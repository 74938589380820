<template>
  <div>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <v-col cols="12" sm="3">
        <CardInfo title="Previsión" :value="weatherInfo" :icon="weatherIcon" :iconColor="weatherColor" :location="weatherLocation" :humidity="weatherHumidity" :airQuality="weatherAirQuality"
          :forecasts="weatherForecasts" />
      </v-col>
      <v-col cols="12" sm="3">
        <CardInfo :titleClass="valvulasTitleClass" title="Válvulas abiertas" :value="formattedValvulasConectadas"
        icon="mdi-network" :iconColor="valvulasIconColor" :valves="connectedValves" />
      </v-col>
      <v-col cols="12" sm="3">
        <CardInfo :title="widgetDetails.title" :value="widgetDetails.value" :icon="widgetDetails.icon"
          :iconColor="widgetDetails.iconColor" />
      </v-col>
      <v-col cols="12" sm="3">
        <CardInfo title="ECOS Core" value="Online" :image="core" />
      </v-col>
    </v-row>
    <v-row :dense="$vuetify.breakpoint.mobile">
      <v-col cols="12" sm="4" v-if="variadores != null && this.variadores.length > 0">
        <v-card v-if="variadores">
            <VariadoresDashboard
              :variadores="variadores"
              :valvula="valvula"
              :sensorSelectedOnMap="sensorSelectedOnMap"
              @updateParametro="updateParametro"
            />
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" v-if="sensores!= null && variadores != null && this.variadores.length > 0">
        <v-card v-if="variadores">
          <RemoteDashboard
              :remoteId = "selectedRemoteId"
              :sensores="sensores"
              :valvulas="electrovalvulas"
              :valvula="valvula"
              :grupos="grupos"
              :programacionesSecuenciales="programacionesSecuenciales"
              :key="key"
              :sensorSelectedOnMap="sensorSelectedOnMap"
              @updateManualAutoValve="updateManualAutoValve"
              @updateNameValve="updateNameValve"
              @close-card="handleCloseCard"
              @valveStateChanged="handleValveStateChanged"
            />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" v-if="sensores!= null && variadores && this.variadores.length == 0">
        <v-card v-if="variadores">
          <RemoteDashboard
              :remoteId = "selectedRemoteId"
              :sensores="sensores"
              :valvulas="electrovalvulas"
              :valvula="valvula"
              :grupos="grupos"
              :programacionesSecuenciales="programacionesSecuenciales"
              :key="key"
              :sensorSelectedOnMap="sensorSelectedOnMap"
              @updateManualAutoValve="updateManualAutoValve"
              @updateNameValve="updateNameValve"
              @close-card="handleCloseCard"
              @valveStateChanged="handleValveStateChanged"
            />
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" v-if="variadores != null && sensores == null">
        <v-card v-if="variadores">
            <VariadoresDashboard
              :variadores="variadores"
              :valvula="valvula"
              :sensorSelectedOnMap="sensorSelectedOnMap"
              @updateParametro="updateParametro"
            />
        </v-card>
      </v-col>
      <v-col>
        <v-card :class="$vuetify.breakpoint.mobile ? 'mb-10' : ''">
          <v-card-text style="padding: 0px">
            <MapDashboard
              :valvulas="electrovalvulas"
              :sensores="sensores"
              @emitIdValve="selectedValve"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VariadoresDashboard from "@/components/VariadoresDashboard.vue";
import CardInfo from "@/components/CardInfo.vue";
import axios from "axios";
import { format, addDays } from 'date-fns';
import { es } from 'date-fns/locale';
import RemoteDashboard from "@/components/RemoteDashboard.vue";
import MapDashboard from "@/components/MapDashboard.vue";
import core from "@/assets/core-icon.png";
import { getValvulas } from "@/data/valvulasData";
import { getSensores } from "@/data/sensoresData";
import { getVariadores } from "@/data/variadoresData";
import { getGrupos } from "@/data/gruposData";
import { getProgramacionesSecuenciales } from "@/data/programacionesSecuencialesData";

export default {
  components: {
    VariadoresDashboard,
    MapDashboard,
    CardInfo,
    RemoteDashboard
  },
  data() {
    return {
      variadores: getVariadores(),
      sensorId: null,
      sensorSelectedOnMap: null,
      electrovalvulas: getValvulas(),
      sensores: getSensores(),
      valvula: -1,
      isAuto: -1,
      battery: 100,
      key: 0,
      markers: [],
      core: core,
      electrovalvulaId: null,
      intervalId: null,
      selectedRemoteId: null,
      grupos: getGrupos(),
      programacionesSecuenciales: getProgramacionesSecuenciales(),
      valvulasConectadas: 0,
      ultimoDatoHumedad: null,
      weatherInfo: 'Cargando...',
      weatherIcon: 'mdi-weather-partly-cloudy',
      weatherColor: 'yellow darken-2',
      weatherHumidity: '-',
      weatherAirQuality: '-',
      weatherLocation: '',
      weatherForecasts: [],
    };
  },
  computed: {
    formattedValvulasConectadas() {
      return `${this.electrovalvulas.filter(valvula => valvula.current_state != 0).length}`;
    },
    valvulasIconColor() {
      const valvulasConectadas = this.electrovalvulas.filter(valvula => valvula.current_state > 1).length;
      return valvulasConectadas === 0 ? 'blue-grey lighten-3' : 'teal darken-2';
    },
    valvulasTitleClass() {
      const valvulasConectadas = this.electrovalvulas.filter(valvula => valvula.current_state > 1).length;
      return valvulasConectadas === 0 ? 'blue-grey--text lighten-3' : '';
    },
    formattedUltimoDatoHumedad() {
      return this.ultimoDatoHumedad ? `${this.ultimoDatoHumedad.historico.values.slice(-1)[0].toFixed(0)}` : '-';
    },
    connectedValves() {
      return this.electrovalvulas.filter(valvula => valvula.current_state != 0).map(valvula => valvula.nombre);
    },
    widgetDetails() {
      const variadorConPresionCabezal = this.variadores?.find(variador =>
        variador.parametros.some(parametro => parametro.param === 'presion_cabezal')
      );

      if (variadorConPresionCabezal) {
        const presionCabezal = variadorConPresionCabezal.parametros.find(parametro => parametro.param === 'presion_cabezal').valor_actual || '-';
        return {
          title: 'Presión Cabezal',
          value: `${presionCabezal}`,
          icon: 'mdi-gauge',
          iconColor: '#ff9800'
        };
      } else if (this.ultimoDatoHumedad) {
        return {
          title: 'Humedad',
          value: this.formattedUltimoDatoHumedad,
          icon: 'mdi-water-percent',
          iconColor: '#0277BD'
        };
      } else {
        return {
          title: 'Presión Cabezal',
          value: '-',
          icon: 'mdi-gauge',
          iconColor: '#ff9800'
        };
      }
    }
  },
  methods: {
    handleCloseCard() {
      this.valvula = -1;
      this.sensorSelectedOnMap = null;
    },
    selectedValve(selection) {
      if (selection.type === "valvula") {
        this.electrovalvulaId = selection.data.id;
        this.selectedRemoteId = selection.data.id_remote;
        this.valvula = this.electrovalvulas.find(
          (valvula) => valvula.id === selection.data.id
        );
        this.getWeatherForValve(this.valvula);
      } else if (selection.type === "sensor") {
        this.sensorSelectedOnMap = getSensores()[0];
        this.sensorId = selection.data.id;
        this.selectedRemoteId = selection.data.id_remote;
        this.sensorSelectedOnMap = this.sensores.find(
          (sensor) => sensor.id === selection.data.id
        );
      }
    },
    updateParametro(id_variador, id, valor) {
      this.variadores
        .find((variador) => variador.id === id_variador)
        .parametros.find((parametro) => parametro.id === id).valor_actual = valor;
    },
    updateManualAutoValve({ id, is_auto }) {
      this.electrovalvulas.map((valvula) => {
        if (valvula.id === id) {
          valvula.is_auto = !is_auto;
        }
      });
    },
    updateNameValve({ id, name }) {
      this.electrovalvulas.map((valvula) => {
        if (valvula.id === id) {
          valvula.nombre = name;
        }
      });
    },
    changeStateValve({ id, current_state }) {
      this.electrovalvulas.map((valvula) => {
        if (valvula.id === id) {
          valvula.current_state = !current_state;
        }
      });
    },
    handleValveStateChanged({ id, current_state }) {
        this.electrovalvulas = this.electrovalvulas.map((valvula) => {
          if (valvula.id === id) {
            valvula.current_state = current_state;
          }
          return valvula;
        });
        // Forzar la actualización de los componentes
        this.$forceUpdate();
      },
    getWeatherForValve(valvula) {
      if (valvula && valvula.latitud && valvula.longitud) {
        this.getWeather(valvula.latitud, valvula.longitud);
      }
    },
    getWeather(lat, lon) {
      const apiKey = 'a2a98b7e4ede4e6fb4973643241306';
      const url = `https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${lat},${lon}&days=4`;

      axios.get(url)
        .then(response => {
          // console.log("Weather data:", response.data)
          const current = response.data.current;
          const forecast = response.data.forecast.forecastday.slice(0, 3);
          const location = response.data.location;
          this.weatherLocation = `${location.name}`;

          if (current.temp_c !== undefined && current.humidity !== undefined && current.wind_kph !== undefined) {
            this.weatherInfo = `${current.temp_c.toFixed(0)}`;
            this.weatherHumidity = `${current.humidity}%`;
            this.weatherAirQuality = `${(current.wind_kph / 3.6).toFixed(2)} m/s`;
          } else {
            this.weatherInfo = null;
          }

          this.weatherForecasts = forecast.map(day => ({
            date: format(new Date(day.date), 'EEE d'),
            temp: day.day.avgtemp_c,
            minTemp: day.day.mintemp_c,
            condition: day.day.condition.text,
            icon: this.getWeatherIconAndColor(day.day.condition.text).icon,
            iconColor: this.getWeatherIconAndColor(day.day.condition.text).color,
            day: this.capitalizeFirstLetter(format(addDays(new Date(day.date), 1), 'EEEE d', { locale: es })),
          }));

          const weatherData = this.getWeatherIconAndColor(current.condition.text);
          this.weatherIcon = weatherData.icon;
          this.weatherColor = weatherData.color;
        })
        .catch(error => {
          console.error("Error fetching weather data:", error);
          this.weatherInfo = "-°C";
          this.weatherHumidity = "-%";
          this.weatherAirQuality = "-m/s";
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getWeatherIconAndColor(condition) {
      const conditionLower = condition.toLowerCase();

      if (conditionLower.includes('sunny')) {
        return { icon: 'mdi-weather-sunny', color: 'yellow darken-2' };
      } else if (conditionLower.includes('cloudy')) {
        return { icon: 'mdi-weather-cloudy', color: 'cyan lighten-4' };
      } else if (conditionLower.includes('rain') || conditionLower.includes('shower') || conditionLower.includes('drizzle')) {
        return { icon: 'mdi-weather-rainy', color: 'blue darken-4' };
      } else if (conditionLower.includes('thunder') || conditionLower.includes('storm') || conditionLower.includes('outbreak')) {
        if (conditionLower.includes('rain') || conditionLower.includes('shower') || conditionLower.includes('drizzle')) {
          return { icon: 'mdi-weather-lightning-rainy', color: 'blue-grey darken-1' };
        } else {
          return { icon: 'mdi-weather-lightning', color: 'blue-grey darken-1' };
        }
      } else if (conditionLower.includes('snow') || conditionLower.includes('sleet') || conditionLower.includes('flurries')) {
        return { icon: 'mdi-weather-snowy-heavy', color: 'cyan lighten-4' };
      } else if (conditionLower.includes('fog') || conditionLower.includes('mist') || conditionLower.includes('haze')) {
        return { icon: 'mdi-weather-fog', color: 'grey darken-1' };
      } else if (conditionLower.includes('patchy rain') || conditionLower.includes('patchy drizzle')) {
        return { icon: 'mdi-weather-partly-rainy', color: 'blue lighten-2' };
      } else {
        return { icon: 'mdi-weather-partly-cloudy', color: 'amber darken-1' };
      }
    },
    getUltimoDatoHumedad() {
      const sensorHumedad = this.sensores.find(sensor => sensor.nombre.toLowerCase() === 'humedad');
      if (sensorHumedad && sensorHumedad.historico && sensorHumedad.historico.values.length) {
        this.ultimoDatoHumedad = {
          historico: sensorHumedad.historico,
        };
      } else {
        this.ultimoDatoHumedad = null;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const firstValve = this.electrovalvulas[0];
      if (firstValve) {
        this.getWeatherForValve(firstValve);
      }
      this.getUltimoDatoHumedad();
    });
  },
};
</script>

<style>
</style>