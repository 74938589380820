<template>
  <LoginComponent/>
</template>

<script>
import LoginComponent from "@/components/LoginComponent.vue";
export default {
components: { LoginComponent },
}
</script>

<style>

</style>