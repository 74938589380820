<template>
  <AnalisisComponent :params="params" />
</template>

<script>
import AnalisisComponent from "@/components/AnalisisComponent.vue";
export default {
  components: { AnalisisComponent },
  data() {
    return {
      params: [
        {
          "header": "Sensores"
        },
        {
          "id": 90,
          "nombre": "Temperatura",
          "id_remote": "011B06",
          "nombre_remote": "Remote Lite Almendros",
          "group": "Sensor",
          "icon": "mdi-thermometer"
        },
        {
          "id": 91,
          "nombre": "Ph",
          "id_remote": "011B06",
          "nombre_remote": "Remote Lite Almendros",
          "group": "Sensor",
          "icon": "mdi-ph"
        },
        {
          "id": 123,
          "nombre": "Bateria Remote",
          "id_remote": "011B06",
          "nombre_remote": "Remote Lite Almendros",
          "group": "Sensor",
          "icon": "mdi-battery-80"
        },
        {
          "id": 560,
          "nombre": "Bateria Remote",
          "id_remote": "016b06",
          "nombre_remote": "Remote Lite Vid",
          "group": "Sensor",
          "icon": "mdi-battery-80"
        },
        {
          "id": 8,
          "nombre": "Anemómetro",
          "id_remote": "abcd06",
          "nombre_remote": "Remote Lite Maiz",
          "group": "Sensor",
          "icon": "mdi-wind-power"
        },
        {
          "id": 12,
          "nombre": "Bateria Remote",
          "id_remote": "abcd06",
          "nombre_remote": "Remote Lite Maiz",
          "group": "Sensor",
          "icon": "mdi-battery-80"
        },
        {
          "id": 7,
          "nombre": "Conductividad",
          "id_remote": "abcd06",
          "nombre_remote": "Remote Lite Maiz",
          "group": "Sensor",
          "icon": "mdi-electric-switch"
        },
        {
          "id": 1,
          "nombre": "Humedad",
          "id_remote": "01dd06",
          "nombre_remote": "Remote Lite Olivo",
          "group": "Sensor",
          "icon": "mdi-water-percent"
        },
        {
          "id": 3,
          "nombre": "Bateria Remote",
          "id_remote": "01dd06",
          "nombre_remote": "Remote Lite Olivo",
          "group": "Sensor",
          "icon": "mdi-battery-80"
        },
        {
          "id": 20,
          "nombre": "Bateria Remote",
          "id_remote": "010906",
          "nombre_remote": "Remote Lite Hortalizas",
          "group": "Sensor",
          "icon": "mdi-battery-80"
        },

        {
          "id": 329,
          "nombre": "Presión en cabezal",
          "group": "Sensor",
          "icon": "mdi-gauge"
        }
      ],
    };
  },
};
</script>

<style></style>