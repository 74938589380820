<template>
  <div>
    <v-navigation-drawer v-if="!$vuetify.breakpoint.mobile" :mini-variant="mini" permanent clipped dark app
      class="pt-2">
      <v-list dense nav class="py-0">
        <v-list-item-group v-model="selectedItemMenu" mandatory>
          <v-list-item v-for="(item, i) in itemsNav" :key="i" @click="linkTo(item.title)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="mb-2 d-flex align-center justify-center mx-4">
          <v-btn :x-small="mini" :block="!mini" class="py-5" color="teal darken-3" @click="showSuggestionsDialog">
            <v-icon>
              mdi-help-circle-outline
            </v-icon>
            <span class="ml-2" v-if="!mini">
              Sugerencias
            </span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-bottom-navigation v-model="selectedItemMenu" v-if="$vuetify.breakpoint.mobile" fixed dark color="white">
      <div style="display: flex; overflow-x: auto; overflow-y: hidden; scrollbar-color: transparent;">
        <v-btn v-for="(item, i) in itemsNav" v-bind:key="i" @click="linkTo(item.title)">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        </div>
    </v-bottom-navigation>
    <v-dialog v-model="suggestionsDialog" max-width="700px" @click:outside="closeDialog" @keydown.esc="closeDialog">
      <v-card min-height="400px">
        <div>
          <v-toolbar color="teal darken-2" dark flat dense>
            <!-- <v-toolbar-title>Sugerencias</v-toolbar-title> -->
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdX2SX7YKVlVEqNwWiQf6JB426Wd5oRngcBbNRTAMCFl3WCeQ/viewform?embedded=true"
          width="700" height="600" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NavigationDrawer",
  props: {
    mini: Boolean,
  },
  data() {
    return {
      suggestionsDialog: false,
      selectedItemMenu: 0,
      itemsNav: [
        {
          title: "Panel de control",
          icon: "mdi-view-dashboard",
        },
        {
          title: "Contadores",
          icon: "mdi-watch-vibrate",
        },
        // {
        //   title: "Configuración",
        //   icon: "mdi-view-list",
        // },
        {
          title: "Grupos",
          icon: "mdi-ungroup",
        },
        {
          title: "Programaciones",
          icon: "mdi-calendar-blank-multiple",
        },
        {
          title: "Análisis",
          icon: "mdi-chart-line",
        },
        {
          title: "Informes",
          icon: "mdi-file-document-multiple-outline",
        },
        // {
        //   title: "Alarmas",
        //   icon: "mdi-bell-outline",
        // }
      ],
    };
  },
  computed: {},
  methods: {
    showSuggestionsDialog() {
      this.suggestionsDialog = true;
    },
    closeDialog() {
      this.suggestionsDialog = false;
    },
    linkTo: function (url) {
      //Regex para eliminar las tildes
      url = url.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      // Regex para eliminar los espacios
      url = url.replace(/\s/g, "%20");
      // Si la ruta actual es igual a la siguiente ruta no sucede nada
      if (this.$route.path.slice(1) === url) return -1;
      this.$router.push(url);
    },
    formatTitle(title) {
      return title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
  },
  beforeMount() {
    const index = this.itemsNav.findIndex(item => this.formatTitle(item.title) === this.$route.name);
    this.selectedItemMenu = index;
  }
};
</script>

<style></style>