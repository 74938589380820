<template>
  <v-app class="bg">
    <AppBar v-on:changeMini="changeMini()" v-on:changeFinca="changeFinca($event)" v-if="isLoggedIn" />
    <NavigationDrawer :mini="mini" v-if="isLoggedIn" />
    <v-main id="app-main" :key="finca">
      <v-container fluid :class="$vuetify.breakpoint.mobile ? 'mt-2' : 'mt-5 px-10'">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import AppBar from "./components/AppBar";
import NavigationDrawer from "./components/NavigationDrawer";
export default {
  name: "App",
  components: {
    AppBar,
    NavigationDrawer,
  },
  data() {
    let isLoggedIn = false;
    return {
      mini: true,
      isLoggedIn,
      finca: null,
    };
  },
  computed: {
  },
  //Verifico cuando se carga la app
  beforeMount() {
    //Intercepto todas las respuestas de axios, si alguna devuelve un 403 se cierra la sesion
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 403) {
          this.$router.push("/login");
          localStorage.clear()
        }
      }
    )

    this.checkToken()
    //Antes de cada llamada se enviara el token automaticamente
    axios.interceptors.request.use(
      config => {
        if (config.url.split(':')[1] === process.env.VUE_APP_API_URL.split(':')[1]) {
          config.headers.Authorization = localStorage.getItem("token")
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    )
    //Si en la respuesta se recibe un error 403 se cierra la sesion
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response && error.response.status === 403) {
          this.$router.push("/login");
          localStorage.clear();
        } else if (error.message && error.message === "Network Error") {
          this.$router.push("/login");
          localStorage.clear();
        }
        return Promise.reject(error);
      }

    )
  },
  beforeUpdate() {
    this.checkToken()
  },
  methods: {
    changeMini() {
      if (this.mini === false) {
        return (this.mini = true);
      } else if (this.mini === true) {
        return (this.mini = false);
      }
    },
    //Funcion para verificar que exista el token
    checkToken() {
      if (localStorage.getItem("token") !== null || undefined) {
        this.isLoggedIn = true;
        if (this.$router.currentRoute.name === "Login") {
          this.$router.push("/Panel%20de%20control");
        }
      } else {
        this.isLoggedIn = false;
        //Si la ruta es diferente a Login se redirige a Login
        if (this.$router.currentRoute.name != "Login") {
          this.$router.push("/login");
        }
      }
    },
    changeFinca(dispositivosFinca) {
      //añado la finca al local storage para mantener los datos si se recarga la pag
      this.finca = dispositivosFinca
      //Cambio los dispositivos en el localStorage
      localStorage.setItem('dispositivos', dispositivosFinca.replace(" ", ""))
    },

  }
};
</script>
<style>
@import url(./styles/fonts.css);

#app-main {
  background-color: rgb(236, 239, 241);
}
</style>