<template>
    <div>
        <v-card height="201" width="270" class="d-flex flex-column ml-4 pt-n4 mb-1 mt-1"
            :class="{ 'sensor-selected': is_selected_sensor }">
            <div>
                <div>
                    <div class="d-flex align-center justify-space-between">
                        <p class="px-3 pt-2 text-h4 font-weight-medium">
                            {{ ultimo_valor }}
                            <span class="text-caption font-weight-bold">
                                {{ unidad }}
                            </span>
                        </p>
                        <v-btn x-small fab class="mr-2 mb-4 elevation-0" @click="chart_dialog = true"
                            color="white"><v-icon>mdi-arrow-expand</v-icon></v-btn>
                    </div>
                    <p class="pl-3 mb-0 mt-n2 text-caption">
                        {{ title }}
                    </p>
                </div>
                <div class="mx-n4 px-1 mt-n11">
                    <apexchart ref="chart" :options="chartOptions" :series="chartSeries" type="area" />
                </div>
            </div>
        </v-card>
        <v-dialog v-if="!$vuetify.breakpoint.smAndDown" v-model="chart_dialog" max-width="75dvw" max-height="80dvh">
            <v-card height="550" class="pb-4" style="overflow-y: hidden;">
                <v-card-title style="height: 10%;" class="d-flex align-center justify-space-between">
                    <span>{{ title }}</span>
                    <v-btn x-small fab class="elevation-0" color="white"
                        @click="chart_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pt-0" style="max-height: 90%;">
                    <section class="w-100 d-flex" style="height: 100%;">
                        <!-- Tabla -->
                        <div class="rounded" style="width: 40%;">
                            <v-container class="bg-grey-lighten-4 py-3" style="position: sticky; top: 0;">
                                <v-row>
                                    <v-col cols="6" class="text-center text-subtitle-1 font-weight-medium">
                                        Fecha
                                    </v-col>
                                    <v-col cols="5" class="text-center text-subtitle-1 font-weight-medium">
                                        Valor
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container>
                                <div v-for="({ fecha, value }) in paginatedDataForTable" class="py-2" :key="fecha"
                                    style="border-bottom: solid #e6e6e6 1px;">
                                    <v-row>
                                        <v-col cols="6" class="text-center text-subtitle-1 font-weight-regular">
                                            {{ fecha }}
                                        </v-col>
                                        <v-col cols="5" class="text-center text-subtitle-1 font-weight-regular">
                                            {{ value.toFixed(2) }} <span class="text-subtitle-1 font-weight-regular">{{
                unidad }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-container>
                            <v-pagination v-if="dataForTable.length > 8" v-model="current_page" :length="pageCount"
                                circle class="mt-2" @input="changePage"></v-pagination>
                        </div>
                        <div class="rounded" style="width: 60%; height: 100%;">
                            <apexchart height="480" ref="chart" :options="chartDialogOptions" :series="chartSeries"
                                type="area" />
                        </div>
                    </section>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-if="$vuetify.breakpoint.smAndDown" v-model="chart_dialog" hide-overlay
            transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title class="ml-5">{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-btn icon @click="selected_view = 0">
                            <v-icon>mdi-table</v-icon>
                        </v-btn>
                        <v-btn icon @click="selected_view = 1">
                            <v-icon>mdi-chart-areaspline</v-icon>
                        </v-btn>
                        <v-btn icon @click="chart_dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-toolbar>
                <v-window v-model="selected_view">
                    <v-window-item :value="0">
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Fecha</th>
                                            <th class="text-left">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="({ fecha, value }) in paginatedDataForTable" :key="fecha">
                                            <td class="text-left">{{ fecha }}</td>
                                            <td class="text-left">{{ value.toFixed(2) }} {{ unidad }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-pagination v-model="current_page" :length="pageCount" size="small" circle
                                :total-visible="5" class="mt-2" @input="changePage"></v-pagination>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="1">
                        <v-card-text>
                            <apexchart height="480" ref="chart" :options="chartDialogOptions" :series="chartSeries"
                                type="area" />
                        </v-card-text>
                    </v-window-item>
                </v-window>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts';

export default {
    component: {
        apexchart: VueApexCharts,
    },
    props: {
        data: {
            type: Array,
            required: true
        },
        fechas: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        unidad: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: '#00695C'
        },
        ultimo_valor: {
            type: Number,
            default: null,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            default: null,
        },
    },

    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            chart_dialog: false,
            current_page: 1,
            page_size: 8,
            selected_view: 0,
            is_selected_sensor: this.isSelected,
        }
    },



    methods: {
        calcularLimites(unidad) {
            switch (unidad) {
                case '%':
                    return { min: 0, max: 100 };
                case 'ºC':
                    return { min: -10, max: 45 };
                case 'pH':
                    return { min: 3, max: 9 };
                case 'µS/cm':
                    return { min: 0, max: 84 };
                case 'km/h':
                    return { min: 0, max: 25 };
                default:
                    return { min: 0, max: 100 };
            }
        },
        changePage(page) {
            this.current_page = page;
        },
    },
    computed: {
        chartSeries: function () {
            const datos =
            {
                name: this.title,
                data: this.data,
            };
            return [datos]
        },

        chartDialogOptions: function () {
            const limites = this.calcularLimites(this.unidad);
            return {
                chart: {
                    id: "basic-area",
                    animations: {
                        enabled: true,
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            zoom: true,
                            zoomout: true,
                            pan: false,
                            download: false,
                            selection: false,
                        },
                    },
                },
                colors: [this.color],
                fill: {
                    colors: [this.color],
                },
                xaxis: {
                    type: "datetime",
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0) + " " + this.unidad;
                        }.bind(this),
                    },
                    min: limites.min,
                    max: limites.max
                },
                tooltip: {
                    x: {
                        format: "dd MMM HH:mm",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                // annotations: {
                //     xaxis: [...new Set(this.fechas.map((fecha) => new Date(fecha).toDateString()))].map((fecha) => {
                //         return {
                //             x: new Date(fecha).getTime(),
                //             strokeDashArray: 0,
                //             borderColor: "#775DD0",
                //         };
                //     }),
                // },
            };
        },
        
        chartOptions: function () {
            return {
                chart: {
                    id: "basic-area",
                    toolbar: false,
                    animations: {
                        enabled: false,
                    },
                },
                tooltip: {
                    enabled: false,
                },
                colors: [this.color],
                fill: {
                    colors: [this.color],
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    categories: this.fechas,
                },
                yaxis: {
                    show: false,
                    labels: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                grid: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
            };
        },

        dataForTable: function () {
            return this.data.map(([timestamp, value]) => {
                const fechaLegible = new Date(timestamp).toLocaleDateString("es-ES", {
                    year: 'numeric', month: '2-digit', day: '2-digit',
                    hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
                });
                return {
                    fecha: fechaLegible,
                    value: value,
                };
            });
        },

        pageCount() {
            return Math.ceil(this.dataForTable.length / this.page_size);
        },

        paginatedDataForTable() {
            const start = (this.current_page - 1) * this.page_size;
            const end = start + this.page_size;
            return this.dataForTable.slice(start, end);
        },
    },

    mounted() {
        setTimeout(() => {
            this.is_selected_sensor = false;
        }, 5000);
    },
};

</script>
<style scoped>
@keyframes dissolveBorder {
    from {
        border: 2px solid rgb(0, 121, 107);
    }

    to {
        border: 2px solid transparent;
    }
}

.sensor-selected {
    animation: dissolveBorder 5s forwards;
}
</style>