function addProgramacion(programacion) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const id = programacionesData.length > 0 ? programacionesData[programacionesData.length - 1].id + 1 : '0';
        programacionesData.push({
            id,
            id_dispositivo: "B827EB7BE480",
            id_grupo: programacion.id_grupo,
            nombre: programacion.nombre,
            fecha: programacion.fecha,
            hora_inicio: programacion.hora_inicio,
            hora_fin: programacion.hora_fin,
            notas: programacion.notas,
            repetir_cada: programacion.repetir_cada
        })
    } else {
        const id = programacionesDos.length > 0 ? programacionesDos[programacionesDos.length - 1].id + 1 : '0';

        programacionesDos.push({
            id,
            id_dispositivo: "B827EB7BE480",
            id_grupo: programacion.id_grupo,
            nombre: programacion.nombre,
            fecha: programacion.fecha,
            hora_inicio: programacion.hora_inicio,
            hora_fin: programacion.hora_fin,
            notas: programacion.notas,
            repetir_cada: programacion.repetir_cada
        })
    }
}
function updateProgramacion(programacion) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const index = programacionesData.findIndex((pr) => pr.id === programacion.id);
        Object.assign(programacionesData[index], programacion);
    } else {
        const index = programacionesDos.findIndex((pr) => pr.id === programacion.id);
        Object.assign(programacionesDos[index], programacion);
    }
}
function deleteProgramacion(id) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const index = programacionesData.findIndex((gr) => gr.id === id);
        programacionesData.splice(index, 1)

    } else {
        const index = programacionesDos.findIndex((gr) => gr.id === id);
        programacionesDos.splice(index, 1)
    }
}

function getProgramaciones() {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        return programacionesData
    } else {
        return programacionesDos
    }
}

let programacionesData = [
    {
        "id": "36",
        "id_grupo": "25",
        "nombre": "Programación 1",
        "notas": "",
        "fecha": "2021-11-18",
        "hora_inicio": "00:59:00",
        "hora_fin": "17:25:00",
        "repetir_cada": "6",
        "is_repetir": 1,
        "color": "0"
    },
    {
        "id": "37",
        "id_grupo": "32",
        "nombre": "Programación 2",
        "notas": "",
        "fecha": "2022-03-01",
        "hora_inicio": "12:00:00",
        "hora_fin": "12:30:00",
        "repetir_cada": "2",
        "is_repetir": 1,
        "color": "0"
    }
]

let programacionesDos = [

]

module.exports = { getProgramaciones, addProgramacion, deleteProgramacion, updateProgramacion }