function addGrupo(grupo) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const id = gruposData.length > 0 ? gruposData[gruposData.length - 1].id + 1 : '0';
        const data = ({
            id,
            nombre: grupo.data.nombre,
            rels: grupo.data.electrovalvulas
        })
        // gruposData.push(grupo);
        gruposData.push(data);
    } else {
        const id = gruposDos.length > 0 ? gruposDos[gruposData.length - 1].id + 1 : '0';
        const data = ({
            id,
            nombre: grupo.data.nombre,
            rels: grupo.data.electrovalvulas
        })
        // gruposData.push(grupo);
        gruposDos.push(data);
    }
}
function deleteGrupo(id) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const index = gruposData.findIndex((gr) => gr.id === id);
        gruposData.splice(index, 1)
    } else {
        const index = gruposDos.findIndex((gr) => gr.id === id);
        gruposDos.splice(index, 1)
    }
}
function updateGrupo(grupo) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        gruposData.map((gr) => {
            if (gr.id === grupo.data.id) {
                gr.nombre = grupo.data.nombre;
                gr.rels = grupo.data.electrovalvulas
            }
        })
    } else {
        gruposDos.map((gr) => {
            if (gr.id === grupo.data.id) {
                gr.nombre = grupo.data.nombre;
                gr.rels = grupo.data.electrovalvulas
            }
        })
    }
}

function getGrupos() {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        return gruposData
    } else {
        return gruposDos
    }
}

let gruposDos = [

]

let gruposData = [
    {
        "id": 24,
        "nombre": "Grupo 1",
        "id_configuracion": 24,
        "rels": [
            {
                "id": 58,
                "nombre": "10",
                "id_electrovalvula": "10",
                "is_deleted": false
            },
            {
                "id": 74,
                "nombre": "12",
                "id_electrovalvula": "12",
                "is_deleted": false
            },
            {
                "id": 59,
                "nombre": "14",
                "id_electrovalvula": "14",
                "is_deleted": false
            },
            {
                "id": 81,
                "nombre": "17",
                "id_electrovalvula": "17",
                "is_deleted": false
            }
        ]
    },
    {
        "id":25,
        "nombre": "Grupo 2",
        "id_configuracion": 25,
        "rels": [
            {
                "id": 61,
                "nombre": "5",
                "id_electrovalvula": "5",
                "is_deleted": false
            },
            {
                "id": 60,
                "nombre": "26",
                "id_electrovalvula": "26",
                "is_deleted": false
            }
        ]
    },
    {
        "id": 32,
        "nombre": "Grupo 3",
        "id_configuracion": 32,
        "rels": [
            {
                "id": 63,
                "nombre": "4",
                "id_electrovalvula": "4",
                "is_deleted": false
            },
            {
                "id": 62,
                "nombre": "12",
                "id_electrovalvula": "12",
                "is_deleted": false
            },
            {
                "id": 64,
                "nombre": "18",
                "id_electrovalvula": "18",
                "is_deleted": false
            }
        ]
    },
    {
        "id": 35,
        "nombre": "Grupo 4",
        "id_configuracion": 35,
        "rels": [
            {
                "id": 86,
                "nombre": "13",
                "id_electrovalvula": "13",
                "is_deleted": false
            }
        ]
    }
]

module.exports = { gruposData, addGrupo, deleteGrupo, updateGrupo, getGrupos }