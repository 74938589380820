<template>
    <v-card>
        <!-- Mensaje cuando no hay contadores disponibles -->
        <v-card-title v-if="contadores.length === 0" class="py-2 px-1">
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <v-row>
                            <v-col style="font-weight: normal;">
                                <v-icon left color="red darken-2" :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'">
                                    mdi-information
                                </v-icon>
                                <span>No hay contadores para visualizar</span>
                            </v-col>
                        </v-row>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card-title>

        <!-- Mensaje cuando no hay contador seleccionado -->
        <v-card-title v-else-if="!contador && contadores.length" class="py-2 px-1">
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <v-row>
                            <v-col style="font-weight: normal;">
                                <v-icon left color="teal" :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'">
                                    mdi-information-outline
                                </v-icon>
                                <span>Selecciona un contador de la lista para visualizar</span>
                            </v-col>
                        </v-row>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card-title>

        <!-- Título y subtítulo del contador seleccionado -->
        <v-card-title v-else class="py-2 px-1">
            <v-list-item two-line>
                <v-list-item-content v-if="!noData" class="contador-info">
                    <v-list-item-title class="contador-title">{{ contador.nombre }}</v-list-item-title>
                    <v-list-item-subtitle class="contador-subtitle">Pulsos: <span>{{ contador.n_pulsos }}</span></v-list-item-subtitle>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-btn icon @click="clearContador">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </v-card-title>

        <!-- Contenido del contador seleccionado -->
        <v-card-text v-if="contador">
            <template v-if="noData">
                <div class="no-data">
                    <v-icon large color="red">mdi-water-remove-outline</v-icon>
                    <span>No se han encontrado datos</span>
                </div>
            </template>
            <template v-else>
                <apexchart height="350" ref="chart" :options="chartOptions" :series="series" type="area"></apexchart>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: ['contador', 'contadores'],
    data() {
        return {
            noData: false,
            chartOptions: {
                chart: {
                    id: "area-datetime",
                    zoom: {
                        enabled: true,
                    },
                    toolbar: {
                        show: true,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                colors: ["#009688"],
                xaxis: {
                    type: "datetime",
                    labels: {
                        datetimeUTC: false,
                        format: 'dd/MM HH:mm', // Displaying day, month, hour and minute
                        style: {
                            fontSize: '12px',
                            cssClass: 'apexcharts-xaxis-label'
                        }
                    },
                    tooltip: {
                        enabled: true,
                        formatter: function (val) {
                            let date = new Date(val);
                            return date.toLocaleString("es-ES", {
                                year: '2-digit',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            });
                        }
                    }
                },
                yaxis: {
                    min: 1500,
                    max: 15000
                },
                markers: {
                    size: 0,
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    x: {
                        format: 'dd MMM HH:mm'
                    }
                },
            },
            series: []
        };
    },
    watch: {
        contador: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.updateChart();
                }
            }
        }
    },
    methods: {
        updateChart() {
            const pulsos = this.contador.historico.n_pulsos;
            this.noData = pulsos.every(p => p === 0 || p === null);

            if (!this.noData) {
                this.series = [
                    {
                        name: 'Pulsos',
                        data: this.contador.historico.fecha_registro.map((fecha, index) => {
                            return {
                                x: new Date(fecha),
                                y: this.contador.historico.n_pulsos[index]
                            };
                        })
                    }
                ];
            } else {
                this.series = [];
            }
        },
        clearContador() {
            this.$emit('clear-contador');
        }
    }
};
</script>

<style scoped>
.apexcharts-xaxis-label {
    transform: rotate(0deg) !important;
    text-align: center !important;
}

.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
    color: #757575;
    margin-bottom: 20px;
    border: 2px dashed #009688;
    border-radius: 10px;
    padding: 20px;
    animation: fadeIn 1s ease-in-out;
}

.no-data v-icon {
    margin-bottom: 10px;
}

.no-data span {
    font-size: 16px;
    font-weight: bold;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.contador-info {
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    animation: fadeInDown 0.5s ease-in-out;
}

.contador-title {
    font-weight: bold;
    color: #009688;
    font-size: 18px;
}

.contador-subtitle {
    font-size: 14px;
}

.contador-subtitle span {
    font-weight: bold;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
