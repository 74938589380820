<template>
  <div>
    <v-card-title>
      <span class="headline pa-2">Variadores</span>
      <v-spacer></v-spacer>
      <v-icon v-show="!show_variator" class="ml-10" @click="showVariadorInfoClick()">mdi-menu-down-outline</v-icon>
      <v-icon v-show="show_variator" @click="closeButtonClick()">mdi-window-close</v-icon>
    </v-card-title>
    <v-select v-if="variador && show_variator" v-model="variador" :items="variadores" filled class="pa-3" chips
      color="blue-grey lighten-2" label="Selecciona un variador" item-text="nombre" item-value="id" solo
      item-color="darkslategrey" @change="selectedVariador($event)">
      <template v-slot:selection="data">
        <v-icon left large class="ml-2 mr-3">mdi-gauge</v-icon>
        {{ data.item.nombre }}
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar color="teal">
            <v-icon color="white">mdi-gauge</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> {{ data.item.nombre }} </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>
    <v-list dense flat :class="this.$vuetify.breakpoint.mobile ? 'pa-3 mt-n5' : 'pa-2 mt-n5'"
      v-if="variador && show_variator">
      <v-subheader>PARÁMETROS</v-subheader>
      <v-divider class="mt-n2"></v-divider>
      <v-list-item-group color="teal" class="">
        <v-list-item three-line class="" v-for="(variador, i) in variador.parametros" :key="i">
          <v-list-item-icon class="mt-7">
            <v-icon color="teal darken-3" dark>{{ variador.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ variador.descripcion }} </v-list-item-title>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <!-- Para el parametro de encendido -->
          <div>
            <v-chip v-if="variador.tipo_valor === 'encendido_dual'" @click="selected(variador)" label dark small :color="variador.is_write == '1' && variador.valor_actual != '0'
              ? 'teal darken-1'
              : ''
              ">
              <span v-if="variador.valor_actual == '0'">Off</span>
              <span v-else>On
                <v-icon class="" small>{{
                  nombreValor(variador) === "Solar"
                  ? "mdi-white-balance-sunny"
                  : "mdi-flash"
                }}</v-icon></span>
            </v-chip>
            <v-chip v-else @click="selected(variador)" label dark small :color="variador.is_write == 1 && variador.valor_actual > 0
                  ? 'teal darken-1'
                  : ''
                ">
              <span>{{ nombreValor(variador) }}</span>
            </v-chip>
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- <v-list v-else>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-row>
              <v-col>
                <v-icon left color="teal" class="mt-0"
                  >mdi-information-outline
                </v-icon>
                Selecciona un variador para visualizar
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <!-- POPUPS -->
    <v-dialog @keydown.esc="close" v-model="dialog" v-if="selectedParam != null" max-width="550" @click:outside="close">
      <v-card>
        <v-card-title> Modificar {{ selectedParam.descripcion }} </v-card-title>
        <v-card-text class="pb-0">
          <v-row class="" justify="space-between">
            <!-- PopUp si el tipo es un select -->
            <v-col v-if="selectedParam.tipo_valor === 'select'" class="mt-4">
              <v-select :items="selectedParam.opciones_nombres.split(',')" filled chips color="blue-grey lighten-2"
                label="Selecciona una opción" item-text="nombre" item-value="nombre" solo item-color="darkslategrey"
                @change="
                  setData(
                    selectedParam.opciones_nombres.split(',').indexOf($event)
                  )
                  ">
                <template v-slot:selection="data">
                  <v-icon left large class="ml-2 mr-3">mdi-power-cycle</v-icon>
                  {{ data.item }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar color="teal">
                      <v-icon dark>mdi-power-cycle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item }} </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <!-- PopUp si es un encendido Dual -->
            <v-col v-if="selectedParam.tipo_valor === 'encendido_dual'" class="">
              <v-container class="d-flex justify-space-around my-4" :class="$vuetify.breakpoint.mobile ? 'align-start' : 'align-center'
                ">
                <v-container class="d-flex flex-column justify-center align-center">
                  <v-btn color="warning" fab large class="mb-2" :outlined="outlinedSolar" @click="setEncendido('Solar')">
                    <v-icon color="">mdi-white-balance-sunny</v-icon>
                  </v-btn>
                  <span>Encendido solar</span>
                </v-container>
                <v-container class="d-flex flex-column align-center justify-center">
                  <v-btn color="blue" fab large class="mb-2" :outlined="outlinedElectrico"
                    @click="setEncendido('Electrico')">
                    <v-icon :color="outlinedElectrico ? 'blue' : 'white'" class="d-flex flex-column">mdi-flash</v-icon>
                  </v-btn>
                  <span>Encendido electrico</span>
                </v-container>
                <v-container class="d-flex flex-column align-center justify-center">
                  <v-btn color="red" fab large class="mb-2" :outlined="outlinedApagar" @click="setEncendido('Off')">
                    <v-icon :color="outlinedApagar ? 'red' : 'white'" class="d-flex flex-column">mdi-power</v-icon>
                  </v-btn>
                  <span>Apagar</span>
                </v-container>
              </v-container>
            </v-col>
            <!-- PopUp si es un slider -->
            <v-col v-if="selectedParam.tipo_valor === 'slider'" class="mt-4">
              <span class="display-3 font-weight-light">{{ valorParametro }} {{ selectedParam.unidades }}</span>
              <v-slider color="teal darken-1" v-model="valorParametro" step="0.01" min="0" track-color="grey"
                :max="selectedParam.opcion_valores.split(',')[1]">
              </v-slider>
            </v-col>
            <!-- PopUp si es un on/off -->
            <v-col v-if="selectedParam.tipo_valor === 'off,on'">
              <v-card-subtitle>
                <span class="font-weight-bold"><v-icon left color="warning">mdi-alert-outline</v-icon>¿Esta
                  seguro de que desea cambiar el estado a "{{
                    indexNombre(selectedParam) == 1
                    ? selectedParam.opciones_nombres.split(",")[0]
                    : selectedParam.opciones_nombres.split(",")[1]
                  }}"?</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="save"> Aceptar </v-btn>
          <v-btn color="red darken-1" text @click="close"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { updateVariador } from "@/data/variadoresData";
export default {
  props: ["variadores", "variadorUpdated", "valvula", "sensorSelectedOnMap"],
  data() {
    //Se inicia el Select con el prmimer variador del array
    let variador = this.variadores[0];
    return {
      dialogConfirm: false,
      dialog: false,
      variador,
      parametros: null,
      selectedParam: null,
      opcionesNombres: null,
      valorParametro: null,
      outlinedElectrico: true,
      outlinedSolar: true,
      outlinedApagar: true,
      show_variator: false,
    };
  },
  computed: {
    variatorHeight() {
      if (this.$vuetify.breakpoint.xl) {
        return this.$vuetify.breakpoint.xl ? (this.show_variator ? "500px" : "0px") : "54vh";
      } else {
        return this.$vuetify.breakpoint.mobile ? (this.show_variator ? "360px" : "0px") : "66vh";
      }
    },
  },
  watch: {
    // Cuando el variador cambia
    variadores: function (newVal) {
      if (this.variador != null) {
        const newVariador = newVal.find((el) => el.id == this.variador.id);
        if (newVariador != null) {
          this.variador = newVariador;
        }
      }
    },
    // Watch que se ejecuta cada vez que se pulsa sobre un sensor
    sensorSelectedOnMap: function (newVal) {
      if (newVal != null) {

        this.show_variator = true;
      }
    },
    // Watch que se ejecuta cada vez que se pulsa sobre una valvula
    valvula: function (newVal) {
      if (newVal != -1) {
        this.show_variator = true;
      }

    },
  },
  methods: {
    closeButtonClick() {
      this.show_variator = false;
    },
    showVariadorInfoClick() {
      this.show_variator = true;
    },
    // Selector de variador
    selectedVariador(e) {
      this.variador = this.variadores.find((variador) => variador.id == e);
      this.parametros = this.variador.parametros;
    },
    close() {
      this.dialog = false;
      this.valorParametro = null
      this.outlinedElectrico = true
      this.outlinedSolar = true
      this.outlinedApagar = true
      setTimeout(() => {
        this.valorParametro = null;
        this.selectedParam = null;
      }, 500);
    },
    // Funcion que se ejecuta cuando se selecciona un parametro
    selected(parametro) {
      if (parametro.is_write === "0") return -1;
      if (parametro.tipo_valor === "encendido_dual") {
        if (this.nombreValor(parametro) == "Solar") {
          this.outlinedSolar = false;
          this.outlinedElectrico = true;
          this.outlinedApagar = true;
        } else if (this.nombreValor(parametro) == "Electrico") {
          this.outlinedElectrico = false;
          this.outlinedSolar = true;
          this.outlinedApagar = true;
        } else if (this.nombreValor(parametro) == "Off") {
          this.outlinedElectrico = true;
          this.outlinedSolar = true;
          this.outlinedApagar = false;
        }
      }
      this.selectedParam = parametro;
      this.opcionesNombres = this.selectedParam.opciones_nombres.split(",");
      this.dialog = true;
      this.valorParametro = parametro.valor_actual;
    },
    save() {
      const id = this.selectedParam.id;
      if (this.selectedParam.tipo_valor === 'off,on') {
        const valores = this.selectedParam.opcion_valores.split(',')
        const value = valores[1] === this.selectedParam.valor_actual ? valores[0] : valores[1]
        updateVariador(id, this.variador.id, value);
      } else {
        updateVariador(id, this.variador.id, this.valorParametro);
      }
      this.close();
      this.valorParametro = null;
    },
    setData(index) {
      this.valorParametro =
        this.selectedParam.opciones_valores.split(",")[index];
    },
    // Funcion que devuelve el nombre del parametro
    nombreValor(variador) {
      if (
        variador.opciones_valores != null &&
        variador.opciones_nombres != null &&
        variador.opciones_nombres !== "" &&
        variador.opciones_valores !== ""
      ) {
        const arrayValores = variador.opcion_valores.split(",");
        const index = arrayValores.indexOf(variador.valor_actual.toString());
        const arrayNombres = variador.opciones_nombres.split(",");
        return arrayNombres[index];
      }
      if (variador.param === "manual_auto" || variador.tipo_valor === "off,on") {
        const arrayValores = variador.opcion_valores.split(",");
        const index = arrayValores.indexOf(variador.valor_actual.toString());
        const arrayNombres = variador.opciones_nombres.split(",");
        return arrayNombres[index];
      }
      if (variador.tipo_valor === "encendido_dual") {
        const arrayValores = variador.opcion_valores.split(",");
        const index = arrayValores.indexOf(variador.valor_actual.toString());
        const arrayNombres = variador.opciones_nombres.split(",");
        return arrayNombres[index];
      }
      if (typeof variador.valor_actual === "string") {
        return (
          variador.valor_actual.replace(".", ",") + " " + variador.unidades
        );
      } else {
        return (
          variador.valor_actual.toFixed(2).replace(".", ",") +
          " " +
          variador.unidades
        );
      }
    },
    indexNombre(variador) {
      const arrayValores = variador.opcion_valores.split(",");
      const index = arrayValores.indexOf(variador.valor_actual.toString());
      return index;
    },
    setEncendido(nombre) {
      const arrNombres = this.selectedParam.opciones_nombres.split(",");
      const index = arrNombres.indexOf(nombre);
      const value = this.selectedParam.opcion_valores.split(",")[index];
      if (nombre == "Solar") {
        this.outlinedSolar = false;
        this.outlinedElectrico = true;
        this.outlinedApagar = true;
        this.valorParametro = value;
      } else if (nombre == "Electrico") {
        this.outlinedElectrico = false;
        this.outlinedSolar = true;
        this.outlinedApagar = true;
        this.valorParametro = value;
      } else if (nombre == "Off") {
        this.valorParametro = value;
        this.outlinedApagar = false;
        this.outlinedSolar = true;
        this.outlinedElectrico = true;
      }
    },
  },
};
</script>

<style></style>