function getData(sensor, fechaInicio, fechaFin) {
    fechaInicio = fechaInicio == null ? null : new Date(fechaInicio).getTime();
    fechaFin = fechaFin == null ? new Date().getTime() : new Date(fechaFin).getTime();
    let min = 1000
    let max = 0
    let media = 0
    let total = 0
    let data = [];

    if (sensor.id == 90) {
        dataTemperatura.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataTemperatura.data.length);
        return {
            data,
            id_param: '90',
            nombre: 'Temperatura',
            unidades: 'ºC',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }
    if (sensor.id == 91) {
        dataPh.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataPh.data.length);
        return {
            data,
            id_param: '91',
            nombre: 'PH',
            unidades: 'pH',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 123) {
        dataBateria.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataBateria.data.length);
        return {
            data,
            id_param: '123',
            nombre: 'Bateria Remote',
            unidades: '',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 1) {
        dataHumedadSegun.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataHumedadSegun.data.length);
        return {
            data,
            id_param: '1',
            nombre: 'Humedad',
            unidades: '%',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }
    if (sensor.id == 329) {
        dataPresionCabezal.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataPresionCabezal.data.length);
        return {
            data,
            id_param: '329',
            nombre: "Presión",
            unidades: 'bar',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 3) {
        dataBateriaSegun.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataBateriaSegun.data.length);
        return {
            data,
            id_param: '3',
            nombre: 'Bateria Remote',
            unidades: '',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 560) {
        dataBateriaTercero.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataBateriaTercero.data.length);
        return {
            data,
            id_param: '560',
            nombre: 'Bateria Remote',
            unidades: '',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 20) {
        dataBateriaCuarto.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataBateriaCuarto.data.length);
        return {
            data,
            id_param: '20',
            nombre: 'Bateria Remote',
            unidades: '',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 12) {
        dataBateriaQuinto.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataBateriaQuinto.data.length);
        return {
            data,
            id_param: '12',
            nombre: 'Bateria Remote',
            unidades: '',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 8) {
        dataAnemometro.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataAnemometro.data.length);
        return {
            data,
            id_param: '8',
            nombre: 'Anemometro',
            unidades: 'km/h',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

    if (sensor.id == 7) {
        dataConductividad.data.map((da) => {
            if (da[0] >= fechaInicio && da[0] <= fechaFin) {
                max = da[1] > max ? da[1] : max;
                min = da[1] < min ? da[1] : min;
                total += da[1];
                data.push(da)
            }
        })
        media = (total / dataConductividad.data.length);
        return {
            data,
            id_param: '7',
            nombre: 'Conductividad',
            unidades: 'µS/cm',
            resumen: {
                tipo_dato: 'max_min',
                maximo: max,
                media,
                minimo: min,
                total
            }
        }
    }

}
const dataPresionCabezal = {
    "data": [
        [
            1713220364000,
            -0.06
        ],
        [
            1713223992000,
            0.012
        ],
        [
            1713227606000,
            0.021
        ],
        [
            1713231244000,
            0.0074999999999998
        ],
        [
            1713234881000,
            -0.033
        ],
        [
            1713238518000,
            -0.033
        ],
        [
            1713242155000,
            0.0165
        ],
        [
            1713245792000,
            -0.069
        ],
        [
            1713249429000,
            -0.0645
        ],
        [
            1713253064000,
            0.0165
        ],
        [
            1713256672000,
            -0.015
        ],
        [
            1713260295000,
            -0.0014999999999998
        ],
        [
            1713263917000,
            -0.0375
        ],
        [
            1713267540000,
            0.03975
        ],
        [
            1713271162000,
            0.0074999999999998
        ],
        [
            1713274785000,
            -0.033
        ],
        [
            1713278407000,
            -0.006
        ],
        [
            1713282030000,
            0.03975
        ],
        [
            1713285652000,
            0.0165
        ],
        [
            1713289275000,
            -0.0465
        ],
        [
            1713292898000,
            0.03975
        ],
        [
            1713296536000,
            0.0074999999999998
        ],
        [
            1713300171000,
            -0.0285
        ],
        [
            1713303786000,
            0.02625
        ],
        [
            1713307403000,
            0.012
        ],
        [
            1713311020000,
            -0.06
        ],
        [
            1713314637000,
            -0.0195
        ],
        [
            1713318255000,
            -0.06
        ],
        [
            1713321872000,
            0.0165
        ],
        [
            1713325489000,
            -0.051
        ],
        [
            1713329106000,
            0.021
        ],
        [
            1713332724000,
            -0.033
        ],
        [
            1713336340000,
            -0.0645
        ],
        [
            1713339957000,
            -0.006
        ],
        [
            1713343574000,
            -0.0465
        ],
        [
            1713347191000,
            -0.06
        ],
        [
            1713350808000,
            -0.006
        ],
        [
            1713354425000,
            -0.0014999999999998
        ],
        [
            1713358043000,
            -0.042
        ],
        [
            1713361660000,
            -0.051
        ],
        [
            1713365277000,
            0.0165
        ],
        [
            1713368894000,
            -0.0465
        ],
        [
            1713372512000,
            -0.0195
        ],
        [
            1713376129000,
            -0.0285
        ],
        [
            1713379746000,
            -0.033
        ],
        [
            1713383363000,
            0.021
        ],
        [
            1713386980000,
            -0.0195
        ],
        [
            1713390597000,
            -0.0285
        ],
        [
            1713394213000,
            -0.024
        ],
        [
            1713397831000,
            0.021
        ],
        [
            1713401448000,
            -0.0555
        ],
        [
            1713405065000,
            -0.0645
        ],
        [
            1713408681000,
            -0.051
        ],
        [
            1713412298000,
            0.0074999999999998
        ],
        [
            1713415915000,
            0.0165
        ],
        [
            1713419533000,
            0.012
        ],
        [
            1713423149000,
            -0.024
        ],
        [
            1713426769000,
            0.012
        ],
        [
            1713430384000,
            -0.0555
        ],
        [
            1713434000000,
            -0.06
        ],
        [
            1713437616000,
            0.0074999999999998
        ],
        [
            1713441232000,
            0.0165
        ],
        [
            1713444848000,
            5.073
        ],
        [
            1713451202000,
            4.95525
        ],
        [
            1713454812000,
            5.03625
        ],
        [
            1713458463000,
            0.012
        ],
        [
            1713462073000,
            -0.024
        ],
        [
            1713465704000,
            -0.033
        ],
        [
            1713469349000,
            -0.0105
        ],
        [
            1713472978000,
            5.03625
        ],
        [
            1713476589000,
            5.01375
        ],
        [
            1713480199000,
            4.95975
        ],
        [
            1713483810000,
            5.04075
        ],
        [
            1713487420000,
            4.95075
        ],
        [
            1713491031000,
            4.9455
        ],
        [
            1713494641000,
            4.79175
        ],
        [
            1713498252000,
            4.8915
        ],
        [
            1713501862000,
            4.75125
        ],
        [
            1713505473000,
            4.80975
        ],
        [
            1713509083000,
            4.80975
        ],
        [
            1713512694000,
            -0.042
        ],
        [
            1713516304000,
            0.0165
        ],
        [
            1713519915000,
            -0.0285
        ],
        [
            1713523525000,
            -0.0014999999999998
        ],
        [
            1713527135000,
            -0.0285
        ],
        [
            1713530746000,
            4.58775
        ],
        [
            1713534356000,
            4.4925
        ],
        [
            1713537967000,
            4.58775
        ],
        [
            1713541577000,
            4.73325
        ],
        [
            1713545187000,
            -0.0375
        ],
        [
            1713548798000,
            -0.0645
        ],
        [
            1713552408000,
            -0.006
        ],
        [
            1713556019000,
            -0.06
        ],
        [
            1713559629000,
            4.81875
        ],
        [
            1713563240000,
            4.76475
        ],
        [
            1713566850000,
            4.923
        ],
        [
            1713570461000,
            4.97775
        ],
        [
            1713574071000,
            4.941
        ],
        [
            1713577682000,
            5.03625
        ],
        [
            1713581292000,
            5.05875
        ],
        [
            1713584902000,
            5.04525
        ],
        [
            1713588513000,
            4.75125
        ],
        [
            1713592151000,
            4.74675
        ],
        [
            1713604656000,
            3.582
        ],
        [
            1713621039000,
            4.2435
        ],
        [
            1713624649000,
            4.167
        ],
        [
            1713628260000,
            4.212
        ],
        [
            1713631870000,
            4.1715
        ],
        [
            1713635480000,
            4.4925
        ],
        [
            1713639091000,
            4.53375
        ],
        [
            1713642701000,
            4.56525
        ],
        [
            1713646311000,
            4.4835
        ],
        [
            1713649921000,
            4.29825
        ],
        [
            1713653532000,
            4.248
        ],
        [
            1713657142000,
            4.34775
        ],
        [
            1713660752000,
            4.13925
        ],
        [
            1713664362000,
            4.017
        ],
        [
            1713667973000,
            3.9855
        ],
        [
            1713671583000,
            3.9675
        ],
        [
            1713675193000,
            3.91275
        ],
        [
            1713678804000,
            2.925
        ],
        [
            1713682414000,
            4.2705
        ],
        [
            1713686024000,
            4.31175
        ],
        [
            1713689634000,
            4.30275
        ],
        [
            1713693245000,
            4.31175
        ],
        [
            1713696855000,
            5.04975
        ],
        [
            1713700465000,
            5.04075
        ],
        [
            1713704075000,
            4.9275
        ],
        [
            1713707686000,
            4.95075
        ],
        [
            1713711296000,
            5.109
        ],
        [
            1713714906000,
            4.99125
        ],
        [
            1713718517000,
            5.1045
        ],
        [
            1713722127000,
            4.99125
        ],
        [
            1713725737000,
            -0.042
        ],
        [
            1713729347000,
            -0.0014999999999998
        ],
        [
            1713732958000,
            -0.051
        ],
        [
            1713736568000,
            0.0074999999999998
        ],
        [
            1713740178000,
            -0.0285
        ],
        [
            1713743788000,
            0.0029999999999997
        ],
        [
            1713747399000,
            -0.0285
        ],
        [
            1713751009000,
            -0.0195
        ],
        [
            1713754620000,
            -0.0465
        ],
        [
            1713758230000,
            -0.042
        ],
        [
            1713761841000,
            -0.0014999999999998
        ],
        [
            1713765451000,
            -0.0465
        ],
        [
            1713769061000,
            -0.0465
        ],
        [
            1713772671000,
            0.0029999999999997
        ],
        [
            1713776281000,
            -0.0105
        ],
        [
            1713779892000,
            0.0074999999999998
        ],
        [
            1713783502000,
            0.0165
        ],
        [
            1713787112000,
            -0.0375
        ],
        [
            1713790723000,
            -0.0375
        ],
        [
            1713794333000,
            -0.015
        ],
        [
            1713797943000,
            -0.006
        ],
        [
            1713801553000,
            -0.0014999999999998
        ],
        [
            1713805164000,
            0.0165
        ],
        [
            1713808774000,
            -0.033
        ],
        [
            1713812385000,
            0.0074999999999998
        ],
        [
            1713815994000,
            0.021
        ],
        [
            1713819605000,
            0.012
        ],
        [
            1713823215000,
            -0.0375
        ],
        [
            1713826825000,
            0.0029999999999997
        ],
        [
            1713830435000,
            -0.0014999999999998
        ],
        [
            1713834046000,
            0.0165
        ],
        [
            1713837656000,
            0.0029999999999997
        ],
        [
            1713841266000,
            -0.042
        ],
        [
            1713844876000,
            -0.042
        ],
        [
            1713848487000,
            -0.0555
        ],
        [
            1713852097000,
            -0.042
        ],
        [
            1713855707000,
            -0.0645
        ],
        [
            1713859317000,
            -0.0555
        ]
    ],
    "id_param": 329,
    "unidades": "bar",
    "nombre": "Presión",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 5.109,
        "minimo": -0.069,
        "media": 1.5564298245614034,
        "total": 266.1495
    }
}

const dataBateria = {
    "data": [
        [
            1694211396000,
            3238
        ],
        [
            1694212618000,
            3230
        ],
        [
            1694213840000,
            3225
        ],
        [
            1694215062000,
            3221
        ],
        [
            1694216284000,
            3217
        ],
        [
            1694217506000,
            3214
        ],
        [
            1694218728000,
            3209
        ],
        [
            1694219950000,
            3204
        ],
        [
            1694221171000,
            3197
        ],
        [
            1694222393000,
            3194
        ],
        [
            1694223615000,
            3186
        ],
        [
            1694224834000,
            3184
        ],
        [
            1694226056000,
            3179
        ],
        [
            1694227277000,
            3174
        ],
        [
            1694228499000,
            3166
        ],
        [
            1694229718000,
            3160
        ],
        [
            1694230940000,
            3154
        ],
        [
            1694232162000,
            3150
        ],
        [
            1694233384000,
            3142
        ],
        [
            1694234605000,
            3136
        ],
        [
            1694235827000,
            3129
        ],
        [
            1694237049000,
            3121
        ],
        [
            1694238271000,
            3118
        ],
        [
            1694239492000,
            3110
        ],
        [
            1694240714000,
            3103
        ],
        [
            1694241936000,
            3099
        ],
        [
            1694243158000,
            3092
        ],
        [
            1694244380000,
            3091
        ],
        [
            1694245602000,
            3093
        ],
        [
            1694246823000,
            3099
        ],
        [
            1694248045000,
            3106
        ],
        [
            1694249267000,
            3114
        ],
        [
            1694250489000,
            3120
        ],
        [
            1694251711000,
            3126
        ],
        [
            1694252933000,
            3130
        ],
        [
            1694254155000,
            3142
        ],
        [
            1694255377000,
            3148
        ],
        [
            1694256599000,
            3153
        ],
        [
            1694257821000,
            3160
        ],
        [
            1694259043000,
            3172
        ],
        [
            1694260264000,
            3183
        ],
        [
            1694261486000,
            3190
        ],
        [
            1694262744000,
            3197
        ],
        [
            1694263967000,
            3202
        ],
        [
            1694265189000,
            3211
        ],
        [
            1694266411000,
            3214
        ],
        [
            1694267633000,
            3228
        ],
        [
            1694268855000,
            3236
        ],
        [
            1694270077000,
            3244
        ],
        [
            1694271299000,
            3250
        ],
        [
            1694272521000,
            3254
        ],
        [
            1694273743000,
            3256
        ],
        [
            1694274965000,
            3265
        ],
        [
            1694276187000,
            3268
        ],
        [
            1694277409000,
            3272
        ],
        [
            1694278631000,
            3289
        ],
        [
            1694279849000,
            3295
        ],
        [
            1694281071000,
            3291
        ],
        [
            1694282293000,
            3288
        ],
        [
            1694283515000,
            3281
        ],
        [
            1694284737000,
            3278
        ],
        [
            1694285995000,
            3277
        ],
        [
            1694287295000,
            3273
        ],
        [
            1694288516000,
            3268
        ],
        [
            1694289782000,
            3263
        ],
        [
            1694291004000,
            3258
        ],
        [
            1694292225000,
            3253
        ],
        [
            1694293447000,
            3249
        ],
        [
            1694294669000,
            3246
        ],
        [
            1694295891000,
            3241
        ],
        [
            1694297113000,
            3234
        ],
        [
            1694298335000,
            3231
        ],
        [
            1694299557000,
            3226
        ],
        [
            1694300779000,
            3220
        ],
        [
            1694302001000,
            3216
        ],
        [
            1694303223000,
            3213
        ],
        [
            1694304445000,
            3207
        ],
        [
            1694305667000,
            3202
        ],
        [
            1694306889000,
            3197
        ],
        [
            1694308110000,
            3191
        ],
        [
            1694309332000,
            3185
        ],
        [
            1694310554000,
            3182
        ],
        [
            1694311776000,
            3174
        ],
        [
            1694312999000,
            3168
        ],
        [
            1694314221000,
            3163
        ],
        [
            1694315443000,
            3156
        ],
        [
            1694316665000,
            3152
        ],
        [
            1694317887000,
            3147
        ],
        [
            1694319108000,
            3137
        ],
        [
            1694320330000,
            3135
        ],
        [
            1694321552000,
            3127
        ],
        [
            1694322774000,
            3120
        ],
        [
            1694323996000,
            3116
        ],
        [
            1694325218000,
            3105
        ],
        [
            1694326440000,
            3099
        ],
        [
            1694327662000,
            3092
        ],
        [
            1694328884000,
            3088
        ],
        [
            1694330106000,
            3086
        ],
        [
            1694331328000,
            3087
        ],
        [
            1694332550000,
            3087
        ],
        [
            1694333772000,
            3088
        ],
        [
            1694334994000,
            3092
        ],
        [
            1694336216000,
            3098
        ],
        [
            1694337438000,
            3104
        ],
        [
            1694338659000,
            3109
        ],
        [
            1694339881000,
            3119
        ],
        [
            1694341139000,
            3125
        ],
        [
            1694342361000,
            3132
        ],
        [
            1694343579000,
            3137
        ],
        [
            1694344801000,
            3144
        ],
        [
            1694346023000,
            3148
        ],
        [
            1694347245000,
            3153
        ],
        [
            1694348467000,
            3157
        ],
        [
            1694349689000,
            3160
        ],
        [
            1694350911000,
            3164
        ],
        [
            1694352133000,
            3169
        ],
        [
            1694353355000,
            3174
        ],
        [
            1694354577000,
            3192
        ],
        [
            1694355799000,
            3207
        ],
        [
            1694357021000,
            3229
        ],
        [
            1694358243000,
            3249
        ],
        [
            1694359464000,
            3279
        ],
        [
            1694360686000,
            3310
        ],
        [
            1694361908000,
            3339
        ],
        [
            1694363131000,
            3362
        ],
        [
            1694364389000,
            3378
        ],
        [
            1694365611000,
            3386
        ],
        [
            1694366833000,
            3381
        ],
        [
            1694368054000,
            3377
        ],
        [
            1694369276000,
            3375
        ],
        [
            1694370498000,
            3369
        ],
        [
            1694371720000,
            3365
        ],
        [
            1694372942000,
            3363
        ],
        [
            1694374164000,
            3360
        ],
        [
            1694375422000,
            3358
        ],
        [
            1694376644000,
            3355
        ],
        [
            1694377866000,
            3350
        ],
        [
            1694379088000,
            3348
        ],
        [
            1694380310000,
            3344
        ],
        [
            1694381532000,
            3343
        ],
        [
            1694382754000,
            3340
        ],
        [
            1694383976000,
            3336
        ],
        [
            1694385198000,
            3331
        ],
        [
            1694386419000,
            3329
        ],
        [
            1694387695000,
            3327
        ],
        [
            1694388917000,
            3323
        ],
        [
            1694390139000,
            3318
        ],
        [
            1694391361000,
            3316
        ],
        [
            1694392583000,
            3312
        ],
        [
            1694393805000,
            3308
        ],
        [
            1694395027000,
            3306
        ],
        [
            1694396249000,
            3303
        ],
        [
            1694397471000,
            3298
        ],
        [
            1694398693000,
            3294
        ],
        [
            1694399915000,
            3291
        ],
        [
            1694401137000,
            3286
        ],
        [
            1694402355000,
            3280
        ],
        [
            1694403577000,
            3280
        ],
        [
            1694404799000,
            3279
        ],
        [
            1694406020000,
            3272
        ],
        [
            1694407242000,
            3269
        ],
        [
            1694408464000,
            3264
        ],
        [
            1694409686000,
            3261
        ],
        [
            1694410908000,
            3257
        ],
        [
            1694412130000,
            3251
        ],
        [
            1694413340000,
            3248
        ],
        [
            1694414562000,
            3248
        ],
        [
            1694415784000,
            3247
        ],
        [
            1694417006000,
            3246
        ],
        [
            1694418228000,
            3246
        ],
        [
            1694419450000,
            3247
        ],
        [
            1694420672000,
            3250
        ],
        [
            1694421893000,
            3257
        ],
        [
            1694423115000,
            3252
        ],
        [
            1694424337000,
            3252
        ],
        [
            1694425559000,
            3255
        ],
        [
            1694426781000,
            3263
        ],
        [
            1694428003000,
            3266
        ],
        [
            1694429225000,
            3266
        ],
        [
            1694430447000,
            3268
        ],
        [
            1694431669000,
            3274
        ],
        [
            1694432891000,
            3277
        ],
        [
            1694434113000,
            3280
        ],
        [
            1694435335000,
            3282
        ],
        [
            1694436557000,
            3289
        ],
        [
            1694437779000,
            3292
        ],
        [
            1694438996000,
            3293
        ],
        [
            1694440218000,
            3299
        ],
        [
            1694441439000,
            3309
        ],
        [
            1694442662000,
            3322
        ],
        [
            1694443884000,
            3341
        ],
        [
            1694445105000,
            3359
        ],
        [
            1694446327000,
            3375
        ],
        [
            1694447549000,
            3389
        ],
        [
            1694448771000,
            3404
        ],
        [
            1694449993000,
            3413
        ],
        [
            1694451215000,
            3411
        ],
        [
            1694452437000,
            3414
        ],
        [
            1694453659000,
            3413
        ],
        [
            1694454881000,
            3412
        ],
        [
            1694456103000,
            3409
        ],
        [
            1694457325000,
            3408
        ],
        [
            1694458547000,
            3406
        ],
        [
            1694459769000,
            3404
        ],
        [
            1694460991000,
            3400
        ],
        [
            1694462213000,
            3398
        ],
        [
            1694463428000,
            3397
        ],
        [
            1694464650000,
            3393
        ],
        [
            1694465872000,
            3392
        ],
        [
            1694467129000,
            3391
        ],
        [
            1694468351000,
            3387
        ],
        [
            1694469573000,
            3385
        ],
        [
            1694470795000,
            3382
        ],
        [
            1694472017000,
            3379
        ],
        [
            1694473239000,
            3377
        ],
        [
            1694474461000,
            3376
        ],
        [
            1694475683000,
            3375
        ],
        [
            1694476905000,
            3373
        ],
        [
            1694478127000,
            3369
        ],
        [
            1694479349000,
            3366
        ],
        [
            1694480571000,
            3364
        ],
        [
            1694481793000,
            3361
        ],
        [
            1694483015000,
            3359
        ],
        [
            1694484237000,
            3357
        ],
        [
            1694485459000,
            3353
        ],
        [
            1694486681000,
            3350
        ],
        [
            1694487905000,
            3345
        ],
        [
            1694489127000,
            3344
        ],
        [
            1694490349000,
            3340
        ],
        [
            1694491571000,
            3341
        ],
        [
            1694492793000,
            3337
        ],
        [
            1694494015000,
            3336
        ],
        [
            1694495237000,
            3331
        ],
        [
            1694496459000,
            3328
        ],
        [
            1694497681000,
            3326
        ],
        [
            1694498903000,
            3322
        ],
        [
            1694500126000,
            3320
        ],
        [
            1694501348000,
            3318
        ],
        [
            1694502570000,
            3315
        ],
        [
            1694503792000,
            3314
        ],
        [
            1694505014000,
            3316
        ],
        [
            1694506236000,
            3313
        ],
        [
            1694507458000,
            3313
        ],
        [
            1694508680000,
            3313
        ],
        [
            1694509902000,
            3316
        ],
        [
            1694511124000,
            3323
        ],
        [
            1694512338000,
            3330
        ],
        [
            1694513560000,
            3332
        ],
        [
            1694514782000,
            3335
        ],
        [
            1694516004000,
            3340
        ],
        [
            1694517226000,
            3343
        ],
        [
            1694518448000,
            3344
        ],
        [
            1694519670000,
            3346
        ],
        [
            1694520892000,
            3351
        ],
        [
            1694522114000,
            3353
        ],
        [
            1694523336000,
            3358
        ],
        [
            1694524558000,
            3358
        ],
        [
            1694525780000,
            3362
        ],
        [
            1694527002000,
            3365
        ],
        [
            1694528224000,
            3374
        ],
        [
            1694529446000,
            3383
        ],
        [
            1694530668000,
            3379
        ],
        [
            1694531890000,
            3379
        ],
        [
            1694533111000,
            3380
        ],
        [
            1694534333000,
            3393
        ],
        [
            1694535555000,
            3416
        ],
        [
            1694536777000,
            3431
        ],
        [
            1694537994000,
            3438
        ],
        [
            1694539216000,
            3438
        ],
        [
            1694540438000,
            3430
        ],
        [
            1694541660000,
            3426
        ],
        [
            1694542882000,
            3423
        ],
        [
            1694544104000,
            3421
        ],
        [
            1694545326000,
            3419
        ],
        [
            1694546548000,
            3413
        ],
        [
            1694547770000,
            3414
        ],
        [
            1694548992000,
            3413
        ],
        [
            1694550214000,
            3411
        ],
        [
            1694551436000,
            3409
        ],
        [
            1694552658000,
            3409
        ],
        [
            1694553880000,
            3408
        ],
        [
            1694555102000,
            3408
        ],
        [
            1694556324000,
            3406
        ],
        [
            1694557546000,
            3406
        ],
        [
            1694558768000,
            3405
        ],
        [
            1694559990000,
            3402
        ],
        [
            1694561248000,
            3401
        ],
        [
            1694562471000,
            3397
        ],
        [
            1694563693000,
            3397
        ],
        [
            1694564914000,
            3396
        ],
        [
            1694566136000,
            3395
        ],
        [
            1694567355000,
            3396
        ],
        [
            1694568577000,
            3394
        ],
        [
            1694569799000,
            3393
        ],
        [
            1694571021000,
            3391
        ],
        [
            1694572243000,
            3390
        ],
        [
            1694573465000,
            3389
        ],
        [
            1694574687000,
            3387
        ],
        [
            1694575945000,
            3384
        ],
        [
            1694577167000,
            3384
        ],
        [
            1694578389000,
            3381
        ],
        [
            1694579611000,
            3379
        ],
        [
            1694580833000,
            3379
        ],
        [
            1694582055000,
            3378
        ],
        [
            1694583277000,
            3377
        ],
        [
            1694584499000,
            3376
        ],
        [
            1694585718000,
            3375
        ],
        [
            1694586941000,
            3374
        ],
        [
            1694588162000,
            3374
        ],
        [
            1694589384000,
            3369
        ],
        [
            1694590606000,
            3373
        ],
        [
            1694591828000,
            3374
        ],
        [
            1694593050000,
            3376
        ],
        [
            1694594308000,
            3376
        ],
        [
            1694595530000,
            3379
        ],
        [
            1694596752000,
            3382
        ],
        [
            1694598010000,
            3385
        ],
        [
            1694599232000,
            3388
        ],
        [
            1694600454000,
            3391
        ],
        [
            1694601676000,
            3391
        ],
        [
            1694602898000,
            3394
        ],
        [
            1694604120000,
            3395
        ],
        [
            1694605342000,
            3398
        ],
        [
            1694606564000,
            3398
        ],
        [
            1694607786000,
            3400
        ],
        [
            1694609008000,
            3398
        ],
        [
            1694610234000,
            3402
        ],
        [
            1694611456000,
            3403
        ],
        [
            1694612677000,
            3403
        ],
        [
            1694613900000,
            3405
        ],
        [
            1694615122000,
            3413
        ],
        [
            1694616343000,
            3423
        ],
        [
            1694617565000,
            3430
        ],
        [
            1694618787000,
            3437
        ],
        [
            1694620009000,
            3441
        ],
        [
            1694621231000,
            3448
        ],
        [
            1694622453000,
            3453
        ],
        [
            1694623675000,
            3456
        ],
        [
            1694624898000,
            3456
        ],
        [
            1694626120000,
            3455
        ],
        [
            1694627342000,
            3445
        ],
        [
            1694628600000,
            3441
        ],
        [
            1694629822000,
            3440
        ],
        [
            1694631044000,
            3438
        ],
        [
            1694632266000,
            3437
        ],
        [
            1694633484000,
            3436
        ],
        [
            1694634706000,
            3433
        ],
        [
            1694635915000,
            3431
        ],
        [
            1694637173000,
            3430
        ],
        [
            1694638502000,
            3428
        ],
        [
            1694639724000,
            3427
        ],
        [
            1694640946000,
            3425
        ],
        [
            1694642168000,
            3425
        ],
        [
            1694643390000,
            3421
        ],
        [
            1694644612000,
            3423
        ],
        [
            1694645834000,
            3418
        ],
        [
            1694647056000,
            3419
        ],
        [
            1694648314000,
            3418
        ],
        [
            1694649536000,
            3418
        ],
        [
            1694650758000,
            3416
        ],
        [
            1694651980000,
            3414
        ],
        [
            1694653202000,
            3413
        ],
        [
            1694654424000,
            3413
        ],
        [
            1694655646000,
            3412
        ],
        [
            1694656868000,
            3411
        ],
        [
            1694658090000,
            3410
        ],
        [
            1694659312000,
            3409
        ],
        [
            1694660567000,
            3409
        ],
        [
            1694661789000,
            3408
        ],
        [
            1694663011000,
            3408
        ],
        [
            1694664233000,
            3408
        ],
        [
            1694665455000,
            3408
        ],
        [
            1694666677000,
            3408
        ],
        [
            1694667899000,
            3408
        ],
        [
            1694669157000,
            3407
        ],
        [
            1694670379000,
            3407
        ],
        [
            1694671601000,
            3406
        ],
        [
            1694672823000,
            3406
        ],
        [
            1694674117000,
            3406
        ],
        [
            1694675339000,
            3407
        ],
        [
            1694676561000,
            3405
        ],
        [
            1694677783000,
            3405
        ],
        [
            1694679005000,
            3407
        ],
        [
            1694680227000,
            3409
        ],
        [
            1694681445000,
            3408
        ],
        [
            1694682667000,
            3409
        ],
        [
            1694683874000,
            3409
        ],
        [
            1694685096000,
            3410
        ],
        [
            1694686318000,
            3415
        ],
        [
            1694687540000,
            3415
        ],
        [
            1694688758000,
            3415
        ],
        [
            1694689980000,
            3418
        ],
        [
            1694691202000,
            3422
        ],
        [
            1694692456000,
            3421
        ],
        [
            1694693715000,
            3423
        ],
        [
            1694694937000,
            3425
        ],
        [
            1694696159000,
            3425
        ],
        [
            1694697381000,
            3427
        ],
        [
            1694698603000,
            3430
        ],
        [
            1694699825000,
            3431
        ],
        [
            1694701047000,
            3439
        ],
        [
            1694702269000,
            3438
        ],
        [
            1694703491000,
            3438
        ],
        [
            1694704709000,
            3443
        ],
        [
            1694705928000,
            3445
        ],
        [
            1694707150000,
            3450
        ],
        [
            1694708358000,
            3443
        ],
        [
            1694709580000,
            3443
        ],
        [
            1694710834000,
            3457
        ],
        [
            1694712056000,
            3457
        ],
        [
            1694713278000,
            3451
        ],
        [
            1694714500000,
            3448
        ],
        [
            1694715722000,
            3444
        ],
        [
            1694716944000,
            3441
        ],
        [
            1694718166000,
            3440
        ],
        [
            1694719388000,
            3440
        ],
        [
            1694720646000,
            3439
        ],
        [
            1694721868000,
            3438
        ],
        [
            1694723090000,
            3437
        ],
        [
            1694724308000,
            3437
        ],
        [
            1694725530000,
            3435
        ],
        [
            1694726752000,
            3431
        ],
        [
            1694727971000,
            3430
        ],
        [
            1694729193000,
            3429
        ],
        [
            1694730415000,
            3427
        ],
        [
            1694731633000,
            3426
        ],
        [
            1694732854000,
            3425
        ],
        [
            1694734076000,
            3424
        ],
        [
            1694735298000,
            3423
        ],
        [
            1694736556000,
            3423
        ],
        [
            1694737777000,
            3422
        ],
        [
            1694739036000,
            3419
        ],
        [
            1694740253000,
            3420
        ],
        [
            1694741476000,
            3417
        ],
        [
            1694742698000,
            3418
        ],
        [
            1694743920000,
            3415
        ],
        [
            1694745142000,
            3416
        ],
        [
            1694746364000,
            3414
        ],
        [
            1694747622000,
            3411
        ],
        [
            1694748844000,
            3410
        ],
        [
            1694750066000,
            3407
        ],
        [
            1694751288000,
            3406
        ],
        [
            1694752510000,
            3406
        ],
        [
            1694753840000,
            3408
        ],
        [
            1694755062000,
            3407
        ],
        [
            1694756284000,
            3406
        ],
        [
            1694757581000,
            3406
        ],
        [
            1694758839000,
            3405
        ],
        [
            1694760054000,
            3406
        ],
        [
            1694761272000,
            3406
        ],
        [
            1694762494000,
            3405
        ],
        [
            1694763752000,
            3405
        ],
        [
            1694764967000,
            3406
        ],
        [
            1694766189000,
            3407
        ],
        [
            1694767410000,
            3408
        ],
        [
            1694768632000,
            3410
        ],
        [
            1694769854000,
            3409
        ],
        [
            1694771073000,
            3411
        ],
        [
            1694772323000,
            3412
        ],
        [
            1694773545000,
            3416
        ],
        [
            1694774764000,
            3417
        ],
        [
            1694775986000,
            3418
        ],
        [
            1694777208000,
            3421
        ],
        [
            1694778462000,
            3421
        ],
        [
            1694779684000,
            3423
        ],
        [
            1694780906000,
            3424
        ],
        [
            1694782128000,
            3428
        ],
        [
            1694783350000,
            3428
        ],
        [
            1694784608000,
            3425
        ],
        [
            1694785830000,
            3432
        ],
        [
            1694787052000,
            3435
        ],
        [
            1694788274000,
            3440
        ],
        [
            1694789528000,
            3441
        ],
        [
            1694790750000,
            3451
        ],
        [
            1694791972000,
            3451
        ],
        [
            1694793194000,
            3461
        ],
        [
            1694794416000,
            3467
        ],
        [
            1694795637000,
            3468
        ],
        [
            1694796860000,
            3471
        ],
        [
            1694798082000,
            3462
        ],
        [
            1694799304000,
            3458
        ],
        [
            1694800526000,
            3456
        ],
        [
            1694801748000,
            3454
        ],
        [
            1694802970000,
            3451
        ],
        [
            1694804192000,
            3451
        ],
        [
            1694805414000,
            3446
        ],
        [
            1694806636000,
            3446
        ],
        [
            1694807844000,
            3443
        ],
        [
            1694809063000,
            3442
        ],
        [
            1694810284000,
            3440
        ],
        [
            1694811506000,
            3438
        ],
        [
            1694812725000,
            3436
        ],
        [
            1694813943000,
            3434
        ],
        [
            1694815333000,
            3430
        ],
        [
            1694816626000,
            3433
        ],
        [
            1694817848000,
            3432
        ],
        [
            1694819059000,
            3430
        ],
        [
            1694820281000,
            3427
        ],
        [
            1694821503000,
            3427
        ],
        [
            1694822761000,
            3429
        ],
        [
            1694823983000,
            3425
        ],
        [
            1694825237000,
            3421
        ],
        [
            1694826495000,
            3424
        ],
        [
            1694827717000,
            3423
        ],
        [
            1694828935000,
            3422
        ],
        [
            1694830193000,
            3422
        ],
        [
            1694831411000,
            3421
        ],
        [
            1694832669000,
            3418
        ],
        [
            1694833883000,
            3420
        ],
        [
            1694835105000,
            3418
        ],
        [
            1694836320000,
            3416
        ],
        [
            1694837571000,
            3417
        ],
        [
            1694838825000,
            3415
        ],
        [
            1694840154000,
            3414
        ],
        [
            1694841372000,
            3412
        ],
        [
            1694842594000,
            3413
        ],
        [
            1694843848000,
            3413
        ],
        [
            1694845070000,
            3413
        ],
        [
            1694846284000,
            3413
        ],
        [
            1694847506000,
            3412
        ],
        [
            1694848764000,
            3410
        ],
        [
            1694849986000,
            3413
        ],
        [
            1694851205000,
            3413
        ],
        [
            1694852462000,
            3414
        ],
        [
            1694853756000,
            3413
        ],
        [
            1694854978000,
            3416
        ],
        [
            1694856236000,
            3415
        ],
        [
            1694857458000,
            3419
        ],
        [
            1694858680000,
            3417
        ],
        [
            1694859938000,
            3420
        ],
        [
            1694861160000,
            3421
        ],
        [
            1694862418000,
            3425
        ],
        [
            1694863640000,
            3425
        ],
        [
            1694864861000,
            3428
        ],
        [
            1694866083000,
            3429
        ],
        [
            1694867341000,
            3432
        ],
        [
            1694868563000,
            3433
        ],
        [
            1694869821000,
            3438
        ],
        [
            1694871043000,
            3438
        ],
        [
            1694872265000,
            3439
        ],
        [
            1694873483000,
            3441
        ],
        [
            1694874705000,
            3440
        ],
        [
            1694875927000,
            3441
        ],
        [
            1694877149000,
            3440
        ],
        [
            1694878404000,
            3441
        ],
        [
            1694879626000,
            3440
        ],
        [
            1694880844000,
            3440
        ],
        [
            1694882102000,
            3440
        ],
        [
            1694883324000,
            3439
        ],
        [
            1694884546000,
            3438
        ],
        [
            1694885768000,
            3436
        ],
        [
            1694887062000,
            3435
        ],
        [
            1694888320000,
            3431
        ],
        [
            1694889569000,
            3432
        ],
        [
            1694890791000,
            3430
        ],
        [
            1694892013000,
            3426
        ],
        [
            1694893235000,
            3426
        ],
        [
            1694894565000,
            3425
        ],
        [
            1694895787000,
            3423
        ],
        [
            1694897005000,
            3424
        ],
        [
            1694898227000,
            3422
        ],
        [
            1694899449000,
            3423
        ],
        [
            1694900707000,
            3421
        ],
        [
            1694901929000,
            3419
        ],
        [
            1694903152000,
            3418
        ],
        [
            1694904374000,
            3418
        ],
        [
            1694905596000,
            3415
        ],
        [
            1694906814000,
            3416
        ],
        [
            1694908036000,
            3415
        ],
        [
            1694909258000,
            3414
        ],
        [
            1694910480000,
            3413
        ],
        [
            1694911737000,
            3412
        ],
        [
            1694913139000,
            3412
        ],
        [
            1694914361000,
            3411
        ],
        [
            1694915583000,
            3409
        ],
        [
            1694916805000,
            3410
        ],
        [
            1694918020000,
            3409
        ],
        [
            1694919270000,
            3408
        ],
        [
            1694920492000,
            3406
        ],
        [
            1694921714000,
            3408
        ],
        [
            1694922936000,
            3407
        ],
        [
            1694924158000,
            3407
        ],
        [
            1694925380000,
            3407
        ],
        [
            1694926591000,
            3406
        ],
        [
            1694927813000,
            3403
        ],
        [
            1694929035000,
            3406
        ],
        [
            1694930257000,
            3405
        ],
        [
            1694931480000,
            3403
        ],
        [
            1694932730000,
            3405
        ],
        [
            1694933952000,
            3404
        ],
        [
            1694935174000,
            3405
        ],
        [
            1694936396000,
            3405
        ],
        [
            1694937722000,
            3407
        ],
        [
            1694938976000,
            3404
        ],
        [
            1694940198000,
            3405
        ],
        [
            1694941420000,
            3404
        ],
        [
            1694942635000,
            3405
        ],
        [
            1694943853000,
            3404
        ],
        [
            1694945075000,
            3405
        ],
        [
            1694946369000,
            3403
        ],
        [
            1694947591000,
            3403
        ],
        [
            1694948813000,
            3401
        ],
        [
            1694950070000,
            3408
        ],
        [
            1694951292000,
            3409
        ],
        [
            1694952550000,
            3407
        ],
        [
            1694953844000,
            3407
        ],
        [
            1694955137000,
            3410
        ],
        [
            1694956352000,
            3411
        ],
        [
            1694957645000,
            3416
        ],
        [
            1694958867000,
            3419
        ],
        [
            1694960089000,
            3415
        ],
        [
            1694961418000,
            3425
        ],
        [
            1694962643000,
            3427
        ],
        [
            1694963865000,
            3431
        ],
        [
            1694965123000,
            3426
        ],
        [
            1694966345000,
            3427
        ],
        [
            1694967603000,
            3432
        ],
        [
            1694968825000,
            3433
        ],
        [
            1694970047000,
            3431
        ],
        [
            1694971301000,
            3430
        ],
        [
            1694972602000,
            3427
        ],
        [
            1694973876000,
            3427
        ],
        [
            1694975134000,
            3423
        ],
        [
            1694976356000,
            3420
        ],
        [
            1694977578000,
            3418
        ],
        [
            1694978800000,
            3420
        ],
        [
            1694980023000,
            3418
        ],
        [
            1694981245000,
            3415
        ],
        [
            1694982472000,
            3416
        ],
        [
            1694983690000,
            3413
        ],
        [
            1694984909000,
            3413
        ],
        [
            1694986131000,
            3412
        ],
        [
            1694987343000,
            3410
        ],
        [
            1694988601000,
            3410
        ],
        [
            1694989823000,
            3409
        ],
        [
            1694991045000,
            3409
        ],
        [
            1694992266000,
            3408
        ],
        [
            1694993488000,
            3406
        ],
        [
            1694994710000,
            3408
        ],
        [
            1694995932000,
            3406
        ],
        [
            1694997154000,
            3407
        ],
        [
            1694998444000,
            3406
        ],
        [
            1694999689000,
            3403
        ],
        [
            1695000954000,
            3403
        ],
        [
            1695002212000,
            3402
        ],
        [
            1695003470000,
            3400
        ],
        [
            1695004688000,
            3400
        ],
        [
            1695005916000,
            3400
        ],
        [
            1695007133000,
            3399
        ],
        [
            1695008391000,
            3396
        ],
        [
            1695009633000,
            3398
        ],
        [
            1695010879000,
            3396
        ],
        [
            1695012134000,
            3396
        ],
        [
            1695013424000,
            3396
        ],
        [
            1695014645000,
            3395
        ],
        [
            1695015867000,
            3396
        ],
        [
            1695017125000,
            3395
        ],
        [
            1695018423000,
            3396
        ],
        [
            1695019654000,
            3393
        ],
        [
            1695020872000,
            3395
        ],
        [
            1695022094000,
            3393
        ],
        [
            1695023316000,
            3395
        ],
        [
            1695024538000,
            3396
        ],
        [
            1695025756000,
            3397
        ],
        [
            1695026977000,
            3401
        ],
        [
            1695028199000,
            3404
        ],
        [
            1695029421000,
            3405
        ],
        [
            1695030643000,
            3406
        ],
        [
            1695031865000,
            3407
        ],
        [
            1695033080000,
            3409
        ],
        [
            1695034327000,
            3408
        ],
        [
            1695035545000,
            3411
        ],
        [
            1695036767000,
            3412
        ],
        [
            1695038061000,
            3416
        ],
        [
            1695039390000,
            3416
        ],
        [
            1695040608000,
            3420
        ],
        [
            1695041866000,
            3420
        ],
        [
            1695043088000,
            3422
        ],
        [
            1695044310000,
            3425
        ],
        [
            1695045528000,
            3423
        ],
        [
            1695046750000,
            3425
        ],
        [
            1695048008000,
            3423
        ],
        [
            1695049230000,
            3426
        ],
        [
            1695050444000,
            3430
        ],
        [
            1695051666000,
            3426
        ],
        [
            1695052924000,
            3426
        ],
        [
            1695054142000,
            3426
        ],
        [
            1695055364000,
            3427
        ],
        [
            1695056654000,
            3427
        ],
        [
            1695057876000,
            3425
        ],
        [
            1695059098000,
            3424
        ],
        [
            1695060353000,
            3419
        ],
        [
            1695061610000,
            3421
        ],
        [
            1695062828000,
            3420
        ],
        [
            1695064086000,
            3413
        ],
        [
            1695065380000,
            3416
        ],
        [
            1695066598000,
            3413
        ],
        [
            1695067820000,
            3413
        ],
        [
            1695069038000,
            3412
        ],
        [
            1695070260000,
            3410
        ],
        [
            1695071482000,
            3409
        ],
        [
            1695072700000,
            3408
        ],
        [
            1695073922000,
            3408
        ],
        [
            1695075141000,
            3408
        ],
        [
            1695076363000,
            3407
        ],
        [
            1695077621000,
            3406
        ],
        [
            1695078950000,
            3405
        ],
        [
            1695080168000,
            3401
        ],
        [
            1695081541000,
            3401
        ],
        [
            1695082759000,
            3402
        ],
        [
            1695083981000,
            3402
        ],
        [
            1695085199000,
            3399
        ],
        [
            1695086421000,
            3397
        ],
        [
            1695087652000,
            3397
        ],
        [
            1695088870000,
            3396
        ],
        [
            1695090092000,
            3396
        ],
        [
            1695091310000,
            3395
        ],
        [
            1695092532000,
            3394
        ],
        [
            1695093790000,
            3392
        ],
        [
            1695095008000,
            3393
        ],
        [
            1695096230000,
            3393
        ],
        [
            1695097451000,
            3392
        ],
        [
            1695098673000,
            3392
        ],
        [
            1695099895000,
            3390
        ],
        [
            1695101153000,
            3390
        ],
        [
            1695102443000,
            3390
        ],
        [
            1695103661000,
            3387
        ],
        [
            1695104918000,
            3387
        ],
        [
            1695106184000,
            3387
        ],
        [
            1695107406000,
            3388
        ],
        [
            1695108735000,
            3390
        ],
        [
            1695109957000,
            3390
        ],
        [
            1695111175000,
            3390
        ],
        [
            1695112397000,
            3389
        ],
        [
            1695113619000,
            3389
        ],
        [
            1695114841000,
            3394
        ],
        [
            1695116099000,
            3396
        ],
        [
            1695117320000,
            3398
        ],
        [
            1695118614000,
            3400
        ],
        [
            1695119836000,
            3400
        ],
        [
            1695121058000,
            3404
        ],
        [
            1695122316000,
            3406
        ],
        [
            1695123538000,
            3406
        ],
        [
            1695124760000,
            3406
        ],
        [
            1695125978000,
            3407
        ],
        [
            1695127200000,
            3408
        ],
        [
            1695128422000,
            3410
        ],
        [
            1695129644000,
            3411
        ],
        [
            1695130866000,
            3414
        ],
        [
            1695132084000,
            3415
        ],
        [
            1695133302000,
            3421
        ],
        [
            1695134524000,
            3428
        ],
        [
            1695135781000,
            3435
        ],
        [
            1695137003000,
            3440
        ],
        [
            1695138261000,
            3443
        ],
        [
            1695139483000,
            3447
        ],
        [
            1695140741000,
            3455
        ],
        [
            1695141963000,
            3458
        ],
        [
            1695143293000,
            3461
        ],
        [
            1695144550000,
            3454
        ],
        [
            1695145769000,
            3450
        ],
        [
            1695146991000,
            3446
        ],
        [
            1695148284000,
            3441
        ],
        [
            1695149506000,
            3441
        ],
        [
            1695150764000,
            3440
        ],
        [
            1695152022000,
            3439
        ],
        [
            1695153244000,
            3438
        ],
        [
            1695154466000,
            3437
        ],
        [
            1695155688000,
            3436
        ],
        [
            1695156910000,
            3433
        ],
        [
            1695158122000,
            3432
        ],
        [
            1695159344000,
            3430
        ],
        [
            1695160565000,
            3430
        ],
        [
            1695161780000,
            3424
        ],
        [
            1695163002000,
            3427
        ],
        [
            1695164220000,
            3424
        ],
        [
            1695165438000,
            3424
        ],
        [
            1695166660000,
            3422
        ],
        [
            1695167875000,
            3421
        ],
        [
            1695169133000,
            3420
        ],
        [
            1695170387000,
            3417
        ],
        [
            1695171609000,
            3419
        ],
        [
            1695172866000,
            3417
        ],
        [
            1695174088000,
            3416
        ],
        [
            1695175346000,
            3415
        ],
        [
            1695176604000,
            3414
        ],
        [
            1695177822000,
            3412
        ],
        [
            1695179044000,
            3411
        ],
        [
            1695180266000,
            3410
        ],
        [
            1695181488000,
            3410
        ],
        [
            1695182739000,
            3410
        ],
        [
            1695183961000,
            3408
        ],
        [
            1695185180000,
            3408
        ],
        [
            1695186426000,
            3408
        ],
        [
            1695187684000,
            3408
        ],
        [
            1695188906000,
            3408
        ],
        [
            1695190128000,
            3408
        ],
        [
            1695191350000,
            3407
        ],
        [
            1695192572000,
            3408
        ],
        [
            1695193790000,
            3407
        ],
        [
            1695195012000,
            3406
        ],
        [
            1695196234000,
            3408
        ],
        [
            1695197492000,
            3408
        ],
        [
            1695198714000,
            3409
        ],
        [
            1695200007000,
            3412
        ],
        [
            1695201229000,
            3414
        ],
        [
            1695202451000,
            3415
        ],
        [
            1695203669000,
            3417
        ],
        [
            1695204891000,
            3419
        ],
        [
            1695206149000,
            3421
        ],
        [
            1695207364000,
            3422
        ],
        [
            1695208575000,
            3423
        ],
        [
            1695209797000,
            3425
        ],
        [
            1695211018000,
            3425
        ],
        [
            1695212240000,
            3424
        ],
        [
            1695213498000,
            3425
        ],
        [
            1695214756000,
            3428
        ],
        [
            1695215977000,
            3429
        ],
        [
            1695217199000,
            3430
        ],
        [
            1695218421000,
            3432
        ],
        [
            1695219639000,
            3437
        ],
        [
            1695220861000,
            3440
        ],
        [
            1695222080000,
            3440
        ],
        [
            1695223298000,
            3447
        ],
        [
            1695224522000,
            3456
        ],
        [
            1695225823000,
            3464
        ],
        [
            1695227040000,
            3470
        ],
        [
            1695228262000,
            3473
        ],
        [
            1695229484000,
            3475
        ],
        [
            1695230706000,
            3476
        ],
        [
            1695231928000,
            3465
        ],
        [
            1695233178000,
            3463
        ],
        [
            1695234386000,
            3461
        ],
        [
            1695235604000,
            3458
        ],
        [
            1695236828000,
            3456
        ],
        [
            1695238088000,
            3456
        ],
        [
            1695239333000,
            3453
        ],
        [
            1695240557000,
            3452
        ],
        [
            1695241801000,
            3451
        ],
        [
            1695243098000,
            3448
        ],
        [
            1695244322000,
            3447
        ],
        [
            1695245546000,
            3443
        ],
        [
            1695246771000,
            3444
        ],
        [
            1695247980000,
            3441
        ],
        [
            1695249201000,
            3441
        ],
        [
            1695250409000,
            3440
        ],
        [
            1695251706000,
            3438
        ],
        [
            1695252930000,
            3438
        ],
        [
            1695254139000,
            3439
        ],
        [
            1695255347000,
            3436
        ],
        [
            1695256556000,
            3435
        ],
        [
            1695257768000,
            3434
        ],
        [
            1695259048000,
            3429
        ],
        [
            1695260257000,
            3431
        ],
        [
            1695261501000,
            3431
        ],
        [
            1695262710000,
            3428
        ],
        [
            1695263927000,
            3428
        ],
        [
            1695265152000,
            3426
        ],
        [
            1695266361000,
            3426
        ],
        [
            1695267569000,
            3425
        ],
        [
            1695268794000,
            3423
        ],
        [
            1695270002000,
            3423
        ],
        [
            1695271259000,
            3421
        ],
        [
            1695272468000,
            3423
        ],
        [
            1695273676000,
            3420
        ],
        [
            1695274885000,
            3418
        ],
        [
            1695276130000,
            3419
        ],
        [
            1695277354000,
            3418
        ],
        [
            1695278563000,
            3419
        ],
        [
            1695279771000,
            3420
        ],
        [
            1695281016000,
            3419
        ],
        [
            1695282228000,
            3417
        ],
        [
            1695283472000,
            3418
        ],
        [
            1695284681000,
            3421
        ],
        [
            1695285889000,
            3419
        ],
        [
            1695287098000,
            3421
        ],
        [
            1695288307000,
            3419
        ],
        [
            1695289515000,
            3420
        ],
        [
            1695290724000,
            3420
        ],
        [
            1695291933000,
            3421
        ],
        [
            1695293153000,
            3421
        ],
        [
            1695294378000,
            3422
        ],
        [
            1695295587000,
            3424
        ],
        [
            1695296795000,
            3424
        ],
        [
            1695298012000,
            3427
        ],
        [
            1695299221000,
            3428
        ],
        [
            1695300442000,
            3430
        ],
        [
            1695301650000,
            3432
        ],
        [
            1695302871000,
            3430
        ],
        [
            1695304080000,
            3433
        ],
        [
            1695305305000,
            3435
        ],
        [
            1695306525000,
            3436
        ],
        [
            1695307733000,
            3437
        ],
        [
            1695308958000,
            3436
        ],
        [
            1695310246000,
            3435
        ],
        [
            1695311491000,
            3434
        ],
        [
            1695312715000,
            3435
        ],
        [
            1695313960000,
            3435
        ],
        [
            1695315169000,
            3434
        ],
        [
            1695316413000,
            3432
        ],
        [
            1695317622000,
            3428
        ],
        [
            1695318830000,
            3430
        ],
        [
            1695320051000,
            3428
        ],
        [
            1695321295000,
            3425
        ],
        [
            1695322504000,
            3425
        ],
        [
            1695323728000,
            3423
        ],
        [
            1695324953000,
            3423
        ],
        [
            1695326198000,
            3424
        ],
        [
            1695327423000,
            3422
        ],
        [
            1695328667000,
            3421
        ],
        [
            1695329888000,
            3421
        ],
        [
            1695331109000,
            3419
        ],
        [
            1695332318000,
            3418
        ],
        [
            1695333527000,
            3416
        ]

    ],
    "id_param": 123,
    "unidades": "%",
    "nombre": "Bateria Remote",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 3476,
        "minimo": 3340,
        "media": 3373.700218818381,
        "total": 3083562
    }
}

const dataBateriaTercero = {
    "data": [
        [1704067200000, 3413],
        [1704070800000, 3413],
        [1704074400000, 3413],
        [1704078000000, 3415],
        [1704081600000, 3417],
        [1704085200000, 3417],
        [1704088800000, 3419],
        [1704092400000, 3421],
        [1704096000000, 3421],
        [1704099600000, 3422],
        [1704103200000, 3423],
        [1704106800000, 3423],
        [1704110400000, 3426],
        [1704114000000, 3425],
        [1704117600000, 3424],
        [1704121200000, 3425],
        [1704124800000, 3426],
        [1704128400000, 3427],
        [1704132000000, 3427],
        [1704135600000, 3428],
        [1704139200000, 3428],
        [1704142800000, 3429],
        [1704146400000, 3425],
        [1704150000000, 3424],
        [1704153600000, 3422],
        [1704157200000, 3429],
        [1704160800000, 3430],
        [1704164400000, 3430],
        [1704168000000, 3432],
        [1704171600000, 3435],
        [1704175200000, 3435],
        [1704178800000, 3436],
        [1704182400000, 3436],
        [1704186000000, 3440],
        [1704189600000, 3440],
        [1704193200000, 3435],
        [1704196800000, 3436],
        [1704200400000, 3437],
        [1704204000000, 3437],
        [1704207600000, 3440],
        [1704211200000, 3449],
        [1704214800000, 3441],
        [1704218400000, 3443],
        [1704222000000, 3445],
        [1704225600000, 3447],
        [1704229200000, 3249],
        [1704232800000, 3211],
        [1704236400000, 3223],
        [1704240000000, 3235],
        [1704243600000, 3247],
        [1704247200000, 3249],
        [1704250800000, 3241],
        [1704254400000, 3243],
        [1704258000000, 3245],
        [1704261600000, 3247],
        [1704265200000, 3249],
        [1704268800000, 3241],
        [1704272400000, 3243],
        [1704276000000, 3245],
        [1704279600000, 3247],
        [1704283200000, 3249],
        [1704286800000, 3231],
        [1704290400000, 3233],
        [1704294000000, 3235],
        [1704297600000, 3237],
        [1704301200000, 3239],
        [1704304800000, 3231],
        [1704308400000, 3233],
        [1704312000000, 3235],
        [1704315600000, 3237],
        [1704319200000, 3239],
        [1704322800000, 3231],
        [1704326400000, 3233],
        [1704330000000, 3235],
        [1704333600000, 3237],
        [1704337200000, 3239],
        [1704340800000, 3231],
        [1704344400000, 3233],
        [1704348000000, 3235],
        [1704351600000, 3237],
        [1704355200000, 3239],
        [1704358800000, 3251],
        [1704362400000, 3223],
        [1704366000000, 3225],
        [1704369600000, 3227],
        [1704373200000, 3229],
        [1704376800000, 3221],
        [1704380400000, 3223],
        [1704384000000, 3225],
        [1704387600000, 3227],
        [1704391200000, 3229],
        [1704394800000, 3221],
        [1704398400000, 3223],
        [1704402000000, 3225],
        [1704405600000, 3227],
        [1704409200000, 3229],
        [1704412800000, 3221],
        [1704416400000, 3223],
        [1704420000000, 3225],
        [1704423600000, 3227],
        [1704427200000, 3229],
        [1704430800000, 3221],
        [1704434400000, 3223],
        [1704438000000, 3225],
        [1704441600000, 3217],
        [1704445200000, 3219],
        [1704448800000, 3211],
        [1704452400000, 3213],
        [1704456000000, 3215],
        [1704459600000, 3217],
        [1704463200000, 3219],
        [1704466800000, 3211],
        [1704470400000, 3213],
        [1704474000000, 3215],
        [1704477600000, 3217],
        [1704481200000, 2639],
        [1704484800000, 2641],
        [1704488400000, 2643],
        [1704492000000, 2645],
        [1704495600000, 2447],
        [1704499200000, 2449],
        [1704502800000, 2451],
        [1704506400000, 2453],
        [1704510000000, 2455],
        [1704513600000, 2457],
        [1704517200000, 2429],
        [1704520800000, 2421],
        [1704524400000, 2423],
        [1704528000000, 2425],
        [1704531600000, 2427],
        [1704535200000, 2429],
        [1704538800000, 1421],
        [1704542400000, 1423],
        [1704546000000, 1425],
        [1704549600000, 1420],
        [1704553200000, 1420],
        [1704556800000, 1420],
        [1704560400000, 1420],
        [1704564000000, 1020],
        [1704567600000, 1020],
        [1704571200000, 520],
        [1704574800000, 520],
        [1704578400000, 520],
        [1704582000000, 520],
        [1704585600000, 520],
        [1704589200000, 520],
        [1704592800000, 520],
        [1704596400000, 520],
        [1704600000000, 520],
        [1704603600000, 520],
        [1704607200000, 520],
        [1704610800000, 520],
        [1704614400000, 520],
        [1704618000000, 520],
        [1704621600000, 520],
        [1704625200000, 520],
        [1704628800000, 520],
        [1704632400000, 520],
        [1704636000000, 520],
        [1704639600000, 520],
        [1704643200000, 520],
        [1704646800000, 520],
        [1704650400000, 520],
        [1704654000000, 520],
        [1704657600000, 520],
        [1704661200000, 520],
        [1704664800000, 520],
        [1704668400000, 520]
    ],
    "id_param": 569,
    "unidades": "%",
    "nombre": "Bateria Remote",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 3520,
        "minimo": 3086,
        "media": 3373.700218818381,
        "total": 3083562
    }
}

const dataBateriaCuarto = {
    "data": [
        [
            1694901929000,
            3419
        ],
        [
            1694903152000,
            3418
        ],
        [
            1694904374000,
            3418
        ],
        [
            1694905596000,
            3415
        ],
        [
            1694906814000,
            3416
        ],
        [
            1694908036000,
            3415
        ],
        [
            1694909258000,
            3414
        ],
        [
            1694910480000,
            3413
        ],
        [
            1694911737000,
            3412
        ],
        [
            1694913139000,
            3412
        ],
        [
            1694914361000,
            3411
        ],
        [
            1694915583000,
            3409
        ],
        [
            1694916805000,
            3410
        ],
        [
            1694918020000,
            3409
        ],
        [
            1694919270000,
            3408
        ],
        [
            1694920492000,
            3406
        ],
        [
            1694921714000,
            3408
        ],
        [
            1694922936000,
            3407
        ],
        [
            1694924158000,
            3407
        ],
        [
            1694925380000,
            3407
        ],
        [
            1694926591000,
            3406
        ],
        [
            1694927813000,
            3403
        ],
        [
            1694929035000,
            3406
        ],
        [
            1694930257000,
            3405
        ],
        [
            1694931480000,
            3403
        ],
        [
            1694932730000,
            3405
        ],
        [
            1694933952000,
            3404
        ],
        [
            1694935174000,
            3405
        ],
        [
            1694936396000,
            3405
        ],
        [
            1694937722000,
            3407
        ],
        [
            1694938976000,
            3404
        ],
        [
            1694940198000,
            3405
        ],
        [
            1694941420000,
            3404
        ],
        [
            1694942635000,
            3405
        ],
        [
            1694943853000,
            3404
        ],
        [
            1694945075000,
            3405
        ],
        [
            1694946369000,
            3403
        ],
        [
            1694947591000,
            3403
        ],
        [
            1694948813000,
            3401
        ],
        [
            1694950070000,
            3408
        ],
        [
            1694951292000,
            3409
        ],
        [
            1694952550000,
            3407
        ],
        [
            1694953844000,
            3407
        ],
        [
            1694955137000,
            3410
        ],
        [
            1694956352000,
            3411
        ],
        [
            1694957645000,
            3416
        ],
        [
            1694958867000,
            3419
        ],
        [
            1694960089000,
            3415
        ],
        [
            1694961418000,
            3425
        ],
        [
            1694962643000,
            3427
        ],
        [
            1694963865000,
            3431
        ],
        [
            1694965123000,
            3426
        ],
        [
            1694966345000,
            3427
        ],
        [
            1694967603000,
            3432
        ],
        [
            1694968825000,
            3433
        ],
        [
            1694970047000,
            3431
        ],
        [
            1694971301000,
            3430
        ],
        [
            1694972602000,
            3427
        ],
        [
            1694973876000,
            3427
        ],
        [
            1694975134000,
            3423
        ],
        [
            1694976356000,
            3420
        ],
        [
            1694977578000,
            3418
        ],
        [
            1694978800000,
            3420
        ],
        [
            1694980023000,
            3418
        ],
        [
            1694981245000,
            3415
        ],
        [
            1694982472000,
            3416
        ],
        [
            1694983690000,
            3413
        ],
        [
            1694984909000,
            3413
        ],
        [
            1694986131000,
            3412
        ],
        [
            1694987343000,
            3410
        ],
        [
            1694988601000,
            3410
        ],
        [
            1694989823000,
            3409
        ],
        [
            1694991045000,
            3409
        ],
        [
            1694992266000,
            3408
        ],
        [
            1694993488000,
            3406
        ],
        [
            1694994710000,
            3408
        ],
        [
            1694995932000,
            3406
        ],
        [
            1694997154000,
            3407
        ],
        [
            1694998444000,
            3406
        ],
        [
            1694999689000,
            3403
        ],
        [
            1695000954000,
            3403
        ],
        [
            1695002212000,
            3402
        ],
        [
            1695003470000,
            3400
        ],
        [
            1695004688000,
            3400
        ],
        [
            1695005916000,
            3400
        ],
        [
            1695007133000,
            3399
        ],
        [
            1695008391000,
            3396
        ],
        [
            1695009633000,
            3398
        ],
        [
            1695010879000,
            3396
        ],
        [
            1695012134000,
            3396
        ],
        [
            1695013424000,
            3396
        ],
        [
            1695014645000,
            3395
        ],
        [
            1695015867000,
            3396
        ],
        [
            1695017125000,
            3395
        ],
        [
            1695018423000,
            3396
        ],
        [
            1695019654000,
            3393
        ],
        [
            1695020872000,
            3395
        ],
        [
            1695022094000,
            3393
        ],
        [
            1695023316000,
            3395
        ],
        [
            1695024538000,
            3396
        ],
        [
            1695025756000,
            3397
        ],
        [
            1695026977000,
            3401
        ],
        [
            1695028199000,
            3404
        ],
        [
            1695029421000,
            3405
        ],
        [
            1695030643000,
            3406
        ],
        [
            1695031865000,
            3407
        ],
        [
            1695033080000,
            3409
        ],
        [
            1695034327000,
            3408
        ],
        [
            1695035545000,
            3411
        ],
        [
            1695036767000,
            3412
        ],
        [
            1695038061000,
            3416
        ],
        [
            1695039390000,
            3416
        ],
        [
            1695040608000,
            3420
        ],
        [
            1695041866000,
            3420
        ],
        [
            1695043088000,
            3422
        ],
        [
            1695044310000,
            3425
        ],
        [
            1695045528000,
            3423
        ],
        [
            1695046750000,
            3425
        ],
        [
            1695048008000,
            3423
        ],
        [
            1695049230000,
            3426
        ],
        [
            1695050444000,
            3430
        ],
        [
            1695051666000,
            3426
        ],
        [
            1695052924000,
            3426
        ],
        [
            1695054142000,
            3426
        ],
        [
            1695055364000,
            3427
        ],
        [
            1695056654000,
            3427
        ],
        [
            1695057876000,
            3425
        ],
        [
            1695059098000,
            3424
        ],
        [
            1695060353000,
            3419
        ],
        [
            1695061610000,
            3421
        ],
        [
            1695062828000,
            3420
        ],
        [
            1695064086000,
            3413
        ],
        [
            1695065380000,
            3416
        ],
        [
            1695066598000,
            3413
        ],
        [
            1695067820000,
            3413
        ],
        [
            1695069038000,
            3412
        ],
        [
            1695070260000,
            3410
        ],
        [
            1695071482000,
            3409
        ],
        [
            1695072700000,
            3408
        ],
        [
            1695073922000,
            3408
        ],
        [
            1695075141000,
            3408
        ],
        [
            1695076363000,
            3407
        ],
        [
            1695077621000,
            3406
        ],
        [
            1695078950000,
            3405
        ],
        [
            1695080168000,
            3401
        ],
        [
            1695081541000,
            3401
        ],
        [
            1695082759000,
            3402
        ],
        [
            1695083981000,
            3402
        ],
        [
            1695085199000,
            3399
        ],
        [
            1695086421000,
            3397
        ],
        [
            1695087652000,
            3397
        ],
        [
            1695088870000,
            3396
        ],
        [
            1695090092000,
            3396
        ],
        [
            1695091310000,
            3395
        ],
        [
            1695092532000,
            3394
        ],
        [
            1695093790000,
            3392
        ],
        [
            1695095008000,
            3393
        ],
        [
            1695096230000,
            3393
        ],
        [
            1695097451000,
            3392
        ],
        [
            1695098673000,
            3392
        ],
        [
            1695099895000,
            3390
        ],
        [
            1695101153000,
            3390
        ],
        [
            1695102443000,
            3390
        ],
        [
            1695103661000,
            3387
        ],
        [
            1695104918000,
            3387
        ],
        [
            1695106184000,
            3387
        ],
        [
            1695107406000,
            3388
        ],
        [
            1695108735000,
            3390
        ],
        [
            1695109957000,
            3390
        ],
        [
            1695111175000,
            3390
        ],
        [
            1695112397000,
            3389
        ],
        [
            1695113619000,
            3389
        ],
        [
            1695114841000,
            3394
        ],
        [
            1695116099000,
            3396
        ],
        [
            1695117320000,
            3398
        ],
        [
            1695118614000,
            3400
        ],
        [
            1695119836000,
            3400
        ],
        [
            1695121058000,
            3404
        ],
        [
            1695122316000,
            3406
        ],
        [
            1695123538000,
            3406
        ],
        [
            1695124760000,
            3406
        ],
        [
            1695125978000,
            3407
        ],
        [
            1695127200000,
            3408
        ],
        [
            1695128422000,
            3410
        ],
        [
            1695129644000,
            3411
        ],
        [
            1695130866000,
            3414
        ],
        [
            1695132084000,
            3415
        ],
        [
            1695133302000,
            3421
        ],
        [
            1695134524000,
            3428
        ],
        [
            1695135781000,
            3435
        ],
        [
            1695137003000,
            3440
        ],
        [
            1695138261000,
            3443
        ],
        [
            1695139483000,
            3447
        ],
        [
            1695140741000,
            3455
        ],
        [
            1695141963000,
            3458
        ],
        [
            1695143293000,
            3461
        ],
        [
            1695144550000,
            3454
        ],
        [
            1695145769000,
            3450
        ],
        [
            1695146991000,
            3446
        ],
        [
            1695148284000,
            3441
        ],
        [
            1695149506000,
            3441
        ],
        [
            1695150764000,
            3440
        ],
        [
            1695152022000,
            3439
        ],
        [
            1695153244000,
            3438
        ],
        [
            1695154466000,
            3437
        ],
        [
            1695155688000,
            3436
        ],
        [
            1695156910000,
            3433
        ],
        [
            1695158122000,
            3432
        ],
        [
            1695159344000,
            3430
        ],
        [
            1695160565000,
            3430
        ],
        [
            1695161780000,
            3424
        ],
        [
            1695163002000,
            3427
        ],
        [
            1695164220000,
            3424
        ],
        [
            1695165438000,
            3424
        ],
        [
            1695166660000,
            3422
        ],
        [
            1695167875000,
            3421
        ],
        [
            1695169133000,
            3420
        ],
        [
            1695170387000,
            3417
        ],
        [
            1695171609000,
            3419
        ],
        [
            1695172866000,
            3417
        ],
        [
            1695174088000,
            3416
        ],
        [
            1695175346000,
            3415
        ],
        [
            1695176604000,
            3414
        ],
        [
            1695177822000,
            3412
        ],
        [
            1695179044000,
            3411
        ],
        [
            1695180266000,
            3410
        ],
        [
            1695181488000,
            3410
        ],
        [
            1695182739000,
            3410
        ],
        [
            1695183961000,
            3408
        ],
        [
            1695185180000,
            3408
        ],
        [
            1695186426000,
            3408
        ],
        [
            1695187684000,
            3408
        ],
        [
            1695188906000,
            3408
        ],
        [
            1695190128000,
            3408
        ],
        [
            1695191350000,
            3407
        ],
        [
            1695192572000,
            3408
        ],
        [
            1695193790000,
            3407
        ],
        [
            1695195012000,
            3406
        ],
        [
            1695196234000,
            3408
        ],
        [
            1695197492000,
            3408
        ],
        [
            1695198714000,
            3409
        ],
        [
            1695200007000,
            3412
        ],
        [
            1695201229000,
            3414
        ],
        [
            1695202451000,
            3415
        ],
        [
            1695203669000,
            3417
        ],
        [
            1695204891000,
            3419
        ],
        [
            1695206149000,
            3421
        ],
        [
            1695207364000,
            3422
        ],
        [
            1695208575000,
            3423
        ],
        [
            1695209797000,
            3425
        ],
        [
            1695211018000,
            3425
        ],
        [
            1695212240000,
            3424
        ],
        [
            1695213498000,
            3425
        ],
        [
            1695214756000,
            3428
        ],
        [
            1695215977000,
            3429
        ],
        [
            1695217199000,
            3430
        ],
        [
            1695218421000,
            3432
        ],
        [
            1695219639000,
            3437
        ],
        [
            1695220861000,
            3440
        ],
        [
            1695222080000,
            3440
        ],
        [
            1695223298000,
            3447
        ],
        [
            1695224522000,
            3456
        ],
        [
            1695225823000,
            3464
        ],
        [
            1695227040000,
            3470
        ],
        [
            1695228262000,
            3473
        ],
        [
            1695229484000,
            3475
        ],
        [
            1695230706000,
            3476
        ],
        [
            1695231928000,
            3465
        ],
        [
            1695233178000,
            3463
        ],
        [
            1695234386000,
            3461
        ],
        [
            1695235604000,
            3458
        ],
        [
            1695236828000,
            3456
        ],
        [
            1695238088000,
            3456
        ],
        [
            1695239333000,
            3453
        ],
        [
            1695240557000,
            3452
        ],
        [
            1695241801000,
            3451
        ],
        [
            1695243098000,
            3448
        ],
        [
            1695244322000,
            3447
        ],
        [
            1695245546000,
            3443
        ],
        [
            1695246771000,
            3444
        ],
        [
            1695247980000,
            3441
        ],
        [
            1695249201000,
            3441
        ],
        [
            1695250409000,
            3440
        ],
        [
            1695251706000,
            3438
        ],
        [
            1695252930000,
            3438
        ],
        [
            1695254139000,
            3439
        ],
        [
            1695255347000,
            3436
        ],
        [
            1695256556000,
            3435
        ],
        [
            1695257768000,
            3434
        ],
        [
            1695259048000,
            3429
        ],
        [
            1695260257000,
            3431
        ],
        [
            1695261501000,
            3431
        ],
        [
            1695262710000,
            3428
        ],
        [
            1695263927000,
            3428
        ],
        [
            1695265152000,
            3426
        ],
        [
            1695266361000,
            3426
        ],
        [
            1695267569000,
            3425
        ],
        [
            1695268794000,
            3423
        ],
        [
            1695270002000,
            3423
        ],
        [
            1695271259000,
            3421
        ],
        [
            1695272468000,
            3423
        ],
        [
            1695273676000,
            3420
        ],
        [
            1695274885000,
            3418
        ],
        [
            1695276130000,
            3419
        ],
        [
            1695277354000,
            3418
        ],
        [
            1695278563000,
            3419
        ],
        [
            1695279771000,
            3420
        ],
        [
            1695281016000,
            3419
        ],
        [
            1695282228000,
            3417
        ],
        [
            1695283472000,
            3418
        ],
        [
            1695284681000,
            3421
        ],
        [
            1695285889000,
            3419
        ],
        [
            1695287098000,
            3421
        ],
        [
            1695288307000,
            3419
        ],
        [
            1695289515000,
            3420
        ],
        [
            1695290724000,
            3420
        ],
        [
            1695291933000,
            3421
        ],
        [
            1695293153000,
            3421
        ],
        [
            1695294378000,
            3422
        ],
        [
            1695295587000,
            3424
        ],
        [
            1695296795000,
            3424
        ],
        [
            1695298012000,
            3427
        ],
        [
            1695299221000,
            3428
        ],
        [
            1695300442000,
            3430
        ],
        [
            1695301650000,
            3432
        ],
        [
            1695302871000,
            3430
        ],
        [
            1695304080000,
            3433
        ],
        [
            1695305305000,
            3435
        ],
        [
            1695306525000,
            3436
        ],
        [
            1695307733000,
            3437
        ],
        [
            1695308958000,
            3436
        ],
        [
            1695310246000,
            3435
        ],
        [
            1695311491000,
            3434
        ],
        [
            1695312715000,
            3435
        ],
        [
            1695313960000,
            3435
        ],
        [
            1695315169000,
            3434
        ],
        [
            1695316413000,
            3432
        ],
        [
            1695317622000,
            3428
        ],
        [
            1695318830000,
            3430
        ],
        [
            1695320051000,
            3428
        ],
        [
            1695321295000,
            3425
        ],
        [
            1695322504000,
            3425
        ],
        [
            1695323728000,
            3423
        ],
        [
            1695324953000,
            3423
        ],
        [
            1695326198000,
            3424
        ],
        [
            1695327423000,
            3422
        ],
        [
            1695328667000,
            3421
        ],
        [
            1695329888000,
            3421
        ],
        [
            1695331109000,
            3419
        ],
        [
            1695332318000,
            3418
        ],
        [
            1695333527000,
            3416
        ]
    ],
    "id_param": 20,
    "unidades": "%",
    "nombre": "Bateria Remote",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 3520,
        "minimo": 3086,
        "media": 3373.700218818381,
        "total": 3083562
    }
}

const dataBateriaQuinto = {
    "data": [
        [1704067200000, 4013],
        [1704070800000, 4013],
        [1704074400000, 4013],
        [1704078000000, 4015],
        [1704081600000, 4017],
        [1704085200000, 4017],
        [1704088800000, 4019],
        [1704092400000, 4021],
        [1704096000000, 4021],
        [1704099600000, 4022],
        [1704103200000, 4023],
        [1704106800000, 4023],
        [1704110400000, 4026],
        [1704114000000, 4025],
        [1704117600000, 4024],
        [1704121200000, 4025],
        [1704124800000, 4026],
        [1704128400000, 4027],
        [1704132000000, 4027],
        [1704135600000, 4028],
        [1704139200000, 4028],
        [1704142800000, 4029],
        [1704146400000, 4025],
        [1704150000000, 4024],
        [1704153600000, 4022],
        [1704157200000, 4029],
        [1704160800000, 4030],
        [1704164400000, 4030],
        [1704168000000, 4032],
        [1704171600000, 4035],
        [1704175200000, 4035],
        [1704178800000, 4036],
        [1704182400000, 4036],
        [1704186000000, 4040],
        [1704189600000, 4040],
        [1704193200000, 4035],
        [1704196800000, 4036],
        [1704200400000, 4037],
        [1704204000000, 4037],
        [1704207600000, 4040],
        [1704211200000, 4049],
        [1704214800000, 4041],
        [1704218400000, 4043],
        [1704222000000, 4045],
        [1704225600000, 4047],
        [1704229200000, 4049],
        [1704232800000, 4011],
        [1704236400000, 4023],
        [1704240000000, 4035],
        [1704243600000, 4047],
        [1704247200000, 4049],
        [1704250800000, 4041],
        [1704254400000, 4043],
        [1704258000000, 4045],
        [1704261600000, 4047],
        [1704265200000, 4049],
        [1704268800000, 4041],
        [1704272400000, 4043],
        [1704276000000, 4045],
        [1704279600000, 4047],
        [1704283200000, 4049],
        [1704286800000, 4031],
        [1704290400000, 4033],
        [1704294000000, 4035],
        [1704297600000, 4037],
        [1704301200000, 4039],
        [1704304800000, 4031],
        [1704308400000, 4033],
        [1704312000000, 4035],
        [1704315600000, 4037],
        [1704319200000, 4039],
        [1704322800000, 4031],
        [1704326400000, 4033],
        [1704330000000, 4035],
        [1704333600000, 4037],
        [1704337200000, 4039],
        [1704340800000, 4031],
        [1704344400000, 4033],
        [1704348000000, 4035],
        [1704351600000, 4037],
        [1704355200000, 4039],
        [1704358800000, 4051],
        [1704362400000, 4023],
        [1704366000000, 4025],
        [1704369600000, 4027],
        [1704373200000, 4029],
        [1704376800000, 4021],
        [1704380400000, 4023],
        [1704384000000, 4025],
        [1704387600000, 4027],
        [1704391200000, 4029],
        [1704394800000, 4021],
        [1704398400000, 4023],
        [1704402000000, 4025],
        [1704405600000, 4027],
        [1704409200000, 4029],
        [1704412800000, 4021],
        [1704416400000, 4023],
        [1704420000000, 4025],
        [1704423600000, 4027],
        [1704427200000, 4029],
        [1704430800000, 4021],
        [1704434400000, 4023],
        [1704438000000, 4025],
        [1704441600000, 4017],
        [1704445200000, 4019],
        [1704448800000, 4011],
        [1704452400000, 4013],
        [1704456000000, 4015],
        [1704459600000, 4017],
        [1704463200000, 4019],
        [1704466800000, 4011],
        [1704470400000, 4013],
        [1704474000000, 4015],
        [1704477600000, 4017],
        [1704481200000, 4039],
        [1704484800000, 4041],
        [1704488400000, 4043],
        [1704492000000, 4045],
        [1704495600000, 4047],
        [1704499200000, 4049],
        [1704502800000, 4051],
        [1704506400000, 4053],
        [1704510000000, 4055],
        [1704513600000, 4057],
        [1704517200000, 4029],
        [1704520800000, 4021],
        [1704524400000, 4023],
        [1704528000000, 4025],
        [1704531600000, 4027],
        [1704535200000, 4029],
        [1704538800000, 4021],
        [1704542400000, 4023],
        [1704546000000, 4025],
        [1704549600000, 4020],
        [1704553200000, 4020],
        [1704556800000, 4020],
        [1704560400000, 4020],
        [1704564000000, 4020],
        [1704567600000, 4020],
        [1704571200000, 4020],
        [1704574800000, 4020],
        [1704578400000, 4020],
        [1704582000000, 4020],
        [1704585600000, 4020],
        [1704589200000, 4020],
        [1704592800000, 4020],
        [1704596400000, 4020],
        [1704600000000, 4020],
        [1704603600000, 4020],
        [1704607200000, 4020],
        [1704610800000, 4020],
        [1704614400000, 4020],
        [1704618000000, 4018],
        [1704621600000, 4018],
        [1704625200000, 4018],
        [1704628800000, 4019],
        [1704632400000, 4019],
        [1704636000000, 4020],
        [1704639600000, 4020],
        [1704643200000, 4020],
        [1704646800000, 4020],
        [1704650400000, 4020],
        [1704654000000, 4020],
        [1704657600000, 4020],
        [1704661200000, 4020],
        [1704664800000, 4020],
        [1704668400000, 4020]
    ],
    "id_param": 12,
    "unidades": "%",
    "nombre": "Bateria Remote",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 4050,
        "minimo": 4020,
        "media": 3373.700218818381,
        "total": 3083562
    }

}

const dataTemperatura = {
    "data": [
        [
            1672564515000,
            18.7
        ],
        [
            1672568115000,
            18.8
        ],
        [
            1672571715000,
            18.8

        ],
        [
            1672575315000,
            19
        ],
        [
            1672578915000,
            19.2

        ],
        [
            1672582515000,
            19.2

        ],
        [
            1672586115000,
            19.9

        ],
        [
            1672589715000,
            19.4

        ],
        [
            1672593315000,
            19.6

        ],
        [
            1672596915000,
            20.2

        ],
        [
            1672600515000,
            19.9

        ],
        [
            1672604115000,
            20

        ],
        [
            1672607715000,
            19.5

        ],
        [
            1672611315000,
            19.7

        ],
        [
            1672614915000,
            20
        ],
        [
            1672618515000,
            20.6
        ],
        [
            1672622115000,
            21.2

        ],
        [
            1672625715000,
            20.7

        ],
        [
            1672629315000,
            20.1
        ],
        [
            1672632915000,
            19.4
        ],
        [
            1672636515000,
            18.8
        ],
        [
            1672640115000,
            19.3
        ],
        [
            1672643715000,
            19
        ],
        [
            1672647315000,
            19.3
        ],
        [
            1672650915000,
            19.3
        ],
        [
            1672654515000,
            19.1
        ],
        [
            1672658115000,
            19.5
        ],
        [
            1672661715000,
            19.8
        ],
        [
            1672665315000,
            20.2
        ],
        [
            1672668915000,
            20.9
        ],
        [
            1672672515000,
            20.5
        ],
        [
            1672676115000,
            20.4
        ],
        [
            1672679715000,
            19.7
        ],
        [
            1672683315000,
            19.5
        ],
        [
            1672686915000,
            19.8
        ],
        [
            1672690515000,
            20.3
        ],
        [
            1672694115000,
            20.3
        ],
        [
            1672697715000,
            20.1
        ],
        [
            1672701315000,
            20.7
        ],
        [
            1672704915000,
            21.1
        ],
        [
            1672708515000,
            21.2
        ],
        [
            1672712115000,
            21.5
        ],
        [
            1672715715000,
            22
        ],
        [
            1672719315000,
            22.2
        ],
        [
            1672722915000,
            22.1
        ],
        [
            1672726515000,
            21.7
        ],
        [
            1672730115000,
            21.3
        ],
        [
            1672733715000,
            21
        ],
        [
            1672737315000,
            20.5
        ],
        [
            1672740915000,
            20.3
        ],

    ],
    "id_param": 90,
    "unidades": "ºC",
    "nombre": "Temperatura",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 32.9,
        "minimo": 13.8,
        "media": 20.084682713347927,
        "total": 18357.400000000005
    }
}

const dataPh = {
    "data": [
        [
            1672564515000,
            5.71
        ],
        [
            1672568115000,
            5.71
        ],
        [
            1672571715000,
            5.79
        ],
        [
            1672575315000,
            5.79
        ],
        [
            1672578915000,
            5.83
        ],
        [
            1672582515000,
            5.79
        ],
        [
            1672586115000,
            5.79
        ],
        [
            1672589715000,
            5.68
        ],
        [
            1672593315000,
            5.71
        ],
        [
            1672596915000,
            5.68
        ],
        [
            1672600515000,
            5.68
        ],
        [
            1672604115000,
            5.68
        ],
        [
            1672607715000,
            5.68
        ],
        [
            1672611315000,
            5.71
        ],
        [
            1672614915000,
            5.71
        ],
        [
            1672618515000,
            5.71
        ],
        [
            1672622115000,
            5.6
        ],
        [
            1672625715000,
            5.6
        ],
        [
            1672629315000,
            5.6
        ],
        [
            1672632915000,
            5.64
        ],
        [
            1672636515000,
            5.64
        ],
        [
            1672640115000,
            5.6
        ],
        [
            1672643715000,
            5.64
        ],
        [
            1672647315000,
            5.75
        ],
        [
            1672650915000,
            5.79
        ],
        [
            1672654515000,
            5.9
        ],
        [
            1672658115000,
            5.83
        ],
        [
            1672661715000,
            5.75
        ],
        [
            1672665315000,
            5.79
        ],
        [
            1672668915000,
            5.71
        ],
        [
            1672672515000,
            5.71
        ],
        [
            1672676115000,
            5.75
        ],
        [
            1672679715000,
            5.71
        ],
        [
            1672683315000,
            5.64
        ],
        [
            1672686915000,
            5.6
        ],
        [
            1672690515000,
            5.64
        ],
        [
            1672694115000,
            5.64
        ],
        [
            1672697715000,
            5.68
        ],
        [
            1672701315000,
            5.64
        ],
        [
            1672704915000,
            5.6
        ],
        [
            1672708515000,
            5.6
        ],
        [
            1672712115000,
            5.64
        ],
        [
            1672715715000,
            5.71
        ],
        [
            1672719315000,
            5.64
        ],
        [
            1672722915000,
            5.75
        ],
        [
            1672726515000,
            5.71
        ],
        [
            1672730115000,
            5.75
        ],
        [
            1672733715000,
            5.83
        ],
        [
            1672737315000,
            5.86
        ],
        [
            1672740915000,
            5.94
        ],
    ],
    "id_param": 91,
    "unidades": "pH",
    "nombre": "PH",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 8,
        "minimo": 5.1,
        "media": 6.64687842278205,
        "total": 6068.600000000012
    }
}

const dataHumedadSegun = {
    "data": [
        [
            1672564515000,
            20.53
        ],
        [
            1672568115000,
            19.55
        ],
        [
            1672571715000,
            22.19
        ],
        [
            1672575315000,
            20.53
        ],
        [
            1672578915000,
            19.65
        ],
        [
            1672582515000,
            20.53
        ],
        [
            1672586115000,
            22.48
        ],
        [
            1672589715000,
            20.63
        ],
        [
            1672593315000,
            22.48
        ],
        [
            1672596915000,
            22.39
        ],
        [
            1672600515000,
            20.82
        ],
        [
            1672604115000,
            22.09
        ],
        [
            1672607715000,
            21.51
        ],
        [
            1672611315000,
            22.87
        ],
        [
            1672614915000,
            20.14
        ],
        [
            1672618515000,
            21.21
        ],
        [
            1672622115000,
            23.07
        ],
        [
            1672625715000,
            21.31
        ],
        [
            1672629315000,
            23.17
        ],
        [
            1672632915000,
            22.68
        ],
        [
            1672636515000,
            22.29
        ],
        [
            1672640115000,
            20.43
        ],
        [
            1672643715000,
            20.43
        ],
        [
            1672647315000,
            22.68
        ],
        [
            1672650915000,
            20.23
        ],
        [
            1672654515000,
            22.09
        ],
        [
            1672658115000,
            22.09
        ],
        [
            1672661715000,
            23.07
        ],
        [
            1672665315000,
            23.17
        ],
        [
            1672668915000,
            22.29
        ],
        [
            1672672515000,
            22.68
        ],
        [
            1672676115000,
            21.51
        ],
        [
            1672679715000,
            20.33
        ],
        [
            1672683315000,
            20.53
        ],
        [
            1672686915000,
            21.8
        ],
        [
            1672690515000,
            21.21
        ],
        [
            1672694115000,
            22.58
        ],
        [
            1672697715000,
            21.11
        ],
        [
            1672701315000,
            21.02
        ],
        [
            1672704915000,
            20.23

        ],
        [
            1672708515000,
            22.58
        ],
        [
            1672712115000,
            21.6
        ],
        [
            1672715715000,
            21.51
        ],
        [
            1672719315000,
            21.99
        ],
        [
            1672722915000,
            21.9
        ],
        [
            1672726515000,
            22.39,
        ],
        [
            1672730115000,
            20.92
        ],
        [
            1672733715000,
            22.48
        ],
        [
            1672737315000,
            22.39,
        ],
        [
            1672740915000,
            22.39
        ],
    ],
    "id_param": 1,
    "unidades": "%",
    "nombre": "Humedad",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 38.8,
        "minimo": 21.3,
        "media": 28.01540983606554,
        "total": 25634.09999999997
    }
}

const dataBateriaSegun = {
    "data": [
        [
            1694951292000,
            3409
        ],
        [
            1694952550000,
            3407
        ],
        [
            1694953844000,
            3407
        ],
        [
            1694955137000,
            3410
        ],
        [
            1694956352000,
            3411
        ],
        [
            1694957645000,
            3416
        ],
        [
            1694958867000,
            3419
        ],
        [
            1694960089000,
            3415
        ],
        [
            1694961418000,
            3425
        ],
        [
            1694962643000,
            3427
        ],
        [
            1694963865000,
            3431
        ],
        [
            1694965123000,
            3426
        ],
        [
            1694966345000,
            3427
        ],
        [
            1694967603000,
            3432
        ],
        [
            1694968825000,
            3433
        ],
        [
            1694970047000,
            3431
        ],
        [
            1694971301000,
            3430
        ],
        [
            1694972602000,
            3427
        ],
        [
            1694973876000,
            3427
        ],
        [
            1694975134000,
            3423
        ],
        [
            1694976356000,
            3420
        ],
        [
            1694977578000,
            3418
        ],
        [
            1694978800000,
            3420
        ],
        [
            1694980023000,
            3418
        ],
        [
            1694981245000,
            3415
        ],
        [
            1694982472000,
            3416
        ],
        [
            1694983690000,
            3413
        ],
        [
            1694984909000,
            3413
        ],
        [
            1694986131000,
            3412
        ],
        [
            1694987343000,
            3410
        ],
        [
            1694988601000,
            3410
        ],
        [
            1694989823000,
            3409
        ],
        [
            1694991045000,
            3409
        ],
        [
            1694992266000,
            3408
        ],
        [
            1694993488000,
            3406
        ],
        [
            1694994710000,
            3408
        ],
        [
            1694995932000,
            3406
        ],
        [
            1694997154000,
            3407
        ],
        [
            1694998444000,
            3406
        ],
        [
            1694999689000,
            3403
        ],
        [
            1695000954000,
            3403
        ],
        [
            1695002212000,
            3402
        ],
        [
            1695003470000,
            3400
        ],
        [
            1695004688000,
            3400
        ],
        [
            1695005916000,
            3400
        ],
        [
            1695007133000,
            3399
        ],
        [
            1695008391000,
            3396
        ],
        [
            1695009633000,
            3398
        ],
        [
            1695010879000,
            3396
        ],
        [
            1695012134000,
            3396
        ],
        [
            1695013424000,
            3396
        ],
        [
            1695014645000,
            3395
        ],
        [
            1695015867000,
            3396
        ],
        [
            1695017125000,
            3395
        ],
        [
            1695018423000,
            3396
        ],
        [
            1695019654000,
            3393
        ],
        [
            1695020872000,
            3395
        ],
        [
            1695022094000,
            3393
        ],
        [
            1695023316000,
            3395
        ],
        [
            1695024538000,
            3396
        ],
        [
            1695025756000,
            3397
        ],
        [
            1695026977000,
            3401
        ],
        [
            1695028199000,
            3404
        ],
        [
            1695029421000,
            3405
        ],
        [
            1695030643000,
            3406
        ],
        [
            1695031865000,
            3407
        ],
        [
            1695033080000,
            3409
        ],
        [
            1695034327000,
            3408
        ],
        [
            1695035545000,
            3411
        ],
        [
            1695036767000,
            3412
        ],
        [
            1695038061000,
            3416
        ],
        [
            1695039390000,
            3416
        ],
        [
            1695040608000,
            3420
        ],
        [
            1695041866000,
            3420
        ],
        [
            1695043088000,
            3422
        ],
        [
            1695044310000,
            3425
        ],
        [
            1695045528000,
            3423
        ],
        [
            1695046750000,
            3425
        ],
        [
            1695048008000,
            3423
        ],
        [
            1695049230000,
            3426
        ],
        [
            1695050444000,
            3430
        ],
        [
            1695051666000,
            3426
        ],
        [
            1695052924000,
            3426
        ],
        [
            1695054142000,
            3426
        ],
        [
            1695055364000,
            3427
        ],
        [
            1695056654000,
            3427
        ],
        [
            1695057876000,
            3425
        ],
        [
            1695059098000,
            3424
        ],
        [
            1695060353000,
            3419
        ],
        [
            1695061610000,
            3421
        ],
        [
            1695062828000,
            3420
        ],
        [
            1695064086000,
            3413
        ],
        [
            1695065380000,
            3416
        ],
        [
            1695066598000,
            3413
        ],
        [
            1695067820000,
            3413
        ],
        [
            1695069038000,
            3412
        ],
        [
            1695070260000,
            3410
        ],
        [
            1695071482000,
            3409
        ],
        [
            1695072700000,
            3408
        ],
        [
            1695073922000,
            3408
        ],
        [
            1695075141000,
            3408
        ],
        [
            1695076363000,
            3407
        ],
        [
            1695077621000,
            3406
        ],
        [
            1695078950000,
            3405
        ],
        [
            1695080168000,
            3401
        ],
        [
            1695081541000,
            3401
        ],
        [
            1695082759000,
            3402
        ],
        [
            1695083981000,
            3402
        ],
        [
            1695085199000,
            3399
        ],
        [
            1695086421000,
            3397
        ],
        [
            1695087652000,
            3397
        ],
        [
            1695088870000,
            3396
        ],
        [
            1695090092000,
            3396
        ],
        [
            1695091310000,
            3395
        ],
        [
            1695092532000,
            3394
        ],
        [
            1695093790000,
            3392
        ],
        [
            1695095008000,
            3393
        ],
        [
            1695096230000,
            3393
        ],
        [
            1695097451000,
            3392
        ],
        [
            1695098673000,
            3392
        ],
        [
            1695099895000,
            3390
        ],
        [
            1695101153000,
            3390
        ],
        [
            1695102443000,
            3390
        ],
        [
            1695103661000,
            3387
        ],
        [
            1695104918000,
            3387
        ],
        [
            1695106184000,
            3387
        ],
        [
            1695107406000,
            3388
        ],
        [
            1695108735000,
            3390
        ],
        [
            1695109957000,
            3390
        ],
        [
            1695111175000,
            3390
        ],
        [
            1695112397000,
            3389
        ],
        [
            1695113619000,
            3389
        ],
        [
            1695114841000,
            3394
        ],
        [
            1695116099000,
            3396
        ],
        [
            1695117320000,
            3398
        ],
        [
            1695118614000,
            3400
        ],
        [
            1695119836000,
            3400
        ],
        [
            1695121058000,
            3404
        ],
        [
            1695122316000,
            3406
        ],
        [
            1695123538000,
            3406
        ],
        [
            1695124760000,
            3406
        ],
        [
            1695125978000,
            3407
        ],
        [
            1695127200000,
            3408
        ],
        [
            1695128422000,
            3410
        ],
        [
            1695129644000,
            3411
        ],
        [
            1695130866000,
            3414
        ],
        [
            1695132084000,
            3415
        ],
        [
            1695133302000,
            3421
        ],
        [
            1695134524000,
            3428
        ],
        [
            1695135781000,
            3435
        ],
        [
            1695137003000,
            3440
        ],
        [
            1695138261000,
            3443
        ],
        [
            1695139483000,
            3447
        ],
        [
            1695140741000,
            3455
        ],
        [
            1695141963000,
            3458
        ],
        [
            1695143293000,
            3461
        ],
        [
            1695144550000,
            3454
        ],
        [
            1695145769000,
            3450
        ],
        [
            1695146991000,
            3446
        ],
        [
            1695148284000,
            3441
        ],
        [
            1695149506000,
            3441
        ],
        [
            1695150764000,
            3440
        ],
        [
            1695152022000,
            3439
        ],
        [
            1695153244000,
            3438
        ],
        [
            1695154466000,
            3437
        ],
        [
            1695155688000,
            3436
        ],
        [
            1695156910000,
            3433
        ],
        [
            1695158122000,
            3432
        ],
        [
            1695159344000,
            3430
        ],
        [
            1695160565000,
            3430
        ],
        [
            1695161780000,
            3424
        ],
        [
            1695163002000,
            3427
        ],
        [
            1695164220000,
            3424
        ],
        [
            1695165438000,
            3424
        ],
        [
            1695166660000,
            3422
        ],
        [
            1695167875000,
            3421
        ],
        [
            1695169133000,
            3420
        ],
        [
            1695170387000,
            3417
        ],
        [
            1695171609000,
            3419
        ],
        [
            1695172866000,
            3417
        ],
        [
            1695174088000,
            3416
        ],
        [
            1695175346000,
            3415
        ],
        [
            1695176604000,
            3414
        ],
        [
            1695177822000,
            3412
        ],
        [
            1695179044000,
            3411
        ],
        [
            1695180266000,
            3410
        ],
        [
            1695181488000,
            3410
        ],
        [
            1695182739000,
            3410
        ],
        [
            1695183961000,
            3408
        ],
        [
            1695185180000,
            3408
        ],
        [
            1695186426000,
            3408
        ],
        [
            1695187684000,
            3408
        ],
        [
            1695188906000,
            3408
        ],
        [
            1695190128000,
            3408
        ],
        [
            1695191350000,
            3407
        ],
        [
            1695192572000,
            3408
        ],
        [
            1695193790000,
            3407
        ],
        [
            1695195012000,
            3406
        ],
        [
            1695196234000,
            3408
        ],
        [
            1695197492000,
            3408
        ],
        [
            1695198714000,
            3409
        ],
        [
            1695200007000,
            3412
        ],
        [
            1695201229000,
            3414
        ],
        [
            1695202451000,
            3415
        ],
        [
            1695203669000,
            3417
        ],
        [
            1695204891000,
            3419
        ],
        [
            1695206149000,
            3421
        ],
        [
            1695207364000,
            3422
        ],
        [
            1695208575000,
            3423
        ],
        [
            1695209797000,
            3425
        ],
        [
            1695211018000,
            3425
        ],
        [
            1695212240000,
            3424
        ],
        [
            1695213498000,
            3425
        ],
        [
            1695214756000,
            3428
        ],
        [
            1695215977000,
            3429
        ],
        [
            1695217199000,
            3430
        ],
        [
            1695218421000,
            3432
        ],
        [
            1695219639000,
            3437
        ],
        [
            1695220861000,
            3440
        ],
        [
            1695222080000,
            3440
        ],
        [
            1695223298000,
            3447
        ],
        [
            1695224522000,
            3456
        ],
        [
            1695225823000,
            3464
        ],
        [
            1695227040000,
            3470
        ],
        [
            1695228262000,
            3473
        ],
        [
            1695229484000,
            3475
        ],
        [
            1695230706000,
            3476
        ],
        [
            1695231928000,
            3465
        ],
        [
            1695233178000,
            3463
        ],
        [
            1695234386000,
            3461
        ],
        [
            1695235604000,
            3458
        ],
        [
            1695236828000,
            3456
        ],
        [
            1695238088000,
            3456
        ],
        [
            1695239333000,
            3453
        ],
        [
            1695240557000,
            3452
        ],
        [
            1695241801000,
            3451
        ],
        [
            1695243098000,
            3448
        ],
        [
            1695244322000,
            3447
        ],
        [
            1695245546000,
            3443
        ],
        [
            1695246771000,
            3444
        ],
        [
            1695247980000,
            3441
        ],
        [
            1695249201000,
            3441
        ],
        [
            1695250409000,
            3440
        ],
        [
            1695251706000,
            3438
        ],
        [
            1695252930000,
            3438
        ],
        [
            1695254139000,
            3439
        ],
        [
            1695255347000,
            3436
        ],
        [
            1695256556000,
            3435
        ],
        [
            1695257768000,
            3434
        ],
        [
            1695259048000,
            3429
        ],
        [
            1695260257000,
            3431
        ],
        [
            1695261501000,
            3431
        ],
        [
            1695262710000,
            3428
        ],
        [
            1695263927000,
            3428
        ],
        [
            1695265152000,
            3426
        ],
        [
            1695266361000,
            3426
        ],
        [
            1695267569000,
            3425
        ],
        [
            1695268794000,
            3423
        ],
        [
            1695270002000,
            3423
        ],
        [
            1695271259000,
            3421
        ],
        [
            1695272468000,
            3423
        ],
        [
            1695273676000,
            3420
        ],
        [
            1695274885000,
            3418
        ],
        [
            1695276130000,
            3419
        ],
        [
            1695277354000,
            3418
        ],
        [
            1695278563000,
            3419
        ],
        [
            1695279771000,
            3420
        ],
        [
            1695281016000,
            3419
        ],
        [
            1695282228000,
            3417
        ],
        [
            1695283472000,
            3418
        ],
        [
            1695284681000,
            3421
        ],
        [
            1695285889000,
            3419
        ],
        [
            1695287098000,
            3421
        ],
        [
            1695288307000,
            3419
        ],
        [
            1695289515000,
            3420
        ],
        [
            1695290724000,
            3420
        ],
        [
            1695291933000,
            3421
        ],
        [
            1695293153000,
            3421
        ],
        [
            1695294378000,
            3422
        ],
        [
            1695295587000,
            3424
        ],
        [
            1695296795000,
            3424
        ],
        [
            1695298012000,
            3427
        ],
        [
            1695299221000,
            3428
        ],
        [
            1695300442000,
            3430
        ],
        [
            1695301650000,
            3432
        ],
        [
            1695302871000,
            3430
        ],
        [
            1695304080000,
            3433
        ],
        [
            1695305305000,
            3435
        ],
        [
            1695306525000,
            3436
        ],
        [
            1695307733000,
            3437
        ],
        [
            1695308958000,
            3436
        ],
        [
            1695310246000,
            3435
        ],
        [
            1695311491000,
            3434
        ],
        [
            1695312715000,
            3435
        ],
        [
            1695313960000,
            3435
        ],
        [
            1695315169000,
            3434
        ],
        [
            1695316413000,
            3432
        ],
        [
            1695317622000,
            3428
        ],
        [
            1695318830000,
            3430
        ],
        [
            1695320051000,
            3428
        ],
        [
            1695321295000,
            3425
        ],
        [
            1695322504000,
            3425
        ],
        [
            1695323728000,
            3423
        ],
        [
            1695324953000,
            3423
        ],
        [
            1695326198000,
            3424
        ],
        [
            1695327423000,
            3422
        ],
        [
            1695328667000,
            3421
        ],
        [
            1695329888000,
            3421
        ],
        [
            1695331109000,
            3419
        ],
        [
            1700780400000,
            3418
        ],
        [
            1700780400000,
            3416
        ]

    ],
    "id_param": 3,
    "unidades": "%",
    "nombre": "Bateria Remote",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 3520,
        "minimo": 3086,
        "media": 3373.700218818381,
        "total": 3083562
    }

}


const dataAnemometro = {
    "data": [
        [
            1676112899000,
            0.6
        ],
        [
            1676116505000,
            1.3
        ],
        [
            1676120111000,
            1
        ],
        [
            1676123717000,
            3.2
        ],
        [
            1676127323000,
            6.2
        ],
        [
            1676130930000,
            3.5
        ],
        [
            1676134536000,
            0.9
        ],
        [
            1676138142000,
            2.5
        ],
        [
            1676141748000,
            4.8
        ],
        [
            1676145354000,
            5
        ],
        [
            1676148960000,
            3.2

        ],
        [
            1676152566000,
            5.6
        ],
        [
            1676156172000,
            3
        ],
        [
            1676159778000,
            2.1
        ],
        [
            1676163384000,
            0.5
        ],
        [
            1676166990000,
            2.3
        ],
        [
            1676170596000,
            4.9
        ],
        [
            1676174202000,
            5.7
        ],
        [
            1676177808000,
            7.2
        ],
        [
            1676181414000,
            10
        ],
        [
            1676185020000,
            7.7
        ],
        [
            1676188626000,
            5.1
        ],
        [
            1676192232000,
            7.3
        ],
        [
            1676195838000,
            5.2
        ],
        [
            1676199444000,
            5.8
        ],
        [
            1676203050000,
            3
        ],
        [
            1676206657000,
            5
        ],
        [
            1676210263000,
            5.4
        ],
        [
            1676213869000,
            5.2
        ],
        [
            1676217475000,
            3
        ],
        [
            1676221081000,
            5
        ],
        [
            1676224687000,
            2.8
        ],
        [
            1676228293000,
            4
        ],
        [
            1676231899000,
            4.5
        ],
        [
            1676235505000,
            7.3
        ],
        [
            1676239111000,
            7.3
        ],
        [
            1676242716100,
            8.9
        ],
        [
            1676246367000,
            10.4
        ],
        [
            1676249973000,
            8.8
        ],
        [
            1676253579000,
            11.6
        ],
        [
            16762571850000,
            10.3
        ],
        [
            1676260791000,
            10.5
        ],
        [
            1676264397000,
            10.5
        ],
        [
            1676268003000,
            22.2
        ],
        [
            1676271609000,
            8.3
        ],
        [
            1676275215000,
            8.8
        ],
        [
            1676278821000,
            6.4
        ],
        [
            1676282427000,
            6.3
        ],
        [
            1676286033000,
            8.9
        ],
        [
            1676289639000,
            6.4
        ],

    ],
    "id_param": 8,
    "unidades": "km/h",
    "nombre": "Anemometro",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 22.2,
        "minimo": 1,
        "media": 10.2,
        "total": 3083562
    }
}

const dataConductividad = {
    "data": [
        [
            1676112899000,
            83.77
        ],
        [
            1676116505000,
            83.68
        ],
        [
            1676120111000,
            83.38
        ],
        [
            1676123717000,
            83.19
        ],
        [
            1676127323000,
            83.28
        ],
        [
            1676130930000,
            83.19
        ],
        [
            1676134536000,
            81.92
        ],
        [
            1676138142000,
            81.62
        ],
        [
            1676141748000,
            82.01
        ],
        [
            1676145354000,
            82.11
        ],
        [
            1676148960000,
            82.21
        ],
        [
            1676152566000,
            82.11
        ],
        [
            1676156172000,
            82.11
        ],
        [
            1676159778000,
            82.31
        ],
        [
            1676163384000,
            82.31
        ],
        [
            1676166990000,
            82.5
        ],
        [
            1676170596000,
            82.5
        ],
        [
            1676174202000,
            82.7
        ],
        [
            1676177808000,
            82.7
        ],
        [
            1676181414000,
            82.99
        ],
        [
            1676185020000,
            83.28
        ],
        [
            1676188626000,
            83.38
        ],
        [
            1676192232000,
            82.89
        ],
        [
            1676195838000,
            82.8
        ],
        [
            1676199444000,
            82.7
        ],
        [
            1676203050000,
            82.5
        ],
        [
            1676206657000,
            82.01
        ],
        [
            1676210263000,
            80.74
        ],
        [
            1676213869000,
            80.35
        ],
        [
            1676217475000,
            80.45
        ],
        [
            1676221081000,
            80.65
        ],
        [
            1676224687000,
            81.04
        ],
        [
            1676228293000,
            81.43
        ],
        [
            1676231899000,
            81.72
        ],
        [
            1676235505000,
            81.92
        ],
        [
            1676239111000,
            82.11
        ],
        [
            1676242716100,
            82.4
        ],
        [
            1676246367000,
            82.31
        ],
        [
            1676249973000,
            82.31
        ],
        [
            1676253579000,
            82.11
        ],
        [
            16762571850000,
            82.21
        ],
        [
            1676260791000,
            82.4
        ],
        [
            1676264397000,
            82.5
        ],
        [
            1676268003000,
            82.7
        ],
        [
            1676271609000,
            82.6
        ],
        [
            1676275215000,
            82.4
        ],
        [
            1676278821000,
            82.31
        ],
        [
            1676282427000,
            82.01
        ],
        [
            1676286033000,
            81.43
        ],
        [
            1676289639000,
            80.25
        ],

    ],
    "id_param": 7,
    "unidades": "µS/cm",
    "nombre": "Conductividad",
    "resumen": {
        "tipo_dato": "max_min",
        "maximo": 83.77,
        "minimo": 80.35,
        "media": 82.06,
        "total": 3083562
    }
}

module.exports = { getData }