<template>
  <v-container class="d-flex justify-center align-center" style="height: 80vh">
    <v-card class="teal darken-4 py-8" width="600">
      <v-container class="d-flex flex-column align-center justify-center">
        <figure>
          <v-img
            min-width="150px"
            max-width="200px"
            src="../assets/logoWhite.png"
          ></v-img>
        </figure>
      </v-container>
      <v-form
        class="d-flex justify-center align-center flex-column"
        @submit="login"
      >
        <v-text-field
          v-model="email"
          label="Email"
          dark
          :style="inputWidth"
          :rules="[error]"
          required
        >
        </v-text-field>
        <v-text-field
          color="white"
          type="password"
          v-model="password"
          label="Password"
          :style="inputWidth"
          dark
          :rules="[error]"
          required
        >
        </v-text-field>
        <v-btn type="submit" dark class="mt-4">Iniciar Sesión</v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import bg_image from "@/assets/bg_user.png";
export default {
  data() {
    const dataFincas = [
      {
        id: 10,
        nombre: "Finca Uno",
        dispositivos: "B827EB7BE480, B827EB7BE482",
      },
      {
        id: 11,
        nombre: "Finca Dos",
        dispositivos: "B827EB7BE481, B827EB7BE483",
      },
    ];
    return {
      email: "",
      password: "",
      dispositivos: [],
      error: true,
      dataFincas,
    };
  },
  methods: {
    login(e) {
      e.preventDefault()
      axios.post(`${process.env.VUE_APP_API_URL}/login`, {
        email: this.email,
        password: this.password,
      })
      .then((response) => {
        console.log("response",response.data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("id", 1)
        localStorage.setItem("email", "demo@ecoes.eco")
        localStorage.setItem("nombre", "ECO");
        localStorage.setItem("apellido", "Engineering");
        localStorage.setItem("URL_avatar", bg_image);
        localStorage.setItem("fincas", JSON.stringify(this.dataFincas));
        localStorage.setItem("usuario", btoa(1));
        localStorage.setItem(
          "dispositivos",
          this.dataFincas[0].dispositivos.replace(/\s/g, "")
        );

      })
      .catch(() => {
        this.error = false;
      })
      .finally(() => {
        this.$router.push("/Panel%20de%20control");
      });

    },
    
  },
  computed: {
    inputWidth() {
      return this.$vuetify.breakpoint.mobile ? "width:80%" : "width:70%";
    },
  },
};
</script>

<style>
</style>