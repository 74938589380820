function getProgramacionesSecuenciales() {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        return data;
    } else {
        return dataDos
    }
}

function addProgramacionSecuencial(programacion, secuencia, parametros) {
    const id = (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482')
        ? (data.length > 0 ? data[data.length - 1].id + 1 : '0')
        : (dataDos.length > 0 ? dataDos[dataDos.length - 1].id + 1 : '0');

    const nuevaProgramacion = {
        ...programacion,
        id,
        secuencia,
        parametros,
        is_repetir: programacion.is_repetir === "si",
        hora_inicio: programacion.hora_inicio + ':00',
        is_activa: 0,
        is_enabled: false
    };

    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        data.push(nuevaProgramacion);
    } else {
        dataDos.push(nuevaProgramacion);
    }
}

function updateProgramacionSecuencial(programacion, secuencia, parametros) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        data.map((pr) => {
            if (pr.id == programacion.id) {
                pr.nombre = programacion.nombre;
                pr.fecha = programacion.fecha;
                pr.hora_inicio = programacion.hora_inicio;
                pr.is_activa = 0;
                pr.is_enabled = false;
                pr.repetir_cada = programacion.repetir_cada;
                pr.is_repetir = programacion.is_repetir === 'si'; // Guardar como booleano
                pr.parametros = parametros;
                pr.secuencia = secuencia;
            }
        });
    } else {
        dataDos.map((pr) => {
            if (pr.id == programacion.id) {
                pr.nombre = programacion.nombre;
                pr.fecha = programacion.fecha;
                pr.hora_inicio = programacion.hora_inicio;
                pr.is_activa = 0;
                pr.is_enabled = false;
                pr.repetir_cada = programacion.repetir_cada;
                pr.is_repetir = programacion.is_repetir === 'si'; // Guardar como booleano
                pr.parametros = parametros;
                pr.secuencia = secuencia;
            }
        });
    }
}

function deleteProgramacionSecuencial({ id }) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        data.map((pr, index) => {
            if (pr.id == id) {
                data.splice(index, 1);
            }
        })
    } else {
        dataDos.map((pr, index) => {
            if (pr.id == id) {
                dataDos.splice(index, 1);
            }
        })
    }
}

function updateIsEnabledProgramacion(programacion) {
    const dispositivos = localStorage.getItem('dispositivos');
    if (dispositivos === 'B827EB7BE480,B827EB7BE482') {
        data.forEach((pr) => {
            if (pr.id === programacion.id) {
                pr.is_activa = programacion.is_activa;
                pr.is_enabled = programacion.is_enabled;
            }
        });
    } else {
        dataDos.forEach((pr) => {
            if (pr.id === programacion.id) {
                pr.is_activa = programacion.is_activa;
                pr.is_enabled = programacion.is_enabled;
            }
        });
    }
}

const dataDos = []

const data = [
    {
        "id": 6,
        "nombre": "Programacion 1",
        "fecha": "2024-03-12",
        "hora_inicio": "16:00:00",
        "is_activa": 1,
        "is_enabled": true,
        "repetir_cada": 1,
        "secuencia": [
            {
                "id": 2,
                "id_secuencia": 6,
                "id_configuracion": 25,
                "nombre": "Grupo 2",
                "hora_inicio": "16:00",
                "id_programacion": 37,
                "orden": 2,
                "minutos": 12
            },
            {
                "id": 3,
                "id_secuencia": 6,
                "id_configuracion": 32,
                "nombre": "Grupo 3",
                "hora_inicio": "16:12",
                "id_programacion": 37,
                "orden": 3,
                "minutos": 60
            }
        ],
        "parametros": [
            {
                "id": 14,
                "icon": "mdi-electric-switch-closed",
                "param": "manual_auto",
                "nombre_parametro": "Manual/Auto",
                "tipo_valor": "off,on",
                "opciones_nombres": "Manual,Auto",
                "unidades": null,
                "conversion": "1",
                "valor_actual": "3",
                "is_write": "1",
                "opcion_valores": "3,5",
                "orden": 1,
                "retardo": 120,
                "retardo_apagado": 80
            },
        ],
        "is_repetir": true
    },
    {
        "id": 9,
        "nombre": "Programacion 2",
        "fecha": "2024-03-15",
        "hora_inicio": "15:55:00",
        "repetir_cada": 1,
        "is_activa": 1,
        "is_enabled": true,
        "secuencia": [
            {
                "id": 4,
                "id_secuencia": 9,
                "id_configuracion": 32,
                "nombre": "Grupo 3",
                "hora_inicio": "15:55",
                "id_programacion": 36,
                "orden": 1,
                "minutos": 20
            }
        ],
        "parametros": [
            {
                "id": 17,
                "icon": "mdi-power",
                "param": "estado_agitador",
                "nombre_parametro": "Estado agitador",
                "tipo_valor": "off,on",
                "opciones_nombres": "Off,On",
                "unidades": "",
                "conversion": "1",
                "valor_actual": "4",
                "is_write": "1",
                "opcion_valores": "0,4",
                "orden": 1,
                "retardo": 60,
                "retardo_apagado": 120
            },
        ],
        "is_repetir": true
    }
]

module.exports = { getProgramacionesSecuenciales, addProgramacionSecuencial, updateProgramacionSecuencial, deleteProgramacionSecuencial, updateIsEnabledProgramacion }