<template>
  <div>
    <!-- <ProgramacionesComponent
      :grupos="grupos"
      :programaciones="programaciones"
      :loader="loader"
    /> -->
    <ProgramacionesSecuenciales :grupos="grupos" @reset-groups="resetGroups" :variadores="variadores" :electrovalvulas="electrovalvulas"/>
    <!-- <ProgramacionParametrica :grupos="grupos"/> -->
  </div>
</template>

<script>
// import ProgramacionesComponent from "@/components/ProgramacionesComponent.vue";
import { getGrupos } from "@/data/gruposData";
import { getProgramaciones } from "@/data/programacionesData";
import { getVariadores } from "@/data/variadoresData";
import { getValvulas } from "@/data/valvulasData";
import ProgramacionesSecuenciales from '@/components/ProgramacionesSecuenciales.vue';
// import ProgramacionParametrica from '@/components/ProgramacionParametrica.vue';
export default {
  components: { ProgramacionesSecuenciales },
  data() {
    return {
      variadores: getVariadores(),
      programaciones: getProgramaciones(),
      grupos: getGrupos(),
      electrovalvulas: getValvulas(),
      loader: false,
    };
  },

  methods: {
    resetGroups() {
      this.grupos = this.grupos.map(group => ({
        ...group,
        disabled: false
      }));
    },
  },
};
</script>

<style>
</style>