function updateSensor(sensor) {
  if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
    sensoresData.map((se) => {
      if (se.id === sensor.id) {
        se.nombre = sensor.nombre;
        se.longitud = sensor.longitud
        se.latitud = sensor.latitud
      }
    })
  } else {
    sensoresDos.map((se) => {
      if (se.id === sensor.id) {
        se.nombre = sensor.nombre;
        se.longitud = sensor.longitud
        se.latitud = sensor.latitud
      }
    })
  }
  return 1
}

function getSensores() {
  if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
    return sensoresData
  } else {
    return sensoresDos
  }
}

const sensoresDos = [
  {
    "id": "91",
    "nombre": "pH",
    "value": "353",
    "latitud": "39.8630",
    "longitud": "-1.35587",
    "id_tipo_sensor": "4",
    "nombre_tipo_sensor": "pH",
    "conversion": "round(0.0369*$val-6.3887, 2)",
    "unidades": "pH",
    "color_front_end": "#36C2A9",
    "id_remote": "011B06",
    "nombre_remote": "Remote Lite Almendros",
    "battery_level": 3965,
    "battery_timestamp": "2023-10-02 13:55:40",
    "real_value": 6.64,
    "historico": {
      "fecha_registro": [
        "2023-01-01 10:15:15",
        "2023-01-01 11:15:15",
        "2023-01-01 12:15:15",
        "2023-01-01 13:15:15",
        "2023-01-01 14:15:15",
        "2023-01-01 15:15:15",
        "2023-01-01 16:15:15",
        "2023-01-01 17:15:15",
        "2023-01-01 18:15:15",
        "2023-01-01 19:15:15",
        "2023-01-01 20:15:15",
        "2023-01-01 21:15:15",
        "2023-01-01 22:15:15",
        "2023-01-01 23:15:15",
        "2023-01-02 00:15:15",
        "2023-01-02 01:15:15",
        "2023-01-02 02:15:15",
        "2023-01-02 03:15:15",
        "2023-01-02 04:15:15",
        "2023-01-02 05:15:15",
        "2023-01-02 06:15:15",
        "2023-01-02 07:15:15",
        "2023-01-02 08:15:15",
        "2023-01-02 09:15:15",
        "2023-01-02 10:15:15",
        "2023-01-02 11:15:15",
        "2023-01-02 12:15:15",
        "2023-01-02 13:15:15",
        "2023-01-02 14:15:15",
        "2023-01-02 15:15:15",
        "2023-01-02 16:15:15",
        "2023-01-02 17:15:15",
        "2023-01-02 18:15:15",
        "2023-01-02 19:15:15",
        "2023-01-02 20:15:15",
        "2023-01-02 21:15:15",
        "2023-01-02 22:15:15",
        "2023-01-02 23:15:15",
        "2023-01-03 00:15:15",
        "2023-01-03 01:15:15",
        "2023-01-03 02:15:15",
        "2023-01-03 03:15:15",
        "2023-01-03 04:15:15",
        "2023-01-03 05:15:15",
        "2023-01-03 06:15:15",
        "2023-01-03 07:15:15",
        "2023-01-03 08:15:15",
        "2023-01-03 09:15:15",
        "2023-01-03 10:15:15",
        "2023-01-03 11:15:15"

      ],
      "values": [
        5.71,
        5.71,
        5.79,
        5.79,
        5.83,
        5.79,
        5.79,
        5.68,
        5.71,
        5.68,
        5.68,
        5.68,
        5.68,
        5.71,
        5.71,
        5.71,
        5.6,
        5.6,
        5.6,
        5.64,
        5.64,
        5.6,
        5.64,
        5.75,
        5.79,
        5.9,
        5.83,
        5.75,
        5.79,
        5.71,
        5.71,
        5.75,
        5.71,
        5.64,
        5.6,
        5.64,
        5.68,
        5.64,
        5.6,
        5.6,
        5.64,
        5.71,
        5.64,
        5.75,
        5.71,
        5.75,
        5.83,
        5.86,
        5.97,
        5.94
      ]
    }
  },
  {
    "id": "90",
    "nombre": "Temperatura",
    "value": "456",
    "latitud": "39.8650",
    "longitud": "-1.3565",
    "id_tipo_sensor": "1",
    "nombre_tipo_sensor": "Temperatura",
    "conversion": "round($val*0.025, 1)",
    "unidades": "ºC",
    "color_front_end": "#FBC02D",
    "id_remote": "011B06",
    "nombre_remote": "Remote Lite Almendros",
    "battery_level": 3965,
    "battery_timestamp": "2023-10-02 13:55:40",
    "real_value": 11.4,
    "historico": {
      "fecha_registro": [
        "2023-01-01 10:15:15",
        "2023-01-01 11:15:15",
        "2023-01-01 12:15:15",
        "2023-01-01 13:15:15",
        "2023-01-01 14:15:15",
        "2023-01-01 15:15:15",
        "2023-01-01 16:15:15",
        "2023-01-01 17:15:15",
        "2023-01-01 18:15:15",
        "2023-01-01 19:15:15",
        "2023-01-01 20:15:15",
        "2023-01-01 21:15:15",
        "2023-01-01 22:15:15",
        "2023-01-01 23:15:15",
        "2023-01-02 00:15:15",
        "2023-01-02 01:15:15",
        "2023-01-02 02:15:15",
        "2023-01-02 03:15:15",
        "2023-01-02 04:15:15",
        "2023-01-02 05:15:15",
        "2023-01-02 06:15:15",
        "2023-01-02 07:15:15",
        "2023-01-02 08:15:15",
        "2023-01-02 09:15:15",
        "2023-01-02 10:15:15",
        "2023-01-02 11:15:15",
        "2023-01-02 12:15:15",
        "2023-01-02 13:15:15",
        "2023-01-02 14:15:15",
        "2023-01-02 15:15:15",
        "2023-01-02 16:15:15",
        "2023-01-02 17:15:15",
        "2023-01-02 18:15:15",
        "2023-01-02 19:15:15",
        "2023-01-02 20:15:15",
        "2023-01-02 21:15:15",
        "2023-01-02 22:15:15",
        "2023-01-02 23:15:15",
        "2023-01-03 00:15:15",
        "2023-01-03 01:15:15",
        "2023-01-03 02:15:15",
        "2023-01-03 03:15:15",
        "2023-01-03 04:15:15",
        "2023-01-03 05:15:15",
        "2023-01-03 06:15:15",
        "2023-01-03 07:15:15",
        "2023-01-03 08:15:15",
        "2023-01-03 09:15:15",
        "2023-01-03 10:15:15",
        "2023-01-03 11:15:15"
      ],
      "values": [
        18.7,
        18.8,
        18.8,
        19,
        19.2,
        19.2,
        19.9,
        19.4,
        19.6,
        20.2,
        19.9,
        20,
        19.5,
        19.7,
        20,
        20.6,
        21.2,
        20.7,
        20.1,
        19.4,
        18.8,
        19.3,
        19,
        19.3,
        19.3,
        19.1,
        19.5,
        19.8,
        20.2,
        20.9,
        20.5,
        20.4,
        19.7,
        19.5,
        19.8,
        20.3,
        20.3,
        20.1,
        20.7,
        21.1,
        21.2,
        21.5,
        22,
        22.2,
        22.1,
        21.7,
        21.3,
        21,
        20.5,
        20.3
      ]
    }
  }
]

const sensoresData = [
  {
    "id": "8",
    "nombre": "Anemómetro",
    "value": "742",
    "latitud": "39.8689",
    "longitud": "-1.35435",
    "id_tipo_sensor": "3",
    "nombre_tipo_sensor": "Velocidad del aire",
    "conversion": "round($val/10, 2)",
    "unidades": "km/h",
    "color_front_end": "#7E57C2",
    "id_remote": "abcd06",
    "nombre_remote": "Remote Lite Maiz",
    "battery_level":4020,
    "battery_timestamp": "2024-01-08 00:01:40",
    "real_value": 74.2,
    "historico": {
      "fecha_registro": [
        "2023-01-01 10:15:15",
        "2023-01-01 11:15:15",
        "2023-01-01 12:15:15",
        "2023-01-01 13:15:15",
        "2023-01-01 14:15:15",
        "2023-01-01 15:15:15",
        "2023-01-01 16:15:15",
        "2023-01-01 17:15:15",
        "2023-01-01 18:15:15",
        "2023-01-01 19:15:15",
        "2023-01-01 20:15:15",
        "2023-01-01 21:15:15",
        "2023-01-01 22:15:15",
        "2023-01-01 23:15:15",
        "2023-01-02 00:15:15",
        "2023-01-02 01:15:15",
        "2023-01-02 02:15:15",
        "2023-01-02 03:15:15",
        "2023-01-02 04:15:15",
        "2023-01-02 05:15:15",
        "2023-01-02 06:15:15",
        "2023-01-02 07:15:15",
        "2023-01-02 08:15:15",
        "2023-01-02 09:15:15",
        "2023-01-02 10:15:15",
        "2023-01-02 11:15:15",
        "2023-01-02 12:15:15",
        "2023-01-02 13:15:15",
        "2023-01-02 14:15:15",
        "2023-01-02 15:15:15",
        "2023-01-02 16:15:15",
        "2023-01-02 17:15:15",
        "2023-01-02 18:15:15",
        "2023-01-02 19:15:15",
        "2023-01-02 20:15:15",
        "2023-01-02 21:15:15",
        "2023-01-02 22:15:15",
        "2023-01-02 23:15:15",
        "2023-01-03 00:15:15",
        "2023-01-03 01:15:15",
        "2023-01-03 02:15:15",
        "2023-01-03 03:15:15",
        "2023-01-03 04:15:15",
        "2023-01-03 05:15:15",
        "2023-01-03 06:15:15",
        "2023-01-03 07:15:15",
        "2023-01-03 08:15:15",
        "2023-01-03 09:15:15",
        "2023-01-03 10:15:15",
        "2023-01-03 11:15:15"
      ],
      "values": [
        0.6,
        1.3,
        1,
        3.2,
        6.2,
        3.5,
        0.9,
        2.5,
        4.8,
        5,
        3.2,
        5.6,
        3,
        2.1,
        0.5,
        2.3,
        4.9,
        5.7,
        7.2,
        10,
        7.7,
        5.1,
        7.3,
        5.2,
        5.8,
        3,
        5,
        5.4,
        5.2,
        3,
        5,
        2.8,
        4,
        4.5,
        7.3,
        7.3,
        8.9,
        10.4,
        8.8,
        11.6,
        10.3,
        10.5,
        10.5,
        22.2,
        8.3,
        8.8,
        6.4,
        6.3,
        8.9,
        6.4
      ]
    }
  },
  {
    "id": "7",
    "nombre": "Conductividad",
    "value": "821",
    "latitud": "39.8692",
    "longitud": "-1.35383",
    "id_tipo_sensor": "5",
    "nombre_tipo_sensor": "Conductividad",
    "conversion": "round($val*100/1023, 2)",
    "unidades": "µS/cm",
    "color_front_end": "#FFB300",
    "id_remote": "abcd06",
    "nombre_remote": "Remote Lite Maiz",
    "battery_level":4020,
    "battery_timestamp": "2024-01-08 00:01:40",
    "real_value": 80.25,
    "historico": {
      "fecha_registro": [
        "2023-02-11 11:54:59",
        "2023-02-11 12:55:05",
        "2023-02-11 13:55:11",
        "2023-02-11 14:55:17",
        "2023-02-11 15:55:23",
        "2023-02-11 16:55:30",
        "2023-02-11 17:55:36",
        "2023-02-11 18:55:42",
        "2023-02-11 19:55:48",
        "2023-02-11 20:55:54",
        "2023-02-11 21:56:00",
        "2023-02-11 22:56:06",
        "2023-02-11 23:56:12",
        "2023-02-12 00:56:18",
        "2023-02-12 01:56:24",
        "2023-02-12 02:56:30",
        "2023-02-12 03:56:36",
        "2023-02-12 04:56:42",
        "2023-02-12 05:56:48",
        "2023-02-12 06:56:54",
        "2023-02-12 07:57:00",
        "2023-02-12 08:57:06",
        "2023-02-12 09:57:12",
        "2023-02-12 10:57:18",
        "2023-02-12 11:57:24",
        "2023-02-12 12:57:30",
        "2023-02-12 13:57:37",
        "2023-02-12 14:57:43",
        "2023-02-12 15:57:49",
        "2023-02-12 16:57:55",
        "2023-02-12 17:58:01",
        "2023-02-12 18:58:07",
        "2023-02-12 19:58:13",
        "2023-02-12 20:58:19",
        "2023-02-12 21:58:25",
        "2023-02-12 22:58:31",
        "2023-02-12 23:59:21",
        "2023-02-13 00:59:27",
        "2023-02-13 01:59:33",
        "2023-02-13 02:59:39",
        "2023-02-13 03:59:45",
        "2023-02-13 04:59:51",
        "2023-02-13 05:59:57",
        "2023-02-13 07:00:03",
        "2023-02-13 08:00:09",
        "2023-02-13 09:00:15",
        "2023-02-13 10:00:21",
        "2023-02-13 11:00:27",
        "2023-02-13 12:00:33",
        "2023-02-13 13:00:39"
      ],
      "values": [
        83.77,
        83.68,
        83.38,
        83.28,
        83.19,
        83.19,
        81.92,
        81.62,
        82.01,
        82.11,
        82.21,
        82.11,
        82.11,
        82.31,
        82.31,
        82.5,
        82.5,
        82.7,
        82.7,
        82.99,
        83.28,
        83.38,
        82.89,
        82.8,
        82.7,
        82.5,
        82.01,
        80.74,
        80.35,
        80.45,
        80.65,
        81.04,
        81.43,
        81.72,
        81.92,
        82.11,
        82.4,
        82.31,
        82.31,
        82.11,
        82.21,
        82.4,
        82.5,
        82.7,
        82.6,
        82.4,
        82.31,
        82.01,
        81.43,
        80.25
      ]
    }
  },
  {
    "id": "1",
    "nombre": "Humedad",
    "value": "369",
    "latitud": "39.8667",
    "longitud": "-1.35420",
    "id_tipo_sensor": "2",
    "nombre_tipo_sensor": "Humedad",
    "conversion": "round($val/1023*100,2)",
    "unidades": "%",
    "color_front_end": "#9CCC65",
    "id_remote": "01dd06",
    "nombre_remote": "Remote Lite Olivo",
    "battery_level": 4012,
    "battery_timestamp": "2023-09-29 10:08:25",
    "real_value": 36.07,
    "historico": {
      "fecha_registro": [
        "2023-01-01 10:15:15",
        "2023-01-01 11:15:15",
        "2023-01-01 12:15:15",
        "2023-01-01 13:15:15",
        "2023-01-01 14:15:15",
        "2023-01-01 15:15:15",
        "2023-01-01 16:15:15",
        "2023-01-01 17:15:15",
        "2023-01-01 18:15:15",
        "2023-01-01 19:15:15",
        "2023-01-01 20:15:15",
        "2023-01-01 21:15:15",
        "2023-01-01 22:15:15",
        "2023-01-01 23:15:15",
        "2023-01-02 00:15:15",
        "2023-01-02 01:15:15",
        "2023-01-02 02:15:15",
        "2023-01-02 03:15:15",
        "2023-01-02 04:15:15",
        "2023-01-02 05:15:15",
        "2023-01-02 06:15:15",
        "2023-01-02 07:15:15",
        "2023-01-02 08:15:15",
        "2023-01-02 09:15:15",
        "2023-01-02 10:15:15",
        "2023-01-02 11:15:15",
        "2023-01-02 12:15:15",
        "2023-01-02 13:15:15",
        "2023-01-02 14:15:15",
        "2023-01-02 15:15:15",
        "2023-01-02 16:15:15",
        "2023-01-02 17:15:15",
        "2023-01-02 18:15:15",
        "2023-01-02 19:15:15",
        "2023-01-02 20:15:15",
        "2023-01-02 21:15:15",
        "2023-01-02 22:15:15",
        "2023-01-02 23:15:15",
        "2023-01-03 00:15:15",
        "2023-01-03 01:15:15",
        "2023-01-03 02:15:15",
        "2023-01-03 03:15:15",
        "2023-01-03 04:15:15",
        "2023-01-03 05:15:15",
        "2023-01-03 06:15:15",
        "2023-01-03 07:15:15",
        "2023-01-03 08:15:15",
        "2023-01-03 09:15:15",
        "2023-01-03 10:15:15",
        "2023-01-03 11:15:15"
      ],
      "values": [
        20.53,
        19.55,
        22.19,
        20.53,
        19.65,
        20.53,
        22.48,
        20.63,
        22.48,
        22.39,
        20.82,
        22.09,
        21.51,
        22.87,
        20.14,
        21.21,
        23.07,
        21.31,
        23.17,
        22.68,
        22.29,
        20.43,
        20.43,
        22.68,
        20.23,
        22.09,
        22.09,
        23.07,
        23.17,
        22.29,
        22.68,
        21.51,
        20.33,
        20.53,
        21.8,
        21.21,
        22.58,
        21.11,
        21.02,
        20.23,
        22.58,
        21.6,
        21.51,
        21.99,
        21.9,
        22.39,
        20.92,
        22.48,
        22.39,
        22.39
      ]
    }
  },
  {
    "id": "91",
    "nombre": "pH",
    "value": "353",
    "latitud": "39.8635",
    "longitud": "-1.35587",
    "id_tipo_sensor": "4",
    "nombre_tipo_sensor": "pH",
    "conversion": "round(0.0369*$val-6.3887, 2)",
    "unidades": "pH",
    "color_front_end": "#36C2A9",
    "id_remote": "011B06",
    "nombre_remote": "Remote Lite Almendros",
    "battery_level": 3965,
    "battery_timestamp": "2023-10-02 13:55:40",
    "real_value": 6.64,
    "historico": {
      "fecha_registro": [
        "2023-01-01 10:15:15",
        "2023-01-01 11:15:15",
        "2023-01-01 12:15:15",
        "2023-01-01 13:15:15",
        "2023-01-01 14:15:15",
        "2023-01-01 15:15:15",
        "2023-01-01 16:15:15",
        "2023-01-01 17:15:15",
        "2023-01-01 18:15:15",
        "2023-01-01 19:15:15",
        "2023-01-01 20:15:15",
        "2023-01-01 21:15:15",
        "2023-01-01 22:15:15",
        "2023-01-01 23:15:15",
        "2023-01-02 00:15:15",
        "2023-01-02 01:15:15",
        "2023-01-02 02:15:15",
        "2023-01-02 03:15:15",
        "2023-01-02 04:15:15",
        "2023-01-02 05:15:15",
        "2023-01-02 06:15:15",
        "2023-01-02 07:15:15",
        "2023-01-02 08:15:15",
        "2023-01-02 09:15:15",
        "2023-01-02 10:15:15",
        "2023-01-02 11:15:15",
        "2023-01-02 12:15:15",
        "2023-01-02 13:15:15",
        "2023-01-02 14:15:15",
        "2023-01-02 15:15:15",
        "2023-01-02 16:15:15",
        "2023-01-02 17:15:15",
        "2023-01-02 18:15:15",
        "2023-01-02 19:15:15",
        "2023-01-02 20:15:15",
        "2023-01-02 21:15:15",
        "2023-01-02 22:15:15",
        "2023-01-02 23:15:15",
        "2023-01-03 00:15:15",
        "2023-01-03 01:15:15",
        "2023-01-03 02:15:15",
        "2023-01-03 03:15:15",
        "2023-01-03 04:15:15",
        "2023-01-03 05:15:15",
        "2023-01-03 06:15:15",
        "2023-01-03 07:15:15",
        "2023-01-03 08:15:15",
        "2023-01-03 09:15:15",
        "2023-01-03 10:15:15",
        "2023-01-03 11:15:15"
      ],
      "values": [
        5.71,
        5.71,
        5.79,
        5.79,
        5.83,
        5.79,
        5.79,
        5.68,
        5.71,
        5.68,
        5.68,
        5.68,
        5.68,
        5.71,
        5.71,
        5.71,
        5.6,
        5.6,
        5.6,
        5.64,
        5.64,
        5.6,
        5.64,
        5.75,
        5.79,
        5.9,
        5.83,
        5.75,
        5.79,
        5.71,
        5.71,
        5.75,
        5.71,
        5.64,
        5.6,
        5.64,
        5.68,
        5.64,
        5.6,
        5.6,
        5.64,
        5.71,
        5.64,
        5.75,
        5.71,
        5.75,
        5.83,
        5.86,
        5.97,
        5.94
      ]
    }
  },
  {
    "id": "90",
    "nombre": "Temperatura",
    "value": "456",
    "latitud": "39.8640",
    "longitud": "-1.3565",
    "id_tipo_sensor": "1",
    "nombre_tipo_sensor": "Temperatura",
    "conversion": "round($val*0.025, 1)",
    "unidades": "ºC",
    "color_front_end": "#FBC02D",
    "id_remote": "011B06",
    "nombre_remote": "Remote Lite Almendros",
    "battery_level": 3965,
    "battery_timestamp": "2023-10-02 13:55:40",
    "real_value": 11.4,
    "historico": {
      "fecha_registro": [
        "2023-01-01 10:15:15",
        "2023-01-01 11:15:15",
        "2023-01-01 12:15:15",
        "2023-01-01 13:15:15",
        "2023-01-01 14:15:15",
        "2023-01-01 15:15:15",
        "2023-01-01 16:15:15",
        "2023-01-01 17:15:15",
        "2023-01-01 18:15:15",
        "2023-01-01 19:15:15",
        "2023-01-01 20:15:15",
        "2023-01-01 21:15:15",
        "2023-01-01 22:15:15",
        "2023-01-01 23:15:15",
        "2023-01-02 00:15:15",
        "2023-01-02 01:15:15",
        "2023-01-02 02:15:15",
        "2023-01-02 03:15:15",
        "2023-01-02 04:15:15",
        "2023-01-02 05:15:15",
        "2023-01-02 06:15:15",
        "2023-01-02 07:15:15",
        "2023-01-02 08:15:15",
        "2023-01-02 09:15:15",
        "2023-01-02 10:15:15",
        "2023-01-02 11:15:15",
        "2023-01-02 12:15:15",
        "2023-01-02 13:15:15",
        "2023-01-02 14:15:15",
        "2023-01-02 15:15:15",
        "2023-01-02 16:15:15",
        "2023-01-02 17:15:15",
        "2023-01-02 18:15:15",
        "2023-01-02 19:15:15",
        "2023-01-02 20:15:15",
        "2023-01-02 21:15:15",
        "2023-01-02 22:15:15",
        "2023-01-02 23:15:15",
        "2023-01-03 00:15:15",
        "2023-01-03 01:15:15",
        "2023-01-03 02:15:15",
        "2023-01-03 03:15:15",
        "2023-01-03 04:15:15",
        "2023-01-03 05:15:15",
        "2023-01-03 06:15:15",
        "2023-01-03 07:15:15",
        "2023-01-03 08:15:15",
        "2023-01-03 09:15:15",
        "2023-01-03 10:15:15",
        "2023-01-03 11:15:15",
      ],
      "values": [
        18.7,
        18.8,
        18.8,
        19,
        19.2,
        19.2,
        19.9,
        19.4,
        19.6,
        20.2,
        19.9,
        20,
        19.5,
        19.7,
        20,
        20.6,
        21.2,
        20.7,
        20.1,
        19.4,
        18.8,
        19.3,
        19,
        19.3,
        19.3,
        19.1,
        19.5,
        19.8,
        20.2,
        20.9,
        20.5,
        20.4,
        19.7,
        19.5,
        19.8,
        20.3,
        20.3,
        20.1,
        20.7,
        21.1,
        21.2,
        21.5,
        22,
        22.2,
        22.1,
        21.7,
        21.3,
        21,
        20.5,
        20.3,
        24,
        23.1,
        22.6,
        22.3,
        22,
        21.8,
        21.6,
        21.5,
        21.3,
        21.1,
        20.9,
        20.8,
        20.7,
        20.6,
        20.5,
        20.4,
        20.3,
        20.2,
        20.2,
        20.1,
        20.1,
        20.1,
        20.1,
        20,
        20,
        20,
        20,
        20,
        20.1,
        20.3,
        20.6,
        20.9,
        21.3,
        21.7,
        22,
        22.4,
      ]
    }
  },
  {
    "id": "329",
    "nombre": "Presión",
    "nombre_tipo_sensor": "Presión en cabezal",
    "conversion": "(0 + (1.2 - 0) / (20 - 4) * (x/1000 - 4) ) * 10",
    "unidades": "bar",
    "historico": {
      "fecha_registro": [
        "2023-02-11 11:54:59",
        "2023-02-11 12:55:05",
        "2023-02-11 13:55:11",
        "2023-02-11 14:55:17",
        "2023-02-11 15:55:23",
        "2023-02-11 16:55:30",
        "2023-02-11 17:55:36",
        "2023-02-11 18:55:42",
        "2023-02-11 19:55:48",
        "2023-02-11 20:55:54",
        "2023-02-11 21:56:00",
        "2023-02-11 22:56:06",
        "2023-02-11 23:56:12",
        "2023-02-12 00:56:18",
        "2023-02-12 01:56:24",
        "2023-02-12 02:56:30",
        "2023-02-12 03:56:36",
        "2023-02-12 04:56:42",
        "2023-02-12 05:56:48",
        "2023-02-12 06:56:54",
        "2023-02-12 07:57:00",
        "2023-02-12 08:57:06",
        "2023-02-12 09:57:12",
        "2023-02-12 10:57:18",
        "2023-02-12 11:57:24",
        "2023-02-12 12:57:30",
        "2023-02-12 13:57:37",
        "2023-02-12 14:57:43",
        "2023-02-12 15:57:49",
        "2023-02-12 16:57:55",
        "2023-02-12 17:58:01",
        "2023-02-12 18:58:07",
        "2023-02-12 19:58:13",
        "2023-02-12 20:58:19",
        "2023-02-12 21:58:25",
        "2023-02-12 22:58:31",
        "2023-02-12 23:59:21",
        "2023-02-13 00:59:27",
        "2023-02-13 01:59:33",
        "2023-02-13 02:59:39",
        "2023-02-13 03:59:45",
        "2023-02-13 04:59:51",
        "2023-02-13 05:59:57",
        "2023-02-13 07:00:03",
        "2023-02-13 08:00:09",
        "2023-02-13 09:00:15",
        "2023-02-13 10:00:21",
        "2023-02-13 11:00:27",
        "2023-02-13 12:00:33",
        "2023-02-13 13:00:39"
      ],
      "values": [
        83.77,
        83.68,
        83.38,
        83.28,
        83.19,
        83.19,
        81.92,
        81.62,
        82.01,
        82.11,
        82.21,
        82.11,
        82.11,
        82.31,
        82.31,
        82.5,
        82.5,
        82.7,
        82.7,
        82.99,
        83.28,
        83.38,
        82.89,
        82.8,
        82.7,
        82.5,
        82.01,
        80.74,
        80.35,
        80.45,
        80.65,
        81.04,
        81.43,
        81.72,
        81.92,
        82.11,
        82.4,
        82.31,
        82.31,
        82.11,
        82.21,
        82.4,
        82.5,
        82.7,
        82.6,
        82.4,
        82.31,
        82.01,
        81.43,
        80.25
      ]
    }
  },
]

const sensoresInforme = [
  {
    "fecha_registro": "2023-09-27T11:33:42.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T11:34:54.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T11:36:27.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T11:36:44.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T11:39:55.000Z",
    "id": 3,
    "value": "10.5",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T11:58:29.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:00:16.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:02:06.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:04:42.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:05:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:07:11.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:07:27.000Z",
    "id": 3,
    "value": "11.1",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:09:17.000Z",
    "id": 3,
    "value": "11.1",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:09:35.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:11:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-09-27T12:13:55.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:31:23.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:32:16.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:32:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:33:08.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:35:21.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:36:31.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:37:54.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:40:20.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:48:00.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:49:32.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:49:49.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:50:38.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:51:18.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:51:36.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:51:54.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:52:11.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:52:29.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:52:47.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:53:04.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:53:22.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:53:40.000Z",
    "id": 3,
    "value": "21.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:53:57.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:54:15.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:54:33.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:54:50.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:55:20.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:55:37.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:55:55.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T07:56:13.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:00:34.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:12:33.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:13:00.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:13:18.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:13:36.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:14:52.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:15:46.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:16:12.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:16:30.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:17:57.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:19:05.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:19:29.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:21:37.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:22:56.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:23:11.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:24:07.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:24:21.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:41:47.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:42:08.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:43:07.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:43:24.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:43:42.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:44:00.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:44:40.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:44:57.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:45:15.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:45:33.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:45:50.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:46:38.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:46:55.000Z",
    "id": 3,
    "value": "22.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:47:13.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:47:31.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:48:08.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:48:26.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:48:44.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:49:01.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:49:32.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:49:50.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:57:28.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:57:41.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:57:53.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:58:20.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:58:38.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T09:59:31.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:00:11.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:03:05.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:03:23.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:03:40.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:03:58.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:04:16.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:04:33.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:05:13.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:05:31.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:05:48.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:06:06.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:06:46.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:07:49.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:08:06.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-26T10:08:24.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:10:32.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:10:49.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:11:07.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:11:25.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:11:43.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:12:00.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:12:18.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:13:38.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:13:50.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:14:03.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:14:37.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:14:55.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:15:12.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:15:30.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:15:48.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:16:36.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:16:53.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:17:11.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:17:29.000Z",
    "id": 3,
    "value": "19.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:17:46.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:18:04.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:18:22.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:18:39.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:18:57.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:19:15.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:19:32.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:19:50.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:20:08.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:20:26.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:20:43.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:21:01.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:21:19.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:21:36.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:21:54.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-27T08:22:12.000Z",
    "id": 3,
    "value": "22.2",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:36:12.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:36:21.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:36:49.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:37:09.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:37:17.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:37:42.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:38:07.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:38:32.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:38:57.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:39:22.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:39:47.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:40:12.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:40:37.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:41:02.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:41:27.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:41:52.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:42:17.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:42:42.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:43:07.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:43:32.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:43:57.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:44:22.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:44:47.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:45:12.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:45:37.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:46:02.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:46:27.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:46:52.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:47:17.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:47:47.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:48:12.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:48:37.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:49:02.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:49:27.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:49:52.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:50:17.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:50:42.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:51:07.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:51:32.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:51:57.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:52:22.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:52:47.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:53:12.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:53:37.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:54:02.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:54:27.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:54:52.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:55:17.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:55:42.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:56:07.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T07:56:32.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:11:24.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:11:49.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:12:14.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:12:39.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:13:04.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:13:29.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:13:54.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:14:19.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:14:44.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:15:09.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:15:34.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:15:59.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:16:24.000Z",
    "id": 3,
    "value": "26.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:17:19.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:17:44.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:18:09.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:18:34.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:18:59.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:19:24.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:19:49.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:20:14.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:20:39.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:21:04.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:21:29.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:21:54.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:22:19.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:22:44.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:23:09.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:23:34.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:23:59.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:24:24.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:24:49.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:25:14.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:25:39.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:26:04.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:26:29.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:26:54.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:27:19.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:27:44.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:28:09.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:28:34.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:28:59.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:29:24.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:29:49.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:30:14.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:30:39.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:31:04.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:31:29.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:31:54.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:32:19.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:32:44.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:33:09.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:33:34.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:34:29.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:34:54.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:35:19.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:36:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:36:39.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:37:04.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:37:29.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:37:54.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:38:19.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:38:44.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:39:09.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:39:34.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:39:59.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:40:24.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:40:49.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:41:14.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:41:39.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:42:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:42:58.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:43:23.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:43:48.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:44:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:44:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:45:03.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:45:28.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:45:53.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:46:18.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:46:43.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:47:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:48:03.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:48:28.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:48:53.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:49:18.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:49:43.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:50:08.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:50:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:50:58.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:51:23.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:51:48.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:52:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:52:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:53:03.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:53:28.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:54:23.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:54:48.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:55:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:55:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:56:03.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:56:28.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:56:53.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:57:18.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:57:43.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:58:08.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:58:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:58:58.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:59:23.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T08:59:48.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:00:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:00:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:01:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:01:58.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:02:23.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:02:48.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:03:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:03:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:04:03.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:04:28.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:04:53.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:05:18.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:05:43.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:06:08.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:06:33.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:06:58.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:07:23.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:07:48.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:08:13.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:08:38.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:09:03.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:09:28.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:09:53.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:10:18.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:10:43.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:11:08.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:11:33.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:11:58.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:12:23.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:12:48.000Z",
    "id": 3,
    "value": "25.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:13:13.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:13:38.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:14:03.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:14:57.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:15:22.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:15:47.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:16:12.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:16:37.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:17:02.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
  {
    "fecha_registro": "2023-10-28T09:17:27.000Z",
    "id": 3,
    "value": "23.0",
    "nombre": "Temperatura",
    "unidades": "ºC"
  },
]

module.exports = { sensoresData, updateSensor, sensoresInforme, getSensores }
