<template>
    <div>
        <v-card-title v-if="valvula === -1 && sensorSelectedOnMap === null" class="py-2 px-1">
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <v-row>
                            <v-col style="font-weight: normal;">
                                <v-icon left color="teal"
                                    :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'">mdi-information-outline
                                </v-icon>
                                <span>Selecciona en el plano de la finca una válvula o</span>
                                <span class="d-block d-sm-inline" :class="$vuetify.breakpoint.smAndDown ? 'ml-8' : ''">
                                    sensor para visualizar</span>
                            </v-col>
                        </v-row>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card-title>
        <v-card v-else :class="this.$vuetify.breakpoint.mobile ? 'pa-0 ma-0 mb-2' : 'pa-1'">
            <v-card-title>
                <span v-if="!$vuetify.breakpoint.smAndDown" class="headline">Remote</span>
                <span
                    :style="{ fontSize: $vuetify.breakpoint.smAndDown ? '18px' : '15px', color: 'rgb(1, 99, 99)', marginLeft: '16px' }">{{
            name_remote }}</span>
                <v-spacer></v-spacer>
                <!-- Hora, fecha del último dato de la batería -->
                <v-chip outlined small color="gray" class="mr-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" style="display: flex; align-items: center;">
                                <v-icon color="gray" small>mdi-information-slab-circle-outline</v-icon>
                            </div>
                        </template>
                        <div style="display: flex; flex-direction: column; align-items: flex-start;">
                            <div style="display: flex; justify-content: center; width: 100%;">
                                <v-icon small left color="white">mdi-identifier</v-icon>
                                <span style="font-size: 0.7rem; text-align: right; flex-grow: 1;">{{
            remoteId.toUpperCase() }}</span>
                            </div>
                            <div style="display: flex; justify-content: center; width: 100%;">
                                <v-icon small left color="white">mdi-cellphone-information</v-icon>
                                <span style="font-size: 0.7rem; text-align: right; flex-grow: 1;">{{ version }}</span>
                            </div>
                        </div>
                    </v-tooltip>
                </v-chip>
                <!-- Nivel de la bateria -->
                <v-chip outlined small :color="battery_value < 20 ? 'red darken-1' : 'teal darken-2'" class="mr-1"
                    v-if="battery_value >= 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" style="display: flex; align-items: center;">
                                <v-icon :color="battery_value < 20 ? 'red' : 'teal darken-2'" small>{{ batteryClass
                                    }}</v-icon>
                                <span :class="{ 'red-text': battery_value < 20, 'teal-text': battery_value >= 20 }">{{
            battery_value
        }}%</span>
                            </div>
                        </template>
                        <span>
                            <v-icon small left color="white">mdi-clock-outline</v-icon>
                            <span style="font-size: 0.875rem;">{{ battery_timestamp }}</span>
                        </span>
                    </v-tooltip>
                </v-chip>
                <!-- Botón cerrar -->
                <v-icon @click="closeButtonClick()">mdi-window-close</v-icon>
            </v-card-title>
            <v-card-text>
                <span v-if="show_battery_details_on_hover" style="float: right;">
                    <v-chip outlined>
                        <v-icon left>mdi-clock-outline</v-icon>
                        {{ battery_timestamp }}
                    </v-chip>
                </span>
                <v-subheader>
                    <span>SENSORES</span>
                </v-subheader>
                <v-divider class="mt-n2"></v-divider>
                <template v-if="show_progress">
                    <span class="d-flex flex-column align-center my-3">
                        <v-progress-circular size="100" width="5" indeterminate color="teal darken-3"
                            class="my-5"></v-progress-circular>
                    </span>
                </template>
                <template v-if="sensores_asociados === true && show_progress === false">
                    <v-slide-group v-model="model" class="my-4">
                        <v-slide-item v-for="(chart, index) in CHART_CARDS" :key="index">
                            <div>
                                <ChartCards :title="chart.title" :unidad="chart.unidad"
                                    :ultimo_valor="chart.ultimoValor" :ultimoRegistro="chart.ultimoRegistro"
                                    :is-selected="chart.id === id_sensor" :fechas="fechas" :data="chart.data"
                                    @update:isSelected="sensorSelected = $event" :color="chart.color" />
                            </div>
                        </v-slide-item>
                    </v-slide-group>
                </template>
                <template v-else-if="sensores_asociados === false && show_progress === false">
                    <div class="d-flex flex-column align-center my-6">
                        <v-chip outlined color="primary" class="ma-2" pill>
                            <v-icon left>
                                mdi-water-thermometer-outline
                            </v-icon>
                            <span style="font-size: 14px;"> No existen sensores asociados al
                                remote
                            </span>
                        </v-chip>
                    </div>
                </template>
                <v-subheader>
                    <span>VÁLVULAS</span>
                </v-subheader>
                <v-divider class="mt-n2"></v-divider>
                <!--Bóton, botones por cada válvula que se encuentre-->
                <div class="my-4" row>
                    <template v-if="filteredValvulas.length > 0">
                        <v-slide-group v-model="model" class="my-4">
                            <v-slide-item v-for="valvula in filteredValvulas" :key="valvula.id">
                                <v-btn text @click="handleValveButtonClick(valvula.id)" x-large elevation="3"
                                    :class="{ 'valvula-selected': valvula.id === is_selected_valvula_id }"
                                    class="mx-3 my-2">
                                    <div class="d-flex flex-column align-center">
                                        <img :src="getImagePath(valvula.current_state)"
                                            :alt="getImageAlt(valvula.current_state)"
                                            style="width: 50px; height: 55px;">
                                        <div class="text-caption mt-3"
                                            :style="{ 'text-align': 'center', 'text-transform': 'capitalize', 'color': valvula.current_state === 1 ? 'rgb(5, 100, 100)' : 'rgb(108, 107, 107)' }">
                                            Válvula {{ valvula.nombre }}
                                        </div>
                                    </div>
                                </v-btn>
                            </v-slide-item>
                        </v-slide-group>
                    </template>
                    <template v-else>
                        <div class="d-flex flex-column align-center my-6">
                            <v-chip outlined color="teal" class="ma-2" pill>
                                <v-icon left>
                                    mdi-pipe-valve
                                </v-icon>
                                <span style="font-size: 14px;"> No existen valvulas asociadas al
                                    remote
                                </span>
                            </v-chip>
                        </div>
                    </template>
                </div>
            </v-card-text>
            <!-- Dialog por cada vez que se presione el botón de las válvulas-->
        </v-card>
        <v-dialog v-model="dialog_valve" max-width="350">
            <v-card height="415">
                <v-card dark class="darken-4 card-with-bg rounded-b-xl" height="205">
                    <div class="d-flex align center justify-end pt-6 pr-4">
                        <v-icon @click="closeDialogValveClick()">mdi-window-close</v-icon>
                    </div>
                    <div class="pt-1 pb-4 px-13 d-flex align-center justify-center">
                        <span style="font-size: 28px; font-weight: bold;" class="pl-13 mx-1">Válvula</span>
                        <v-text-field autocomplete="off" :readonly="readonly" solo hide-details :flat="readonly"
                            v-model="valveName" id="input-nombre" style="font-size: 28px;"
                            background-color="transparent"></v-text-field>
                    </div>
                    <div class="d-flex align-center justify-center">
                        <v-fade-transition>
                            <div style="position: relative; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
              width: 200px; height: 200px; border-radius: 50%; background-color: white;">
                                <v-fade-transition leave-absolute>
                                    <v-progress-circular v-if="valveLoader" size="200" width="10" indeterminate
                                        color="teal">
                                        <div class="d-flex flex-column align-center">
                                            <span class="black--text" style="font-size: 18px">Enviando datos...</span>
                                        </div>
                                    </v-progress-circular>
                                    <v-icon size="200" class="d-flex flex-column align-center" v-else-if="mqttFail"
                                        color="red">
                                        mdi-close-circle
                                    </v-icon>
                                    <v-icon size="200" class="d-flex flex-column align-center"
                                        v-else-if="!valveLoader && mqttSuccess" color="teal">
                                        mdi-check-circle
                                    </v-icon>
                                </v-fade-transition>
                                <v-progress-circular v-if="!valveLoader && !mqttSuccess && !mqttFail" :size="200"
                                    :rotate="270" :width="10" :style="{ left: '50%', transform: 'translateX(-50%)' }"
                                    :color="currentState >= 1 ? 'teal' : 'grey lighten-3'"
                                    :value="currentState >= 1 ? 100 : 0">
                                    <div class="d-flex flex-column align-center">
                                        <v-icon x-large left
                                            :color="currentState >= 1 ? 'teal darken-2' : 'grey darken-2'"
                                            class="pl-2">{{
            getIconClass(currentState) }} </v-icon>
                                        <span class="black--text" style="font-size: 28px">{{ currentState >= 1 ? 100 : 0
                                            }}%</span>
                                    </div>
                                </v-progress-circular>
                            </div>
                        </v-fade-transition>
                    </div>
                </v-card>
                <div class="pt-14 mt-6">
                    <v-list-item class="mt-14">
                        <v-btn class="pa-4" :class="isAuto ? 'teal darken-3 my-5' : 'warning my-5'" :dark="isAuto == 1"
                            small @click="openDialog" :disabled="is_button_disabled">
                            <v-icon left>{{
            isAuto ? "mdi-alpha-a-circle" : "mdi-alpha-m-circle"
        }}</v-icon>
                            {{ isAuto ? "AUTO" : "MANUAL" }}
                        </v-btn>
                        <v-list-item-subtitle class="d-flex justify-end align-end">
                            <!--Boton cerrar-->
                            <v-btn class="pa-3" outlined color="red darken-4" @click="handleSetLatch(0)"
                                :disabled="is_button_disabled" v-if="!isAuto">Cerrar</v-btn>
                            <!--Boton Abrir-->
                            <v-btn class="ml-4 pa-4" color="teal darken-2" @click="handleSetLatch(1)"
                                :disabled="is_button_disabled" :dark="!is_button_disabled" v-if="!isAuto">Abrir</v-btn>
                        </v-list-item-subtitle>
                    </v-list-item>
                </div>
            </v-card>
        </v-dialog>

        <!-- Alerta de cambio de estado -->
        <v-dialog v-model="show_dialog" width="520">
            <v-card>
                <v-card-title class="headline subtitle-1">
                    <v-icon color="teal darken-2">mdi-information</v-icon>
                    <h2 class="ml-4"><strong>Cambio de estado</strong></h2>
                </v-card-title>
                <v-card-text class="subtitle-1" v-if="valvula_en_programacion && !isAuto">
                    ¿Estás seguro de cambiar el estado de la válvula a
                    <strong> {{ isAuto ? " Manual" : " Automático" }}</strong>?
                </v-card-text>
                <v-card-title class="headline" v-if="valvula_en_programacion && isAuto">
                    <v-icon color="red darken-2" class="mr-1">mdi-alert</v-icon>
                    ¡Atención!
                </v-card-title>

                <v-card-text v-if="valvula_en_programacion && isAuto">
                    No se puede cambiar el estado de la válvula a <strong>Manual</strong> ya que está en la programación
                    <strong> {{ nombre_programacion }}</strong> del grupo <strong>{{ nombre_grupo }}</strong>
                </v-card-text>

                <v-card-text v-else-if="valvula_en_programacion && !isAuto">
                    <v-icon color="orange darken-4" class="mr-1">mdi-alert</v-icon>
                    <span>Ten en cuenta que esta válvula se activa mediante la programación:
                        <strong>{{ nombre_programacion }}</strong></span>
                </v-card-text>

                <!-- Si no hay programación -->
                <v-card-text v-else-if="!valvula_en_programacion">
                    ¿Estás seguro de cambiar el estado de la válvula a
                    <strong> {{ isAuto ? " Manual" : " Automático" }}</strong>?
                </v-card-text>
                <!-- Botones de aceptar y cancelar -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="teal darken-2" text @click="show_dialog = false"
                        v-if="valvula_en_programacion && isAuto">Aceptar</v-btn>
                    <v-btn color="red darken-4" text @click="show_dialog = false"
                        v-else-if="valvula_en_programacion && !isAuto">Cancelar</v-btn>
                    <v-btn color="teal darken-2" text @click="handleAutoManual(); show_dialog = false"
                        v-if="valvula_en_programacion && !isAuto">Aceptar</v-btn>
                    <v-btn color="red darken-4" text @click="show_dialog = false"
                        v-else-if="!valvula_en_programacion">Cancelar</v-btn>
                    <v-btn color="teal darken-2" text @click="handleAutoManual(); show_dialog = false"
                        v-if="!valvula_en_programacion">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { updateValvulaState } from '@/data/valvulasData';
import ChartCards from '@/components/ChartCards.vue';
import valve_closed from "@/assets/valve_closed.png";
import valve_opened from "@/assets/valve_opened.png";
export default {
    components: {
        ChartCards,
    },
    props: ["remoteId", "valvula", "valvulas", 'sensores', 'sensorSelectedOnMap', 'grupos', 'programacionesSecuenciales'],
    data() {
        //Remote
        const name_remote = null;
        //Valvulas
        let valvulas_abiertas = [];
        this.valvulas.map((el) => {
            if (el.current_state >= 1) valvulas_abiertas.push(el);
        });
        //Sensores
        let id_sensor = -1;
        let sensor;
        if (this.sensorSelectedOnMap != null) {
            sensor = this.sensorSelectedOnMap;
            id_sensor = this.sensorSelectedOnMap.id
        }
        //Graficos
        const fechas = [];
        const CHART_CARDS = [];

        return {
            //Remote
            name_remote,
            version: null,
            battery_value: 0,
            battery_timestamp: 0,
            //Valvulas
            is_selected_valvula_id: null,
            valveName: this.valvula.nombre,
            valveId: this.valvula.id,
            currentState: this.valvula.current_state || 0,
            valvulas_abiertas,
            mqttFail: false,
            mqttSuccess: false,
            isAuto: false,
            is_button_disabled: false,
            valveLat: parseFloat(this.valvula.latitud),
            valveLong: parseFloat(this.valvula.longitud),
            switchState: false,
            show_dialog: false,
            valvula_en_programacion: false,
            id_grupo: null,
            nombre_programacion: null,
            nombre_grupo: null,

            // Diseños
            readonly: true,
            valveLoader: false,
            show_battery_details_on_hover: false,
            // Dialog
            dialog_valve: false,
            model: null,
            //Graficos
            loading_chart_data: false,
            fechas,
            CHART_CARDS,
            show_progress: false,
            //Sensores
            id_sensor,
            sensor,
            historico: null,
            ultimoValor: null,
            ultimoRegistro: null,
            sensores_asociados: false,
        };
    },
    watch: {
        // Función que se ejecuta cada vez que los sensores cambian
        sensores: function (newVal, oldVal) {
            if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
                this.sensores.find(sensor => {
                    if (sensor.id == this.id_sensor) {
                        this.sensor = sensor;
                        this.ultimoValor = sensor.ultimoValor;
                        this.ultimoRegistro = sensor.ultimoRegistro;
                        this.historico = sensor.historico;
                    }
                });
            }
        },
        // Watch que se ejecuta cada vez que se pulsa sobre un sensor
        sensorSelectedOnMap: function (newVal) {
            this.sensor = newVal;
            this.id_sensor = newVal.id;
            this.name_remote = newVal.nombre_remote;
            const timestamp_raw = newVal.battery_timestamp;
            const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            this.battery_timestamp = new Date(timestamp_raw).toLocaleString('es-ES', options);

            if (newVal.battery_level >= 0 && newVal.battery_level < 3500) {
                this.battery_value = 0;
            } else {
                this.battery_value = (0 + (100 - 0) * (newVal.battery_level - 3347) / (4095 - 3347)).toFixed(0);
            }

            this.version = newVal.version;

            this.getSensorDataFromRemoteIdBetweenTimes();
        },
        //Watch para revisar si hay algún cambio en las válvulas, de encontrarlo actualiza la vista
        valvulas: function (newVal, oldVal) {
            let valvulas_abiertas = [];
            newVal.map((el) => {
                if (el.current_state >= 1) {
                    valvulas_abiertas.push(el);
                }
                oldVal.map((elOld) => {
                    if (el.id === elOld.id) {
                        if (el.current_state !== elOld.current_state) {
                            this.$emit("updateElectrovalvulas");
                        }
                    }
                });
                newVal.find((el) => {
                    if (el.id === this.valvula.id) {
                        this.currentState = el.current_state;
                        this.switchState = el.current_state > 0 ? true : false;
                        this.isAuto = el.is_auto;
                    }
                });
            });
            this.valvulas_abiertas = valvulas_abiertas;
        },
        // Watch que se ejecuta cada vez que se pulsa sobre una valvula
        valvula: function (newVal) {
            this.is_selected_valvula_id = newVal.id;
            this.name_remote = newVal.nombre_remote;
            this.valveId = newVal.id
            this.valveName = newVal.nombre;
            this.valveLat = newVal.latitud;
            this.valveLong = newVal.longitud
            this.readonly = true;
            this.currentState = newVal.current_state;
            this.switchState = newVal.current_state > 0 ? true : false;
            //TODO: deberia seer = a this.getIsAuto()
            this.isAuto = newVal.is_auto;
            const timestamp_raw = newVal.battery_timestamp;
            const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            this.battery_timestamp = new Date(timestamp_raw).toLocaleString('es-ES', options);

            if (newVal.battery_level >= 0 && newVal.battery_level < 3500) {
                this.battery_value = 0;
            } else {
                this.battery_value = (0 + (100 - 0) * (newVal.battery_level - 3347) / (4095 - 3347)).toFixed(0);
            }

            this.version = newVal.version;
            this.getSensorDataFromRemoteIdBetweenTimes();

        },
        // Watch para revisar si hay algún cambio en los grupos, de encontrarlo actualiza la vista
        grupos: function (newVal, oldVal) {
            if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
                this.getGruposAndProgramaciones(this.valveId);
            }

        },
        // Watch para revisar si hay algún cambio en las programaciones secuenciales, de encontrarlo actualiza la vista
        programacionesSecuenciales: function (newVal, oldVal) {
            if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
                this.getGruposAndProgramaciones(this.valveId);
            }
        },
    },
    methods: {
        getGruposAndProgramaciones(valvulaId) {
            // Reseteo variables
            this.valvula_en_programacion = false;
            this.nombre_programacion = null;
            this.nombre_grupo = null;
            this.id_grupo = null;

            // Compruebo si la válvula seleccionada está en un grupo 
            this.grupos.forEach(grupo => {
                grupo.rels.forEach(valvula => {
                    if (valvula.id_electrovalvula === valvulaId) {
                        this.id_grupo = grupo.id;
                    }
                })
            })

            // Compruebo si la válvula seleccionada está en un grupo y si el grupo tiene programaciones secuenciales
            this.programacionesSecuenciales.forEach(programacion => {
                // Recorro programacion.secuencia para encontrar el id_configuracion que es igual al id_grupo
                programacion.secuencia.forEach(secuencia => {
                    if (secuencia.id_configuracion == this.id_grupo) {
                        this.valvula_en_programacion = true;
                        this.nombre_programacion = programacion.nombre;
                        this.nombre_grupo = secuencia.nombre;
                    }
                })
            })
        },
        openDialog() {
            this.show_dialog = true;

        },
        clearValues() {
            this.CHART_CARDS = [];
            this.mqttFail = false;
            this.mqttSuccess = false;
            this.isAuto = false;
            this.is_button_disabled = false;
        },
        checkSensoresAsociados() {
            if (this.CHART_CARDS.length === 0) {
                this.noSensoresAsociados = true;
            } else {
                this.noSensoresAsociados = false;
            }
        },
        //Para tomar datos del sensor desde su id remote
        getSensorDataFromRemoteIdBetweenTimes() {
            this.clearValues();
            this.show_progress = true;

            // Recopila todos los sensores
            const allSensors = this.sensores;

            // Itera a través de las válvulas
            this.filteredValvulas.forEach(valvula => {
                const valveRemoteId = valvula.id_remote;
                const matchingSensors = allSensors.filter(sensor => sensor.id_remote === valveRemoteId);

                // Verifica si hay sensores asociados a la válvula actual
                if (matchingSensors.length > 0) {
                    this.CHART_CARDS = [];
                    matchingSensors.forEach(sensor => {
                        if (sensor.id_tipo_sensor != 19) {
                            const fechaRegistro = sensor.historico.fecha_registro;
                            const values = sensor.historico.values;
                            const timestamps = fechaRegistro.map(fecha => (new Date(fecha)).getTime());
                            const data = timestamps.map((timestamp, index) => [timestamp, values[index]]);

                            this.CHART_CARDS.push({
                                id: sensor.id,
                                title: sensor.nombre,
                                data: data,
                                unidad: sensor.unidades,
                                color: sensor.color_front_end,
                                ultimoValor: sensor.real_value,
                            });
                        }
                    });
                }
            });

            // Establece el valor de sensores_asociados basado en CHART_CARDS
            this.sensores_asociados = this.CHART_CARDS.length > 0;

            setTimeout(() => {
                this.show_progress = false;
            }, 2000);
        },
        handleValveButtonClick(valvulaId) {
            this.is_selected_valvula_id = valvulaId;
            const selectedValvula = this.valvulas.find(valvula => valvula.id === valvulaId);
            if (selectedValvula) {
                this.dialog_valve = true;
                this.valveId = selectedValvula.id;
                this.valveName = selectedValvula.nombre;
                this.readonly = true;
                this.currentState = selectedValvula.current_state;
                this.switchState = selectedValvula.current_state > 0 ? true : false;
                this.isAuto = selectedValvula.is_auto;
            }

            this.getGruposAndProgramaciones(valvulaId);
        },
        closeDialogValveClick() {
            this.dialog_valve = false;
        },
        getImagePath(currentState) {
            return currentState >= 1 ? valve_opened : valve_closed;
        },
        getImageAlt(currentState) {
            return currentState >= 1 ? 'valve_opened' : 'valve_closed';
        },
        getIconClass(currentState) {
            return currentState >= 1 ? 'mdi-valve-open' : 'mdi-valve-closed';
        },
        //Para cerrar el card del Remote
        closeButtonClick() {
            this.$emit('close-card');
        },
        handleAutoManual() {
            this.isAuto = !this.isAuto;
            this.$emit('updateManualAutoValve', this.valvula)
        },
        handleUpdateName() {
            this.$emit('updateNameValve', { id: this.valvula.id, name: this.valveName })
            this.readonly = true;
        },
        async handleSetLatch(state) {
            const selectedValvula = this.valvulas.find(valvula => valvula.id === this.valveId);
            this.is_button_disabled = true;
            this.valveLoader = true;
            await this.timeSleep(1.5)
            this.valveLoader = false;
            this.mqttSuccess = true;
            this.is_button_disabled = false;
            if (state == 0) {
                const index = this.valvulas_abiertas.findIndex((valvula) => valvula.id == selectedValvula.id)
                if (index != -1) this.valvulas_abiertas.splice(index, 1);
            } else {
                this.valvulas_abiertas.push(selectedValvula)
            }
            this.currentState = state
            updateValvulaState(selectedValvula, state)
            
            this.$emit('valveStateChanged', { id: selectedValvula.id, current_state: state });
            // this.$emit('changeStateValve', this.valvula)
            await this.timeSleep(1.5)
            this.mqttSuccess = false;
        },
        focusInput() {
            this.readonly = !this.readonly
            if (!this.readonly) {
                document.getElementById("input-nombre").focus()
            }
            if (this.readonly) {
                this.valveName = this.valvula.nombre
                this.valveLat = this.valvula.latitud;
                this.valveLong = this.valvula.longitud;
            }
        },
        changeLocation() {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords
                this.valveLat = latitude;
                this.valveLong = longitude;
            }
                , {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                })
        },
        timeSleep(seconds) {
            return new Promise((resolve) => {
                setTimeout(resolve, seconds * 1000)
            })
        },
    },
    computed: {
        batteryClass() {
            if (this.battery_value < 30) {
                return "mdi-battery-alert";
            } else if (this.battery_value < 60) {
                return "mdi-battery-50";
            } else if (this.battery_value < 80) {
                return "mdi-battery-80";
            } else {
                return "mdi-battery";
            }
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        filteredValvulas() {
            return this.valvulas.filter(valvula => valvula.id_remote === this.remoteId);
        },
    },
    beforeMount() {
        this.clearValues();
        this.getSensorDataFromRemoteIdBetweenTimes();
    },
};
</script>

<style scoped>
.row .v-btn {
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 140px;
    height: 140px;
    font-size: 24px;
}

.valvula-selected {
    border: 2px solid rgb(0, 121, 107);
    box-shadow: 0 0 30px rgba(17, 40, 102, 0.62)
}

.red-text {
    color: red;
}

.teal-text {
    color: teal;
}

.card-with-bg {
    background-image: url(../assets/bg.png);
    background-size: cover;
}
</style>