function insertInformes(informe) {
    const id = localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482'
        ? (informesData.length > 0 ? informesData[informesData.length - 1].id + 1 : 0)
        : (informesDos.length > 0 ? informesDos[informesDos.length - 1].id + 1 : 0);

    const nuevoInforme = {
        id,
        id_dispositivo: "B827EB7BE480",
        nombre: informe.nombre,
        fecha_registro: new Date().toISOString(),
        fecha_modificacion_s: new Date().toISOString(),
        fecha_inicio: new Date(informe.fecha_inicio).toISOString(),
        fecha_fin: new Date(informe.fecha_fin).toISOString(),
        URL_pdf: "https://smartwater-server-informes-bucket.s3.amazonaws.com/Informe_Finca ECO_202407091457.pdf",
    };

    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        informesData.push(nuevoInforme);
    } else {
        informesDos.push(nuevoInforme);
    }
}

function updateInformes(informe) {
    const updatedInforme = {
        ...informe,
        fecha_modificacion_s: new Date().toISOString(), // Update modification date
        fecha_inicio: new Date(informe.fecha_inicio).toISOString(),
        fecha_fin: new Date(informe.fecha_fin).toISOString(),
    };

    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const index = informesData.findIndex((pr) => pr.id === informe.id);
        Object.assign(informesData[index], updatedInforme);
    } else {
        const index = informesDos.findIndex((pr) => pr.id === informe.id);
        Object.assign(informesDos[index], updatedInforme);
    }
}

function deleteInformes(id) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        const index = informesData.findIndex((gr) => gr.id === id);
        informesData.splice(index, 1)

    } else {
        const index = informesDos.findIndex((gr) => gr.id === id);
        informesDos.splice(index, 1)
    }
}

function getInformes() {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        return informesData
    } else {
        return informesDos
    }
}

let informesData = [
    {
        "id": 14,
        "nombre": "Reporte",
        "fecha_registro": "2024-07-09T16:57:08.000Z",
        "fecha_modificacion_s": "2024-07-09T16:57:08.000Z",
        "fecha_inicio": "2024-02-10T00:00:00.000Z",
        "fecha_fin": "2024-04-24T00:00:00.000Z",
        "URL_pdf": "https://smartwater-server-informes-bucket.s3.amazonaws.com/Informe_Finca ECO_202407091457.pdf",
    }
]

let informesDos = [

]

module.exports = { getInformes, insertInformes, deleteInformes, updateInformes }