<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6">
                <ContadoresList :contadores="contadores" @select-contador="showContadorDetails" />
            </v-col>
            <v-col cols="12" sm="6">
                <ContadoresChart :contador="selectedContador" :contadores="contadores" @clear-contador="clearSelectedContador" />
            </v-col>
            <v-col>
                <v-card :class="$vuetify.breakpoint.mobile ? 'mb-13' : ''">
                    <v-card-text style="padding: 0px">
                        <MapContadores :contadores="contadores" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MapContadores from "@/components/MapContadores.vue";
import ContadoresList from "@/components/ContadoresList.vue";
import ContadoresChart from "@/components/ContadoresChart.vue";
import { getContadores } from "@/data/contadoresData";

export default {
    components: {
        ContadoresList,
        ContadoresChart,
        MapContadores,
    },
    data() {
        return {
            contadores: getContadores(),
            selectedContador: null,
        };
    },
    methods: { 
        showContadorDetails(contador) {
            this.selectedContador = contador;
        },
        clearSelectedContador() {
            this.selectedContador = null;
        }
    },
}
</script>

<style></style>
