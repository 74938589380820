<template>
  <div>
    <v-card :class="$vuetify.breakpoint.mobile ? 'mb-7' : ''">
      <v-card dark class="card-bg rounded-b-xl" :height="$vuetify.breakpoint.mobile ? '160' : '240'">
        <div class="teal-overlay">
          <v-row align="center">
            <v-col xs="12" lg="3" class="d-flex justify-center">
              <div :style="{
                position: 'relative',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                marginTop: $vuetify.breakpoint.mobile ? '50px' : '100px', marginLeft: $vuetify.breakpoint.mobile ? '30px' : '',
              }" :size="$vuetify.breakpoint.mobile ? '170' : '220'">
                <div class="d-flex flex-column align-center">
                  <v-avatar :class="{ 'rotate-360': rotate }" color="teal"
                    :size="$vuetify.breakpoint.mobile ? '170' : '220'">
                    <img v-if="URL_avatar" :src="URL_avatar" alt="Imagen seleccionada" />
                    <span v-else :style="{ fontSize: $vuetify.breakpoint.mobile ? '3em' : '4rem' }">{{ avatar }}</span>
                  </v-avatar>
                  <!-- Modal de recorte de imagen -->
                  <v-dialog v-model="cropper_dialog" max-height="450px" max-width="450px">
                    <v-card contain>
                      <v-card-title>
                        <v-img src="../assets/logo-leaf.png" height="30" max-width="30"></v-img>
                        <span class="ml-2"> Editar imagen</span>
                        <v-spacer></v-spacer>
                        <v-btn @click="handleImageFile" color="teal darken-4" icon @mouseenter="changeIconEdit(true)"
                          @mouseleave="changeIconEdit(false)" text><v-icon>{{ edit_icon }}</v-icon></v-btn>
                        <input ref="fileInput" type="file" style="display: none" @change="handleFileChange"
                          accept=".jpg, .jpeg, .png" />
                        <v-btn color="teal darken-4" icon text @mouseenter="changeIcon(true)"
                          @mouseleave="changeIcon(false)" @click="openDeleteConfirmation">
                          <v-icon>{{ remove_icon }}</v-icon>
                        </v-btn>
                        <v-btn @click="closeCropperDialog" icon text><v-icon>mdi-window-close</v-icon></v-btn>
                      </v-card-title>
                      <CropperImage v-if="cropper_dialog" contain :image-src="image_to_croppe"
                        @cropped="handleAndSaveCroppedImage" />
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="image_dialog" max-width="450px">
                    <v-card contain>
                      <v-card-title>
                        <v-img src="../assets/logo-leaf.png" height="30" max-width="30"></v-img>
                        <span class="ml-2"> Editar imagen</span>
                        <v-spacer></v-spacer>
                        <v-btn @click="handleImageFile" color="teal darken-4" icon @mouseenter="changeIconEdit(true)"
                          @mouseleave="changeIconEdit(false)" text><v-icon>{{ edit_icon }}</v-icon></v-btn>
                        <input ref="fileInput" type="file" style="display: none" @change="handleFileChange"
                          accept=".jpg, .jpeg, .png" />
                        <v-btn color="teal darken-4" icon text @mouseenter="changeIcon(true)"
                          @mouseleave="changeIcon(false)" @click="openDeleteConfirmation">
                          <v-icon>{{ remove_icon }}</v-icon>
                        </v-btn>
                        <v-btn @click="closeImageDialog" icon text><v-icon>mdi-window-close</v-icon></v-btn>
                      </v-card-title>
                      <v-card v-if="image_dialog">
                        <v-img :src="URL_avatar" contain></v-img>
                      </v-card>
                    </v-card>
                  </v-dialog>
                  <!-- Modal de confirmación de borrado -->
                  <v-dialog v-model="delete_confirmation" max-width="250px">
                    <v-card class="d-flex flex-column align-center" dark style="height: 100px; ">
                      <span style="font-size: smaller; " class="mt-5"> ¿Deseas eliminar la foto de perfil? </span>
                      <v-list-item-subtitle class="d-flex justify-end align-end mb-3">
                        <v-btn @click="closeDeleteConfirmation" color="teal darken-2" plain
                          style="text-transform: none;">Cancelar</v-btn>
                        <v-btn @click="deleteImage" :loading="loading_delete" color="error" plain
                          style="text-transform: none;">Eliminar</v-btn>
                      </v-list-item-subtitle>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="image_limit" max-width="250px">
                    <v-card class="d-flex flex-column align-center" dark style="height: 100px; ">
                      <span style="font-size: smaller; " class="mt-5"> La imagen supera el límite de 1MB </span>
                      <v-list-item-subtitle class="d-flex justify-end align-end mb-3">
                        <v-btn @click="closeDialogLimit" color="teal darken-2" plain
                          style="text-transform: none;">OK</v-btn>
                      </v-list-item-subtitle>
                    </v-card>
                  </v-dialog>
                  <v-btn fab small style="position: absolute; bottom: 0; right: 0; margin-bottom: 17px;"
                    :class="$vuetify.breakpoint.mobile ? 'pr-0 mb-2' : 'mr-2'" @click="handleEditButtonAndImageFile">
                    <v-icon small>mdi-camera-outline</v-icon>
                  </v-btn>
                  <input ref="fileInput" type="file" style="display: none" @change="handleFileChange"
                    accept=".jpg, .jpeg, .png" />
                </div>
              </div>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.smAndDown">
              <div class="d-flex flex-column">
                <span :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h3'">{{ greetingMessage }}</span>
                <span>{{ email }}</span>
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndDown">
              <div class="d-flex flex-column align-center">
                <span class="text-h5">{{ greetingMessage }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <!--Formulario de datos de usuario-->
      <div style="margin-top: 50px;" :class="$vuetify.breakpoint.mobile ? 'pa-5 mt-15' : 'pa-12'">
        <div class="d-flex justify-space-between align-center">
          <v-subheader>
            <span>DATOS DE USUARIO</span>
          </v-subheader>
          <v-spacer></v-spacer>
          <v-btn @click="toggleEditMode" color="warning" style="text-transform: none;" rounded class="mb-5">
            <v-icon left>mdi-account-edit</v-icon>
            Editar perfil
          </v-btn>
        </div>
        <v-divider class="mt-n2"></v-divider>
        <v-form ref="form" lazy-validation :class="$vuetify.breakpoint.mobile ? 'ma-7' : 'ma-12'">
          <v-row>
            <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="2" class="d-flex align-center justify-center">
              <span class="text-center font-weight-bold ">Editar perfil</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="nombre" outlined required :disabled="!edit_mode" label="Nombre" :rules="NameRules"
                class="rounded-xl" prepend-inner-icon="mdi-account-outline" @input="capitalizeFirstLetter"></v-text-field>
              <v-text-field v-model="primer_apellido" outlined :disabled="!edit_mode" @input="capitalizeFirstLetter"
                :rules="LastNameRules" class="rounded-xl" prepend-inner-icon="mdi-account-convert-outline"
                label="Primer apellido" hint="Minimo 2 caracteres"></v-text-field>
              <v-text-field v-model="segundo_apellido" outlined :disabled="!edit_mode" @input="capitalizeFirstLetter"
                class="rounded-xl" label="Segundo apellido" prepend-inner-icon="mdi-account-convert-outline"
                hint="Minimo 2 caracteres"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.mobile ? 'mb-5 mt-n5' : ''">
              <v-text-field v-model="movil" required outlined prepend-inner-icon="mdi-cellphone-sound" label="Móvil"
                class="rounded-xl" :disabled="!edit_mode" :rules="MovilRules"></v-text-field>
              <v-text-field v-model="telefono" outlined prepend-inner-icon="mdi-phone-dial-outline" :disabled="!edit_mode"
                class="rounded-xl" label="Teléfono" hint="Maximo 9 caracteres"></v-text-field>
              <v-text-field v-model="email" placeholder="example@ecoes.eco" required label="Correo electrónico"
                class="rounded-xl" hint="Ingrese su dirección de correo electrónico, example@ecoes.eco" outlined
                prepend-inner-icon="mdi-email-edit-outline" append-outer-icon="mdi-help-circle-outline"
                :disabled="!edit_mode" :rules="EmailRules">
                <v-icon slot="append-outer" @mouseenter="show_help_alert = true" @mouseleave="show_help_alert = false">
                  mdi-help-circle-outline</v-icon></v-text-field>
              <div>
                <v-alert v-if="show_help_alert && edit_mode" v-model="alert" :dismissible="$vuetify.breakpoint.mobile"
                  color="warning" border="left" elevation="2" colored-border icon="mdi-alert-outline">
                  <strong>Ten en cuenta que</strong> al completar este campo, se convertirá en tu credencial de acceso.
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <!--Formulario de cambio de contraseña-->
        <div class="d-flex justify-space-between align-center">
          <v-subheader>
            <span>CAMBIO DE CONTRASEÑA</span>
          </v-subheader>
          <v-spacer></v-spacer>
          <v-btn class="mb-5" color="success" style="text-transform: none;" large rounded
            :disabled="!edit_mode || !hasRulesFields()" @click="saveFormChanges">Guardar</v-btn>
          <v-snackbar v-model="snackbar_save" :timeout="timeout">
            {{ text_save }}
            <template v-slot:action="{ attrs }">
              <v-btn color="teal" text v-bind="attrs" @click="snackbar_save = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <v-divider class="mt-n2"></v-divider>
        <v-form ref="form2" lazy-validation :class="$vuetify.breakpoint.mobile ? 'ma-7' : 'ma-12'">
          <v-row align="center">
            <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="2">
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field prepend-inner-icon="mdi-account-key-outline" required label="Nueva contraseña"
                class="rounded-xl" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_password ? 'text' : 'password'" outlined @click:append="show_password = !show_password"
                v-model="new_password" @input="updatePasswordStrength" autocomplete="new-password"
                :rules="NewPasswordRules"></v-text-field>
              <v-text-field required :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'" outlined
                class="rounded-xl" label="Repetir nueva contraseña" prepend-inner-icon="mdi-shield-lock-outline"
                :type="show_new_password ? 'text' : 'password'" @click:append="show_new_password = !show_new_password"
                v-model="new_password_repeat" autocomplete="new-password"
                :rules="RepeatNewPassRules.concat(validateNewPassword)">
              </v-text-field>
              <v-progress-linear :value="progress" :color="color_progress" absolute rounded height="6"
                :class="{ 'custom-progress-mobile': $vuetify.breakpoint.mobile }"
                class="mb-5 custom-progress"></v-progress-linear>
            </v-col>
          </v-row>
        </v-form>
        <v-list-item-subtitle class="d-flex justify-end align-end">
          <v-btn color="success" style="text-transform: none;" dark large @click="updateUserPassword" rounded
            class="mb-2">Actualizar contraseña</v-btn>
          <v-snackbar center v-model="snackbar_password" :timeout="timeout">{{ text_update }} </v-snackbar>
        </v-list-item-subtitle>
      </div>
    </v-card>
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="mb-4">
      <v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CropperImage from './CropperImage.vue';
import bg_image_demo from "@/assets/bg_user_demo.png";
import demo_image from "@/assets/image_demo.png";
export default {
  components: {
    CropperImage,
  },
  data() {
    return {
      // Campos de text field
      nombre: 'ECO',
      email: 'demo@ecoes.eco',
      movil: '654321098',
      telefono: '654321098',
      primer_apellido: 'Engineering',
      segundo_apellido: 'Solutions',
      password: 'Password',
      new_password: '',
      new_password_repeat: '',
      // Diseños
      rotate: false,
      edit_mode: false,
      alert: true,
      show_help_alert: false,
      timeout: 3000,
      progress: 0,
      color_progress: 'error',
      snackbar_save: false,
      snackbar_password: false,
      loading_delete: false,
      delete_confirmation: false,
      edit_icon: 'mdi-pencil-outline',
      remove_icon: 'mdi-delete-outline',
      text_save: 'Guardado correctamente',
      text_update: 'La contraseña se ha actualizado correctamente',
      // Campo no visible
      show_password: false,
      show_new_password: false,
      //Imagen
      URL_avatar: null,
      image_to_croppe: null,
      image_dialog: false,
      cropper_dialog: false,
      is_image_selected: false,
      image_to_upload: null,
      image_limit: false,
      // Reglas que están en los campos obligatorios
      NameRules: [
        value_name => !!value_name || 'Este campo es obligatorio',
        value_name => /^[^\d]+$/.test(value_name) || 'Este campo no admite numeros',
        value_name => (value_name && value_name.length >= 3) || 'Min 3 caracteres',
      ],
      LastNameRules: [
        value_lastname => !!value_lastname || 'Este campo es obligatorio',
        value_lastname => (value_lastname && value_lastname.length >= 2) || 'Min 2 caracteres',
      ],
      MovilRules: [
        value_movil => !!value_movil || 'Este campo es obligatorio',
        value_movil => /^\d{9}$/.test(value_movil) || 'Debe tener exactamente 9 caracteres y ser solo números',
      ],
      EmailRules: [
        value_email => !!value_email || 'Este campo es obligatorio',
      ],
      NewPasswordRules: [
        value_newpass => !!value_newpass || 'Este campo es obligatorio',
        value_newpass => (value_newpass && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%#*?&]{6,}$/.test(value_newpass)) || 'Min 6 caracteres, mayuscula, numero y caracter especial (*, &, %, $, #, @, ?, !)',
      ],
      RepeatNewPassRules: [
        value_repeat_newpass => !!value_repeat_newpass || 'Este campo es obligatorio',
      ]
    };
  },

  watch: {
    URL_avatar(newVal, oldVal) {
      // Si la URL de la imagen cambia, se activa la rotación 
      if (newVal !== oldVal) {
        this.rotate = !this.rotate;
        if (this.rotate) {
          setTimeout(() => {
            this.rotate = false;
          }, 500);
        }
      }
    },
  },

  methods: {
    // Para cambiar el icono de borrado
    changeIcon(isHovered) {
      this.remove_icon = isHovered ? 'mdi-delete-empty-outline' : 'mdi-delete-outline';
    },
    changeIconEdit(isHovered) {
      this.edit_icon = isHovered ? 'mdi-progress-pencil' : 'mdi-pencil-outline';
    },
    //Para habilitar los text field
    toggleEditMode() {
      this.edit_mode = true;
    },
    // Logica para ver la primera letra en Mayusculas
    capitalizeFirstLetter() {
      if (this.nombre) {
        this.nombre = this.nombre.charAt(0).toUpperCase() + this.nombre.slice(1);
      }
      if (this.primer_apellido) {
        this.primer_apellido = this.primer_apellido.charAt(0).toUpperCase() + this.primer_apellido.slice(1);
      }
      if (this.segundo_apellido) {
        this.segundo_apellido = this.segundo_apellido.charAt(0).toUpperCase() + this.segundo_apellido.slice(1);
      }
    },
    // Actualizar la fortaleza de la contraseña mientras el usuario escribe
    updatePasswordStrength() {
      const lower_case_regex = /[a-z]/;
      const upper_case_regex = /[A-Z]/;
      const digit_regex = /\d/;
      const special_char_regex = /[@$!%*?#&]/;

      if (this.new_password) {
        // Evaluar cada condición y asignar un valor al progreso
        const conditions_pass = [
          lower_case_regex.test(this.new_password),
          upper_case_regex.test(this.new_password),
          digit_regex.test(this.new_password),
          special_char_regex.test(this.new_password),
        ];

        const fulfilled_conditions = conditions_pass.filter((condition) => condition).length;
        const length_conditions_pass = conditions_pass.length;

        // Calcular el progreso en función de las condiciones cumplidas
        this.progress = Math.min(100, (fulfilled_conditions / length_conditions_pass) * 100);

        // Determinar el color del indicador de progreso
        this.color_progress = ['error', 'warning', 'success'][Math.floor(this.progress / 40)];
      } else {
        this.progress = 0;
        this.color_progress = 'error';
      }
    },
    // Advertencia que las contraseñas deben coincidir
    validateNewPassword(value) {
      return value == this.new_password || "Las contraseñas deben coincidir"
    },
    // Lógica para que se cumplan las reglas del formulario de datos de usuario
    hasRulesFields() {
      // Se comprueba que se cumplan las reglas de los campos obligatorios
      if (this.nombre !== null && this.primer_apellido !== null && this.movil !== null && this.email !== '') {
        if (this.nombre.length >= 3 && this.primer_apellido.length >= 2) {
          return true;
        } else {
          return false;
        }
      }
    },
    // Para limpiar los textfield de contraseña
    resetFieldsPassword() {
      this.$refs.form2.reset();
    },
    // Para abrir el modal de confirmación de borrado
    openDeleteConfirmation() {
      this.delete_confirmation = true;
    },
    // Para cerrar el modal de confirmación de borrado
    closeDeleteConfirmation() {
      this.delete_confirmation = false;
    },
    // Para borrar la imagen
    deleteImage() {
      this.loading_delete = true;
      setTimeout(() => {
        this.URL_avatar = null;
        this.image_to_upload = null;
        this.is_image_selected = false;
        this.closeDeleteConfirmation();
        this.updateUserImage();
        this.loading_delete = false;
        this.closeCropperDialog();
        this.closeImageDialog();
      }, 3000);
    },
    // Lógica para actualizar la contraseña
    updateUserPassword() {
      if (this.new_password === this.new_password_repeat) {
        if (this.$refs.form2.validate()) {
          this.snackbar_password = true;
          if (this.snackbar_password === true) {
            this.resetFieldsPassword();
            this.progress = 0;
            this.color_progress = "error";
          }
        }
      }
    },
    // Lógica para actualizar la imagen
    updateUserImage() {
      // Si hay una imagen seleccionada, se actualiza la imagen
      if (this.is_image_selected) {
        this.URL_avatar = demo_image;
        this.image_to_upload = null;
        this.is_image_selected = false;
        this.updateUserInfo();
      } // Si no hay una imagen seleccionada, se elimina la imagen
      else {
        this.URL_avatar = null;
        this.updateUserInfo();
      }
    },

    // Logica para actualizar los datos del usuario
    updateUserInfo() {
      this.$root.$emit('nombre-actualizado', this.nombre);
      this.$root.$emit('apellido-actualizado', this.primer_apellido);
      this.$root.$emit('correo-actualizado', this.email);
      this.$root.$emit('avatar-updated', this.URL_avatar);
    },
    // Lógica para obtener los datos del usuario
    getUserInfo() {
      this.nombre = "ECO";
      this.primer_apellido = "Engineering";
      this.segundo_apellido = "Solutions";
      this.email = "demo@ecoes.eco";
      this.movil = "654321098";
      this.telefono = "654321098";
      this.URL_avatar = bg_image_demo;
    },
    // Lógica para guardar los cambios
    saveFormChanges() {
      if (this.$refs.form.validate()) {
        this.updateUserInfo();
        this.snackbar_save = true;

        // Se hace un timeout para simular el tiempo de carga
        setTimeout(() => {
          this.edit_mode = false;
          // Muestra los nuevos datos en el formulario
          // this.getUserInfo();
        }, 1000);

      }
    },
    // Logica para acceder a un archivo de formato imagen y aparezca el boton de editar perfil
    handleEditButtonAndImageFile() {
      // Primero se verifica si el usuario ya tiene una imagen, si URL_avatar es null, se muestra el boton de editar y se abre el explorador de archivos
      if (this.URL_avatar === null || this.URL_avatar === '' || this.URL_avatar === undefined) {
        this.$refs.fileInput.click();
      } else {
        // Si el usuario ya tiene una imagen, se muestra el modal de recorte con la imagen que ya tiene
        this.image_to_croppe = this.URL_avatar;
        this.openImageDialog();
      }
    },
    // Logica para acceder a un archivo de formato imagen 
    handleImageFile() {
      this.closeCropperDialog();
      this.closeImageDialog();
      this.$refs.fileInput.click();
    },
    // Logica para el cambio de imagen
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      this.$refs.fileInput.value = '';
      this.manageImageSelectionSize(selectedFile);
    },
    // Método para verificar el tamaño de la imagen
    manageImageSelectionSize(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Verificar si las dimensiones superan el límite
          if (img.width >= 256 || img.height >= 256) {
            this.image_to_croppe = e.target.result;
            this.is_image_selected = true;
            this.openCropperDialog();
            // Comprobamos que el tamño sea menor a 1MB
          } else if (file.size > 1024 * 1024) {
            this.is_image_selected = false;
            // TODO: Aqui tenemos dos opciones. O decirle al usuario que la imagen es muy pesada. O nosotros reescalar la imagen para hacerla mas pequeña
            this.image_limit = true;
          } else {
            this.image_to_upload = e.target.result;
            this.is_image_selected = true;
            this.updateUserImage();
          }
        };
        img.src = e.target.result;
      };
      // Leemos el archivo
      reader.readAsDataURL(file);
    },
    // Método para abrir el modal de imagen
    openImageDialog() {
      this.image_dialog = true;
    },
    // Método para cerrar el modal de imagen
    closeImageDialog() {
      this.image_dialog = false;
    },
    // Método para abrir el modal de recorte
    openCropperDialog() {
      this.cropper_dialog = true;
    },
    // Método para cerrar el modal de recorte
    closeCropperDialog() {
      this.cropper_dialog = false;
    },
    // Método para cerrar el modal de imagen supera el limite
    closeDialogLimit() {
      this.image_limit = false;
    },
    // Método para recibir la imagen recortada del componente CropperImage
    handleAndSaveCroppedImage(croppedImage) {
      this.image_to_upload = croppedImage;
      this.updateUserImage();
      this.closeCropperDialog();
    },
  },

  computed: {
    avatar() {
      const nombre = localStorage.getItem("nombre");
      const primer_apellido = localStorage.getItem("apellido");
      return nombre[0] + primer_apellido[0];
    },
    greetingMessage() {
      const currentHour = new Date().getHours();

      if (currentHour >= 4 && currentHour < 12) {
        return `Buenos días, ${this.nombre}!`;
      } else if (currentHour >= 12 && currentHour < 21) {
        return `Buenas tardes, ${this.nombre}!`;
      } else {
        return `Buenas noches, ${this.nombre}!`;
      }
    },
  },

  mounted: function () {
    this.getUserInfo();
  }
}
</script>

<style scoped>
.rotate-360 {
  transition: transform 0.5s ease-in-out;
  transform: rotate(360deg);
}

.custom-progress {
  width: 25%;
  margin-left: 21%;
}

.custom-progress-mobile {
  width: 70%;
  margin-left: 16%;
}

.card-bg {
  position: relative;
  background-image: url(../assets/bg_user.png);
  background-size: cover;
}

.teal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(19, 90, 76, 0.689);
  border-radius: inherit;
  z-index: 1;
}
</style>