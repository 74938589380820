function getContadores() {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        return contadoresData
    } else {
        return contadoresDos
    }
}

const contadoresDos = [
    {
        "id": 2,
        "nombre": "Contador Ista",
        "n_pulsos": 9348,
        "caudal": 0,
        "fecha_registro": "2024-01-08T11:41:08.000Z",
        "latitud": 39.869985,
        "longitud": -1.355760,
        "id_remote": "abcd06",
        "nombre_remote": "Remote Lite Maiz",
        "battery_level": 4020,
        "battery_timestamp": "2024-01-08 00:01:40",
        "real_value": 74.2,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                9095, 9095, 9100, 9100, 9105, 9105, 9105, 9110, 9110, 9110, 9110, 9115, 9115, 9115, 9115, 9120,
                9120, 9120, 9120, 9120, 9130, 9130, 9130, 9130, 9130, 9130, 9130, 9150, 9150, 9150, 9150, 9150,
                9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9180,
                9180, 9180, 9180, 9180, 9200, 9200, 9200, 9200, 9200, 9205, 9205, 9205, 9210, 9210,
                9210, 9210, 9210, 9215, 9215, 9215, 9215, 9215, 9215, 9215, 9215, 9220,
                9220, 9220, 9220, 9220, 9220, 9220, 9220, 9220, 9220, 9228, 9228, 9228,
                9228, 9228, 9230, 9230, 9230, 9230, 9230, 9232, 9232, 9232, 9232, 9232,
                9236, 9236, 9236, 9236, 9236, 9236, 9236, 9236, 9240, 9240, 9240, 9240,
                9245, 9245, 9245, 9245, 9245, 9245, 9245, 9250, 9250, 9250, 9250, 9254, 9254, 9256, 9256,
                9258, 9258, 9260, 9260, 9265, 9270, 9270, 9270, 9270, 9275, 9275, 9275, 9280, 9280,
                9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300,
                9300, 9300, 9300, 9300, 9300, 9310, 9330, 9335, 9338, 9340, 9342, 9345,
                9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348,
                9348, 9348, 9348],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    },
    {
        "id": 5,
        "nombre": "Contador Almendros",
        "n_pulsos": 9958,
        "caudal": 0,
        "fecha_registro": "2024-04-11T11:41:08.000Z",
        "latitud": 39.864658,
        "longitud": -1.357314,
        "id_remote": "011B06",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
        "real_value": 11.4,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300,
                9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320,
                9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360,
                9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380,
                9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9400,
                9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420,
                9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420,
                9420, 9440, 9460, 9480, 9500, 9520, 9540, 9560, 9580, 9600, 9620, 9640,
                9420, 9440, 9460, 9480, 9500, 9520, 9540, 9560, 9580, 9600, 9620, 9640,
                9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9642, 9645, 9646,
                9650, 9690, 9710, 9740, 9770, 9800, 9830, 9860, 9870, 9881, 9897, 9900, 9900, 9900,
                9902, 9905, 9908, 9902, 9900, 9905, 9908, 9912, 9915, 9918, 9920, 9922,
                9925, 9927, 9930, 9932, 9935, 9938, 9942, 9940, 9942, 9944, 9947, 9950,
                9952, 9955, 9958],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    }
]

const contadoresData = [
    {
        "id": 2,
        "nombre": "Contador Ista",
        "n_pulsos": 9348,
        "caudal": 0,
        "fecha_registro": "2024-01-08T11:41:08.000Z",
        "latitud": 39.869985,
        "longitud": -1.355760,
        "id_remote": "abcd06",
        "nombre_remote": "Remote Lite Maiz",
        "battery_level": 4020,
        "battery_timestamp": "2024-01-08 00:01:40",
        "real_value": 74.2,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                9095, 9095, 9100, 9100, 9105, 9105, 9105, 9110, 9110, 9110, 9110, 9115, 9115, 9115, 9115, 9120,
                9120, 9120, 9120, 9120, 9130, 9130, 9130, 9130, 9130, 9130, 9130, 9150, 9150, 9150, 9150, 9150,
                9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9160, 9180,
                9180, 9180, 9180, 9180, 9200, 9200, 9200, 9200, 9200, 9205, 9205, 9205, 9210, 9210,
                9210, 9210, 9210, 9215, 9215, 9215, 9215, 9215, 9215, 9215, 9215, 9220,
                9220, 9220, 9220, 9220, 9220, 9220, 9220, 9220, 9220, 9228, 9228, 9228,
                9228, 9228, 9230, 9230, 9230, 9230, 9230, 9232, 9232, 9232, 9232, 9232,
                9236, 9236, 9236, 9236, 9236, 9236, 9236, 9236, 9240, 9240, 9240, 9240,
                9245, 9245, 9245, 9245, 9245, 9245, 9245, 9250, 9250, 9250, 9250, 9254, 9254, 9256, 9256,
                9258, 9258, 9260, 9260, 9265, 9270, 9270, 9270, 9270, 9275, 9275, 9275, 9280, 9280,
                9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300,
                9300, 9300, 9300, 9300, 9300, 9310, 9330, 9335, 9338, 9340, 9342, 9345,
                9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348, 9348,
                9348, 9348, 9348],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    },
    {
        "id": 3,
        "nombre": "Contador Maiz",
        "n_pulsos": 9958,
        "caudal": 0,
        "fecha_registro": "2024-02-17T11:41:08.000Z",
        "latitud": 39.868930,
        "longitud": -1.355572,
        "id_remote": "abcd06",
        "nombre_remote": "Remote Lite Maiz",
        "battery_level": 4020,
        "battery_timestamp": "2024-01-08 00:01:40",
        "real_value": 80.25,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300,
                9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320,
                9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360,
                9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380,
                9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9400,
                9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420,
                9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420,
                9420, 9440, 9460, 9480, 9500, 9520, 9540, 9560, 9580, 9600, 9620, 9640,
                9420, 9440, 9460, 9480, 9500, 9520, 9540, 9560, 9580, 9600, 9620, 9640,
                9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9642, 9645, 9646,
                9650, 9690, 9710, 9740, 9770, 9800, 9830, 9860, 9870, 9881, 9897, 9900, 9900, 9900,
                9902, 9905, 9908, 9902, 9900, 9905, 9908, 9912, 9915, 9918, 9920, 9922,
                9925, 9927, 9930, 9932, 9935, 9938, 9942, 9940, 9942, 9944, 9947, 9950,
                9952, 9955, 9958],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    },
    {
        "id": 4,
        "nombre": "Contador Olivo",
        "n_pulsos": 10743,
        "caudal": 0,
        "fecha_registro": "2024-01-09T11:41:08.000Z",
        "latitud": 39.868091,
        "longitud": -1.354032,
        "id_remote": "01dd06",
        "nombre_remote": "Remote Lite Olivo",
        "battery_level": 4012,
        "battery_timestamp": "2023-09-29 10:08:25",
        "real_value": 36.07,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                10400, 10405, 10407, 10405, 10400, 10405, 10410, 10415, 10416, 10418, 10420, 10425, 10428, 10430, 10432, 10435,
                10400, 10405, 10407, 10405, 10400, 10405, 10410, 10415, 10416, 10418, 10420, 10425, 10428, 10430, 10432, 10435,
                10400, 10405, 10407, 10405, 10400, 10405, 10410, 10415, 10416, 10418, 10420, 10425, 10428, 10430, 10432, 10435,
                10407, 10405, 10400, 10405, 10410, 10415, 10416, 10418, 10420, 10425, 10428, 10430, 10432, 10435,
                10430, 10435, 10436, 10438, 10440, 10442, 10445, 10446, 10448, 10452, 10450, 10455, 10456, 10460,
                10430, 10435, 10436, 10438, 10440, 10442, 10445, 10446, 10448, 10452, 10450, 10455, 10456, 10460,
                10458, 10460, 10462, 10466, 10465, 10468, 10470, 10472, 10475, 10476, 10478, 10480, 10482, 10486,
                10488, 10485, 10490, 10492, 10495, 10498, 10500, 10503, 10506, 10508, 10512, 10510, 10510, 10512,
                10515, 10518, 10520, 10530, 10535, 10538, 10540, 10542, 10546, 10548, 10550, 10552,
                10488, 10485, 10490, 10492, 10495, 10498, 10500, 10503, 10506, 10508, 10512,
                10550, 10546, 10548, 10550, 10554, 10558, 10562, 10560, 10565, 10568, 10570, 10575,
                10576, 10580, 10585, 10587, 10593, 10597, 10595, 10590, 10600, 10615, 10610, 10605,
                10608, 10605, 10610, 10625, 10620, 10615, 10610, 10620, 10630, 10630, 10640, 10680,
                10725, 10743],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    }
    ,
    {
        "id": 5,
        "nombre": "Contador Almendros",
        "n_pulsos": 9958,
        "caudal": 0,
        "fecha_registro": "2024-01-10T11:41:08.000Z",
        "latitud": 39.864658,
        "longitud": -1.357314,
        "id_remote": "011B06",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
        "real_value": 11.4,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300, 9300,
                9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320, 9320,
                9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360, 9360,
                9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380,
                9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9380, 9400,
                9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420,
                9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420, 9420,
                9420, 9440, 9460, 9480, 9500, 9520, 9540, 9560, 9580, 9600, 9620, 9640,
                9420, 9440, 9460, 9480, 9500, 9520, 9540, 9560, 9580, 9600, 9620, 9640,
                9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9640, 9642, 9645, 9646,
                9650, 9690, 9710, 9740, 9770, 9800, 9830, 9860, 9870, 9881, 9897, 9900, 9900, 9900,
                9902, 9905, 9908, 9902, 9900, 9905, 9908, 9912, 9915, 9918, 9920, 9922,
                9925, 9927, 9930, 9932, 9935, 9938, 9942, 9940, 9942, 9944, 9947, 9950,
                9952, 9955, 9958],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    },
    {
        "id": 6,
        "nombre": "Contador",
        "n_pulsos": 9760,
        "caudal": 0,
        "fecha_registro": "2024-01-10T11:41:08.000Z",
        "latitud": 39.865042,
        "longitud": -1.356206,
        "id_remote": "011B06",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
        "real_value": 11.4,
        "historico": {
            "fecha_registro": [
                "2024-01-02 00:00:00",
                "2024-01-02 01:00:00",
                "2024-01-02 02:00:00",
                "2024-01-02 03:00:00",
                "2024-01-02 04:00:00",
                "2024-01-02 05:00:00",
                "2024-01-02 06:00:00",
                "2024-01-02 07:00:00",
                "2024-01-02 08:00:00",
                "2024-01-02 09:00:00",
                "2024-01-02 10:00:00",
                "2024-01-02 11:00:00",
                "2024-01-02 12:00:00",
                "2024-01-02 13:00:00",
                "2024-01-02 14:00:00",
                "2024-01-02 15:00:00",
                "2024-01-02 16:00:00",
                "2024-01-02 17:00:00",
                "2024-01-02 18:00:00",
                "2024-01-02 19:00:00",
                "2024-01-02 20:00:00",
                "2024-01-02 21:00:00",
                "2024-01-02 22:00:00",
                "2024-01-02 23:00:00",
                "2024-01-03 00:00:00",
                "2024-01-03 01:00:00",
                "2024-01-03 02:00:00",
                "2024-01-03 03:00:00",
                "2024-01-03 04:00:00",
                "2024-01-03 05:00:00",
                "2024-01-03 06:00:00",
                "2024-01-03 07:00:00",
                "2024-01-03 08:00:00",
                "2024-01-03 09:00:00",
                "2024-01-03 10:00:00",
                "2024-01-03 11:00:00",
                "2024-01-03 12:00:00",
                "2024-01-03 13:00:00",
                "2024-01-03 14:00:00",
                "2024-01-03 15:00:00",
                "2024-01-03 16:00:00",
                "2024-01-03 17:00:00",
                "2024-01-03 18:00:00",
                "2024-01-03 19:00:00",
                "2024-01-03 20:00:00",
                "2024-01-03 21:00:00",
                "2024-01-03 22:00:00",
                "2024-01-03 23:00:00",
                "2024-01-04 00:00:00",
                "2024-01-04 01:00:00",
                "2024-01-04 02:00:00",
                "2024-01-04 03:00:00",
                "2024-01-04 04:00:00",
                "2024-01-04 05:00:00",
                "2024-01-04 06:00:00",
                "2024-01-04 07:00:00",
                "2024-01-04 08:00:00",
                "2024-01-04 09:00:00",
                "2024-01-04 10:00:00",
                "2024-01-04 11:00:00",
                "2024-01-04 12:00:00",
                "2024-01-04 13:00:00",
                "2024-01-04 14:00:00",
                "2024-01-04 15:00:00",
                "2024-01-04 16:00:00",
                "2024-01-04 17:00:00",
                "2024-01-04 18:00:00",
                "2024-01-04 19:00:00",
                "2024-01-04 20:00:00",
                "2024-01-04 21:00:00",
                "2024-01-04 22:00:00",
                "2024-01-04 23:00:00",
                "2024-01-05 00:00:00",
                "2024-01-05 01:00:00",
                "2024-01-05 02:00:00",
                "2024-01-05 03:00:00",
                "2024-01-05 04:00:00",
                "2024-01-05 05:00:00",
                "2024-01-05 06:00:00",
                "2024-01-05 07:00:00",
                "2024-01-05 08:00:00",
                "2024-01-05 09:00:00",
                "2024-01-05 10:00:00",
                "2024-01-05 11:00:00",
                "2024-01-05 12:00:00",
                "2024-01-05 13:00:00",
                "2024-01-05 14:00:00",
                "2024-01-05 15:00:00",
                "2024-01-05 16:00:00",
                "2024-01-05 17:00:00",
                "2024-01-05 18:00:00",
                "2024-01-05 19:00:00",
                "2024-01-05 20:00:00",
                "2024-01-05 21:00:00",
                "2024-01-05 22:00:00",
                "2024-01-05 23:00:00",
                "2024-01-06 00:00:00",
                "2024-01-06 01:00:00",
                "2024-01-06 02:00:00",
                "2024-01-06 03:00:00",
                "2024-01-06 04:00:00",
                "2024-01-06 05:00:00",
                "2024-01-06 06:00:00",
                "2024-01-06 07:00:00",
                "2024-01-06 08:00:00",
                "2024-01-06 09:00:00",
                "2024-01-06 10:00:00",
                "2024-01-06 11:00:00",
                "2024-01-06 12:00:00",
                "2024-01-06 13:00:00",
                "2024-01-06 14:00:00",
                "2024-01-06 15:00:00",
                "2024-01-06 16:00:00",
                "2024-01-06 17:00:00",
                "2024-01-06 18:00:00",
                "2024-01-06 19:00:00",
                "2024-01-06 20:00:00",
                "2024-01-06 21:00:00",
                "2024-01-06 22:00:00",
                "2024-01-06 23:00:00",
                "2024-01-07 00:00:00",
                "2024-01-07 01:00:00",
                "2024-01-07 02:00:00",
                "2024-01-07 03:00:00",
                "2024-01-07 04:00:00",
                "2024-01-07 05:00:00",
                "2024-01-07 06:00:00",
                "2024-01-07 07:00:00",
                "2024-01-07 08:00:00",
                "2024-01-07 09:00:00",
                "2024-01-07 10:00:00",
                "2024-01-07 11:00:00",
                "2024-01-07 12:00:00",
                "2024-01-07 13:00:00",
                "2024-01-07 14:00:00",
                "2024-01-07 15:00:00",
                "2024-01-07 16:00:00",
                "2024-01-07 17:00:00",
                "2024-01-07 18:00:00",
                "2024-01-07 19:00:00",
                "2024-01-07 20:00:00",
                "2024-01-07 21:00:00",
                "2024-01-07 22:00:00",
                "2024-01-07 23:00:00",
                "2024-01-08 00:00:00",
                "2024-01-08 01:00:00",
                "2024-01-08 02:00:00",
                "2024-01-08 03:00:00",
                "2024-01-08 04:00:00",
                "2024-01-08 05:00:00",
                "2024-01-08 06:00:00",
                "2024-01-08 07:00:00",
                "2024-01-08 08:00:00",
                "2024-01-08 09:00:00",
                "2024-01-08 10:00:00",
                "2024-01-08 11:00:00",
                "2024-01-08 12:00:00",
                "2024-01-08 13:00:00",
                "2024-01-08 14:00:00",
                "2024-01-08 15:00:00",
                "2024-01-08 16:00:00",
                "2024-01-08 17:00:00",
                "2024-01-08 18:00:00",
                "2024-01-08 19:00:00",
                "2024-01-08 20:00:00",
                "2024-01-08 21:00:00",
                "2024-01-08 22:00:00",
                "2024-01-08 23:00:00",
                "2024-01-09 00:00:00",
                "2024-01-09 01:00:00",
                "2024-01-09 02:00:00",
                "2024-01-09 03:00:00",
                "2024-01-09 04:00:00",
                "2024-01-09 05:00:00",
                "2024-01-09 06:00:00",
                "2024-01-09 07:00:00",
                "2024-01-09 08:00:00",
                "2024-01-09 09:00:00",
                "2024-01-09 10:00:00",
                "2024-01-09 11:00:00"
            ],
            "caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
                10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9,
                13.1, 13.3, 13.5, 13.7, 13.9],
            "n_pulsos": [
                8981, 8982, 8983, 8984, 8985, 8986, 8987, 8988, 8989, 8990, 8991, 8992, 8993, 8994, 8995, 8996,
                8981, 8982, 8983, 8984, 8985, 8986, 8987, 8988, 8989, 8990, 8991, 8992, 8993, 8994, 8995, 8996,
                8990, 8990, 8990, 8990, 8990, 8990, 8990, 8990, 9000, 9002, 9005, 9010, 9015, 9020,
                8990, 8990, 8990, 8990, 8990, 8990, 8990, 8990, 9000, 9002, 9005, 9010, 9015, 9020,
                8990, 8990, 8990, 8990, 8990, 8990, 8990, 8990, 9000, 9002, 9005, 9010, 9015, 9020,
                8990, 8990, 8990, 8990, 8990, 8990, 8990, 8990, 9000, 9002, 9005, 9010, 9015, 9020,
                8990, 8990, 8990, 8990, 8990, 8990, 8990, 8990, 9000, 9002, 9005, 9010, 9015, 9020,
                9027, 9035, 9042, 9055, 9045, 9035, 9025, 9030, 9050, 9080, 9100, 9150, 9180, 9200,
                9010, 9015, 9022, 9035, 9045, 9035, 9025, 9030, 9050, 9080, 9100, 9150, 9180, 9200,
                9310, 9320, 9350, 9380, 9400,
                9280, 9300, 9310, 9320, 9350, 9380, 9400, 9420, 9440, 9480, 9500, 9514,
                9520, 9540, 9560, 9580, 9600, 9610, 9620, 9650, 9700, 9710, 9720, 9724,
                9731, 9738, 9745, 9740, 9755, 9760, 9762, 9760, 9756, 9755, 9756, 9758,
                9731, 9738, 9745, 9740, 9755, 9760, 9762, 9760, 9756, 9755, 9756, 9758,
                9755, 9760],
            "intervalo_caudal": [
                0.8, 0.9, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9, 0.8, 0.7, 0.6, 0.5, 0.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9,
                4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3,
                7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1, 10.3, 10.5,
                10.7, 10.9, 11.1, 11.3, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5,
                13.7, 13.9]
        }
    },
]


module.exports = { contadoresData, getContadores }