<template>
  <v-app-bar color="rgb(57, 109, 98)" dark app clipped-left src="../assets/bg.png">
    <v-app-bar-nav-icon @click="changeMini" v-if="!$vuetify.breakpoint.mobile">
    </v-app-bar-nav-icon>
    <v-img src="../assets/logo.png" height="40" max-width="80"></v-img>
    <v-toolbar-title class="ml-5 d-none d-sm-block kozuka-b">Smart<span class="kozuka-l">Water</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-col md="2" cols="6">
      <v-select v-if="multiFinca" :items="fincas" :value="fincaSelected" item-text="nombre" item-value="dispositivos"
        dense outlined flat class="mt-6 pb-0" @change="changeFinca($event)">
      </v-select>
      <v-container v-else class="d-flex justify-end">
        {{ fincas[0].nombre }}
      </v-container>
    </v-col>
    <v-menu v-model="menu" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab text v-bind="attrs" v-on="on" :class="$vuetify.breakpoint.mobile ? 'mr-0' : 'mr-4'">
          <v-avatar color="teal" size="50" style="cursor: pointer">
            <img v-if="avatar_image" :src="avatar_image" alt="Imagen seleccionada" />
            <span v-else>{{ avatar }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <!-- Contenido del menú -->
      <v-card @mouseleave="cerrarMenu">
        <div class="d-flex justify-end pa-2">
          <v-btn text fab x-small left @click="editar">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center justify-center">
          <div class="d-flex flex-column align-center">
            <v-avatar color="teal" size="85" style="cursor: pointer" @click="editar">
              <img v-if="avatar_image" :src="avatar_image" alt="Imagen seleccionada" />
              <span v-else style="color:white; font-size: 2rem;">{{ avatar }}</span>
            </v-avatar>
            <span class="mt-2">{{ nombre }}</span>
            <span>{{ email }}</span>
          </div>
        </div>
        <v-divider class="ma-3"></v-divider>
        <div class="d-flex justify-space-around pb-3">
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-btn color="red darken-2" fab dark small style="cursor: pointer" @click="logout">
                  <v-icon dark>mdi-exit-to-app</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-subtitle>Cerrar sesión</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import bg_image_demo from "@/assets/bg_user_demo.png";
export default {
  name: "AppBar",
  data() {
    return {
      status: false,
      battery: 10,
      hover: false,
      menu: false,
      nombre: 'ECO',
      apellido: 'Engeenering',
      email: 'demo@ecoes.eco',
      avatar_image: null,
      fincas: JSON.parse(localStorage.getItem("fincas")),
      token: localStorage.getItem("token"),
    };
  },
  methods: {
    changeMini: function () {
      this.$emit("changeMini");
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    changeFinca(dispositivosFinca){
      this.$emit("changeFinca",dispositivosFinca)
    },
    editar() {
     // Verifica si la ruta actual es diferente de "/Usuario" para redireccionar
     if (this.$route.path !== "/Usuario") {
        this.$router.push("/Usuario");
        this.menu = false;
      } // Si la ruta actual es "/Usuario" vuelve a cargar la página
      else {
        this.$router.go();
      }
    },
    cerrarMenu() {
      this.menu = false;
    },
    handleAvatarUpdated(url_avatar) {
      this.avatar_image = url_avatar;
    },
    handleNombreActualizado(nuevoNombre) {
      this.nombre = nuevoNombre;
    },
    handleCorreoActualizado(nuevoCorreo) {
      this.email = nuevoCorreo;
    },
    handleApellidoActualizado(nuevoApellido) {
      this.apellido = nuevoApellido;
    },
    // Logica para obtener datos del usuario
    getUserInfo() {
      this.name_user = "ECO";
      this.lastname_user = "Engeneering";
      this.email = "demo@ecoes.eco";
      this.avatar_image = bg_image_demo;
    },
    checkToken() {
      if (localStorage.getItem("token") !== null || undefined) {
        if (this.$router.currentRoute.name === "Login") {
          this.$router.push("/Panel%20de%20control");
        }
      } else {
        if (this.$router.currentRoute.name != "Login") {
          this.$router.push("/login");
        }
      }
    },
  },

  computed: {
    avatar() {
      const nombre = localStorage.getItem("nombre");
      const apellido = localStorage.getItem("apellido");
      return nombre[0] + apellido[0];
    },
    fincaSelected() {
      const dispositivos = localStorage.getItem("dispositivos");
      return this.fincas.find(finca => (finca.dispositivos).replace(" ", "") === dispositivos)
    },
    multiFinca() {
      if (this.fincas != null) {
        if (this.fincas.length > 1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  mounted() {
    this.checkToken();
    setTimeout(() => {
      console.log("Checking token");
      this.checkToken();
    }, 5000);

    this.getUserInfo();
    this.$root.$on('avatar-updated', this.handleAvatarUpdated);
    this.$root.$on('nombre-actualizado', this.handleNombreActualizado);
    this.$root.$on('apellido-actualizado', this.handleApellidoActualizado);
    this.$root.$on('correo-actualizado', this.handleCorreoActualizado);
    this.fincaSelected = this.fincas[0].id;
  },
};
</script>

<style>
@import url(../styles/fonts.css);
</style>