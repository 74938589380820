function updateVariador(parametro, variador, valor){
    const arr = ['estado_variador','estado_inyector','estado_agitador'];
    const indexVariador = variadoresData.findIndex((vari)=>vari.id === variador);
    const param = variadoresData[indexVariador].parametros.findIndex((param)=>param.id === parametro)
    variadoresData[indexVariador].parametros[param].valor_actual = valor;
    if(arr.includes(variadoresData[indexVariador].parametros[param].param) === true ){
        if(valor == 0){
            disminuirFrecuencia(indexVariador)
        }else{
            aumentarFrecuencia(indexVariador)
        }
    }
}
//TODO: implementar un metodo para que no se solapen los 2 interval
function aumentarFrecuencia(index_variador){
    const variador = variadoresData[index_variador];
    const param = variador.parametros.findIndex((prm)=>prm.param === 'frecuencia_salida');
    let value = 0;
    const interval = setInterval(()=>{
        let num = Math.random() * (5.6 - 1.1) + 1.1
        if(variador.parametros[param].valor_actual >= 48){
            clearInterval(interval)
            return variador.parametros[param].valor_actual = 50;
        }
        if(value > variador.parametros[param].valor_actual){
            return clearInterval(interval)
        }
        else{
            if(variador.parametros[param].valor_actual + num > 50){
                variador.parametros[param].valor_actual = 50;
                return clearInterval(interval)
            }else{
                value = variador.parametros[param].valor_actual += parseFloat(num.toFixed(2)); 
                variador.parametros[param].valor_actual += parseFloat(num.toFixed(2)); 
            }
        }
    },500)
}

function disminuirFrecuencia(index_variador){
    const variador = variadoresData[index_variador];
    const param = variador.parametros.findIndex((prm)=>prm.param === 'frecuencia_salida');
    let value = 51;
    const interval = setInterval(()=>{
        let num = Math.random() * (5.6 - 1.1) + 1.1
        if(variador.parametros[param].valor_actual <= 2){
            clearInterval(interval)
            return variador.parametros[param].valor_actual = 0;
        }
        if(value < variador.parametros[param].valor_actual){
            return clearInterval(interval)
        }
        else{
            if(variador.parametros[param].valor_actual - num <= 1.8){
                variador.parametros[param].valor_actual = 0;
                return clearInterval(interval)
            }else{
                value = variador.parametros[param].valor_actual -= parseFloat(num.toFixed(2)); 
                variador.parametros[param].valor_actual -= parseFloat(num.toFixed(2)); 
            }
        }
    },500)
    }

function getVariadores(){
    if(localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482'){
        return variadoresData
    }else{
        return variadoresDos
    }

}

const variadoresDos = [ 
    {
        "id": "3",
        "nombre": "Variador Principal",
        "latitud": "0",
        "longitud": "0",
        "parametros": [
            {
                "id": "17",
                "icon": "mdi-power",
                "param": "estado_agitador",
                "descripcion": "Estado agitador",
                "tipo_valor": "off,on",
                "opciones_nombres": "Off,On",
                "unidades": "",
                "conversion": "1",
                "valor_actual": "4",
                "is_write": "1",
                "opcion_valores": "0,4"
            },
            {
                "id": "19",
                "icon": "mdi-autorenew mdi-spin",
                "param": "frecuencia_salida",
                "descripcion": "Frecuencia salida",
                "tipo_valor": null,
                "opciones_nombres": null,
                "unidades": "Hz",
                "conversion": "round($val/100, 2)",
                "valor_actual": 47,
                "is_write": "0",
                "opcion_valores": null
            }
        ]
    }
 ]

const variadoresData = [
    {
        "id": "1",
        "nombre": "Nave principal",
        "latitud": "0",
        "longitud": "0",
        "parametros": [
            {
                "id": "6",
                "icon": "mdi-power",
                "param": "estado_variador",
                "descripcion": "Estado variador",
                "tipo_valor": "encendido_dual",
                "opciones_nombres": "Off,Solar,Electrico",
                "unidades": "",
                "conversion": "1",
                "valor_actual": "3",
                "is_write": "1",
                "opcion_valores": "0,3,7"
            },
            {
                "id": "13",
                "icon": "mdi-arrow-expand-vertical",
                "param": "presion_maxima",
                "descripcion": "Presión máxima de trabajo",
                "tipo_valor": "slider",
                "opciones_nombres": "",
                "unidades": "bar",
                "conversion": "1",
                "valor_actual": "2.74",
                "is_write": "1",
                "opcion_valores": "0,5"
            },
            {
                "id": "14",
                "icon": "mdi-electric-switch-closed",
                "param": "manual_auto",
                "descripcion": "Manual/Auto",
                "tipo_valor": "off,on",
                "opciones_nombres": "Manual,Auto",
                "unidades": null,
                "conversion": "1",
                "valor_actual": "3",
                "is_write": "1",
                "opcion_valores": "3,5"
            },
            {
                "id": "15",
                "icon": "mdi-autorenew mdi-spin",
                "param": "frecuencia_salida",
                "descripcion": "Frecuencia salida",
                "tipo_valor": null,
                "opciones_nombres": null,
                "unidades": "Hz",
                "conversion": "round($val/100, 2)",
                "valor_actual": 50,
                "is_write": "0",
                "opcion_valores": null
            }
        ]
    },
    {
        "id": "2",
        "nombre": "Variador inyector",
        "latitud": "40.4521",
        "longitud": "-3.70278",
        "parametros": [
            {
                "id": "18",
                "icon": "mdi-power",
                "param": "estado_inyector",
                "descripcion": "Estado Inyector",
                "tipo_valor": "off,on",
                "opciones_nombres": "Off,On",
                "unidades": "",
                "conversion": "1",
                "valor_actual": "4",
                "is_write": "1",
                "opcion_valores": "0,4"
            },
            {
                "id": "20",
                "icon": "mdi-autorenew mdi-spin",
                "param": "frecuencia_salida",
                "descripcion": "Frecuencia salida",
                "tipo_valor": null,
                "opciones_nombres": null,
                "unidades": "Hz",
                "conversion": "round($val/100, 2)",
                "valor_actual": 49,
                "is_write": "0",
                "opcion_valores": null
            }
        ]
    },
    {
        "id": "3",
        "nombre": "Variador agitador",
        "latitud": "0",
        "longitud": "0",
        "parametros": [
            {
                "id": "17",
                "icon": "mdi-power",
                "param": "estado_agitador",
                "descripcion": "Estado agitador",
                "tipo_valor": "off,on",
                "opciones_nombres": "Off,On",
                "unidades": "",
                "conversion": "1",
                "valor_actual": "4",
                "is_write": "1",
                "opcion_valores": "0,4"
            },
            {
                "id": "19",
                "icon": "mdi-autorenew mdi-spin",
                "param": "frecuencia_salida",
                "descripcion": "Frecuencia salida",
                "tipo_valor": null,
                "opciones_nombres": null,
                "unidades": "Hz",
                "conversion": "round($val/100, 2)",
                "valor_actual": 47,
                "is_write": "0",
                "opcion_valores": null
            }
        ]
    }
]

module.exports = { variadoresData, updateVariador, getVariadores }