<template>
    <InformesComponent/>
  </template>
  
  <script>
  import InformesComponent from "@/components/InformesComponent.vue";
  export default {
    components: { InformesComponent },
    data() {
      return {
      };
    },
  };
  </script>
  
  <style>
  </style>