<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" style="text-transform: none;" rounded small color="teal lighten-5"
                elevation="0">
                <v-icon left color="blue-grey darken-1">
                    mdi-tune
                </v-icon>
                Filtrar
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="h6">Filtros</span>
                <v-spacer></v-spacer>
                <v-btn text @click="clearFilters" class="caption" style="color: red">Limpiar filtros</v-btn>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-autocomplete
                    outlined
                    dense
                    rounded
                    v-model="searchName"
                    :items="contadorNames"
                    item-text="name"
                    item-value="name"
                    prepend-inner-icon="mdi-magnify"
                    label="Contador"
                    single-line
                    hide-details
                    clearable
                >
                    <template v-slot:item="data">
                        <template v-if="data.item">
                            <v-list-item @click="selectItem(data.item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-counter</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </template>
                </v-autocomplete>
                <v-expansion-panels class="mt-5" elevation="0">
                    <v-expansion-panel>
                        <v-expansion-panel-header>Por pulsaciones</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-radio-group v-model="selectedPulsos">
                                <v-radio label="Igual a 0 pulsos" value="0"></v-radio>
                                <v-radio label="Menos de 500 pulsos" value="500"></v-radio>
                                <v-radio label="Más de 1000 pulsos" value="1000"></v-radio>
                                <v-radio label="Más de 5000 pulsos" value="5000"></v-radio>
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Por remote</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-select v-model="selectedRemote" :items="remoteOptions" rounded outlined dense
                                label="Remote"></v-select>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="applyFilters" small class="mb-2">
                    Aplicar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        contadores: Array
    },
    data() {
        return {
            dialog: false,
            searchName: '',
            selectedPulsos: null,
            selectedRemote: null,
            remoteOptions: [],
            contadorNames: []
        };
    },
    watch: {
        contadores: {
            immediate: true,
            handler(val) {
                this.remoteOptions = [...new Set(val.map(item => item.id_remote))];
                this.contadorNames = [...new Set(val.map(item => item.nombre))];
            }
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        clearFilters() {
            this.searchName = '';
            this.selectedPulsos = null;
            this.selectedRemote = null;
        },
        applyFilters() {
            this.$emit('apply-filters', {
                name: this.searchName,
                pulsos: this.selectedPulsos,
                remote: this.selectedRemote
            });
            this.closeDialog();
        },
        selectItem(item) {
            this.searchName = item;
        }
    }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}

.v-card {
    max-width: 500px;
}
</style>
