<template>
    <div>
        <v-card style="max-height: 60vh; overflow-y: auto;">
            <Cropper ref="cropper" :src="imageSrc" :zoomOnWheel="false" :aspectRatio="1" :viewMode="2" :dragMode="dragMode"
                :autoCropArea="0.8" @ready="onCropperReady" />
            <div class="d-flex flex-column align-center">
                <v-btn color="teal darken-4" large class=" ma-2 white--text"
                    style="font-size: smaller; text-transform: none;" @click="cropImage"> Guardar recorte
                    <v-icon right dark>mdi-scissors-cutting</v-icon>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'vue-cropperjs';
export default {
    components: {
        Cropper,
    },
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dragMode: 'crop',
        };
    },
    methods: {
        onCropperReady() {

        },
        cropImage() {
            // Obten la imagen recortada y emite un evento
            const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
            const croppedImage = croppedCanvas.toDataURL('image/png');
            const croppedImageBase64 = croppedImage.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

            // Emite el evento con la imagen recortada
            this.$emit('cropped', croppedImageBase64);
        },
    },
};
</script>

<style scoped></style>