function updateValvulaState({ id }, state) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        Promise.all(
            valvulasUno.map((valvula) => {
                if (valvula.id === id) {
                    valvula.current_state = state;
                }
            })
        )
    } else {
        Promise.all(
            valvulasDos.map((valvula) => {
                if (valvula.id === id) {
                    valvula.current_state = state;
                }
            })
        )
    }
}

function updateValvula(valvula) {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        valvulasUno.map((val) => {
            if (val.id === valvula.id) {
                val.nombre = valvula.nombre
                val.is_auto = valvula.is_auto
                val.current_state = valvula.current_state
                val.longitud = valvula.longitud
                val.latitud = valvula.latitud
            }
        })
    } else {
        valvulasDos.map((val) => {
            if (val.id === valvula.id) {
                val.nombre = valvula.nombre
                val.is_auto = valvula.is_auto
                val.current_state = valvula.current_state
                val.longitud = valvula.longitud
                val.latitud = valvula.latitud
            }
        })
    }
    return 1
}

function getValvulas() {
    if (localStorage.getItem('dispositivos') === 'B827EB7BE480,B827EB7BE482') {
        return valvulasUno
    } else {
        return valvulasDos
    }
}

let valvulasDos = [
    {
        "id": "17",
        "nombre": "17",
        "latitud": "39.8685",
        "longitud": "-1.35449",
        "is_auto": false,
        "ordered_state": false,
        "current_state": 100,
        "rs485_address": "0",
        "n_valvula": "1",
        //Datos del remote
        "id_remote": "011B06",
        "nombre_remote": "Remote Lite Almendros",
        "version": "1.1",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
    },
    {
        "id": "18",
        "nombre": "18",
        "latitud": "39.8690",
        "longitud": "-1.35430",
        "is_auto": false,
        "ordered_state": false,
        "current_state": 0,
        "rs485_address": "0",
        "n_valvula": "1",
        "version": "1.1",
        //Datos del remote
        "id_remote": "011B06",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
    },
    {
        "id": "19",
        "nombre": "19",
        "latitud": "39.8696",
        "longitud": "-1.35412",
        "is_auto": false,
        "ordered_state": false,
        "current_state": 0,
        "rs485_address": "0",
        "n_valvula": "1",
        //Datos del remote
        "id_remote": "011B06",
        "version": "1.1",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
    },
    {
        "id": "20",
        "nombre": "20",
        "latitud": "39.8686",
        "longitud": "-1.35408",
        "is_auto": false,
        "ordered_state": false,
        "current_state": 0,
        "rs485_address": "0",
        "n_valvula": "1",
        //Datos del remote
        "id_remote": "011B06",
        "version": "1.1",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "battery_timestamp": "2023-10-02 13:55:40",
    },
    {
        "id": "21",
        "nombre": "21",
        "latitud": "39.8692",
        "longitud": "-1.35385",
        "is_auto": false,
        "ordered_state": false,
        "current_state": 0,
        "rs485_address": "0",
        "n_valvula": "1",
        //Datos del remote
        "id_remote": "011B06",
        "nombre_remote": "Remote Lite Almendros",
        "battery_level": 3965,
        "version": "1.1",
        "battery_timestamp": "2023-10-02 13:55:40",
    },

]

let valvulasUno =
    [
        {
            "id": "1",
            "nombre": "1",
            "latitud": "39.8632",
            "longitud": "-1.35710",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "011B06",
            "version": "1.1",
            "nombre_remote": "Remote Lite Almendros",
            "battery_level": 3965,
            "battery_timestamp": "2023-10-02 13:55:40"
        },
        {
            "id": "7",
            "nombre": "7",
            "latitud": "39.8685",
            "longitud": "-1.35449",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "abcd06",
            "nombre_remote": "Remote Lite Maiz",
            "version": "1.1",
            "battery_level": 4020,
            "battery_timestamp": "2024-01-08 00:01:40",
        },
        {
            "id": "8",
            "nombre": "8",
            "latitud": "39.8690",
            "longitud": "-1.35430",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "abcd06",
            "nombre_remote": "Remote Lite Maiz",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2024-01-08 00:01:40",
        },
        {
            "id": "9",
            "nombre": "9",
            "latitud": "39.8696",
            "longitud": "-1.35412",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "abcd06",
            "nombre_remote": "Remote Lite Maiz",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2024-01-08 00:01:40",
        },
        {
            "id": "10",
            "nombre": "10",
            "latitud": "39.8686",
            "longitud": "-1.35408",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "abcd06",
            "nombre_remote": "Remote Lite Maiz",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2024-01-08 00:01:40",
        },
        {
            "id": "11",
            "nombre": "11",
            "latitud": "39.8692",
            "longitud": "-1.35385",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "abcd06",
            "nombre_remote": "Remote Lite Maiz",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2024-01-08 00:01:40",
        },
        {
            "id": "12",
            "nombre": "12",
            "latitud": "39.8685",
            "longitud": "-1.35364",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "abcd06",
            "nombre_remote": "Remote Lite Maiz",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2024-01-08 00:01:40",
        },
        {
            "id": "13",
            "nombre": "13",
            "latitud": "39.8704",
            "longitud": "-1.35858",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "010906",
            "nombre_remote": "Remote Lite Hortalizas",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2023-10-15 05:25:53"
        },
        {
            "id": "14",
            "nombre": "14",
            "latitud": "39.8709",
            "longitud": "-1.35839",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "010906",
            "nombre_remote": "Remote Lite Hortalizas",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2023-10-15 05:25:53"
        },
        {
            "id": "15",
            "nombre": "15",
            "latitud": "39.8704",
            "longitud": "-1.35775",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "010906",
            "nombre_remote": "Remote Lite Hortalizas",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2023-10-15 05:25:53"
        },
        {
            "id": "16",
            "nombre": "16",
            "latitud": "39.8708",
            "longitud": "-1.35765",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "010906",
            "nombre_remote": "Remote Lite Hortalizas",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2023-10-15 05:25:53"
        },
        {
            "id": "17",
            "nombre": "17",
            "latitud": "39.8704",
            "longitud": "-1.35708",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "010906",
            "nombre_remote": "Remote Lite Hortalizas",
            "battery_level": 4020,
            "version": "1.1",
            "battery_timestamp": "2023-10-15 05:25:53"
        },
        {
            "id": "18",
            "nombre": "18",
            "latitud": "39.8718",
            "longitud": "-1.35695",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "016b06",
            "nombre_remote": "Remote Lite Vid",
            "battery_level": -1,
            "version": "1.1",
            "battery_timestamp": "2023-09-01 00:35:27"
        },
        {
            "id": "19",
            "nombre": "19",
            "latitud": "39.8726",
            "longitud": "-1.35658",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "016b06",
            "nombre_remote": "Remote Lite Vid",
            "battery_level": -1, 
            "version": "1.1",
            "battery_timestamp": "2023-09-01 00:35:27"
        },
        {
            "id": "2",
            "nombre": "2",
            "latitud": "39.8640",
            "longitud": "-1.35732",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "011B06",
            "nombre_remote": "Remote Lite Almendros",
            "battery_level": 3965,
            "version": "1.1",
            "battery_timestamp": "2023-10-02 13:55:40"
        },
        {
            "id": "20",
            "nombre": "20",
            "latitud": "39.8735",
            "longitud": "-1.35618",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "016b06",
            "nombre_remote": "Remote Lite Vid",
            "battery_level": -1,
            "version": "1.1",
            "battery_timestamp": "2023-09-01 00:35:27"
        },
        {
            "id": "21",
            "nombre": "21",
            "latitud": "39.8718",
            "longitud": "-1.35620",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "016b06",
            "nombre_remote": "Remote Lite Vid",
            "battery_level": -1,
            "version": "1.1",
            "battery_timestamp": "2023-09-01 00:35:27"
        },
        {
            "id": "22",
            "nombre": "22",
            "latitud": "39.8728",
            "longitud": "-1.35576",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "016b06",
            "nombre_remote": "Remote Lite Vid",
            "battery_level": -1,
            "version": "1.1",
            "battery_timestamp": "2023-09-01 00:35:27"
        },
        {
            "id": "23",
            "nombre": "23",
            "latitud": "39.8719",
            "longitud": "-1.35545",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "016b06",
            "nombre_remote": "Remote Lite Vid",
            "battery_level": -1,
            "version": "1.1",
            "battery_timestamp": "2023-09-01 00:35:27"
        },
        {
            "id": "24",
            "nombre": "24",
            "latitud": "39.8668",
            "longitud": "-1.35530",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "version": "1.1",
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "25",
            "nombre": "25",
            "latitud": "39.8677",
            "longitud": "-1.35490",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "26",
            "nombre": "26",
            "latitud": "39.8658",
            "longitud": "-1.35452",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "version": "1.1",
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "27",
            "nombre": "27",
            "latitud": "39.8668",
            "longitud": "-1.35420",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "version": "1.1",
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "28",
            "nombre": "28",
            "latitud": "39.8676",
            "longitud": "-1.35382",
            "is_auto": false,
            "ordered_state": true,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "version": "1.1",
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "29",
            "nombre": "29",
            "latitud": "39.8660",
            "longitud": "-1.35320",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "version": "1.1",
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "3",
            "nombre": "3",
            "latitud": "39.8634",
            "longitud": "-1.35619",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "011B06",
            "nombre_remote": "Remote Lite Almendros",
            "battery_level": 3965,
            "version": "1.1",
            "battery_timestamp": "2023-10-02 13:55:40"
        },
        {
            "id": "30",
            "nombre": "30",
            "latitud": "39.8665",
            "longitud": "-1.35306",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 100,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "01dd06",
            "nombre_remote": "Remote Lite Olivo",
            "battery_level": 4012,
            "version": "1.1",
            "battery_timestamp": "2023-09-29 10:08:25"
        },
        {
            "id": "4",
            "nombre": "4",
            "latitud": "39.8641",
            "longitud": "-1.3565",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "011B06",
            "nombre_remote": "Remote Lite Almendros",
            "battery_level": 3965,
            "version": "1.1",
            "battery_timestamp": "2023-10-02 13:55:40"
        },
        {
            "id": "5",
            "nombre": "5",
            "latitud": "39.8635",
            "longitud": "-1.3556",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            //Datos del remote
            "id_remote": "011B06",
            "nombre_remote": "Remote Lite Almendros",
            "battery_level": 3965,
            "version": "1.1",
            "battery_timestamp": "2023-10-02 13:55:40"
        },
        {
            "id": "6",
            "nombre": "6",
            "latitud": "39.8642",
            "longitud": "-1.3559",
            "is_auto": false,
            "ordered_state": false,
            "current_state": 0,
            "rs485_address": "0",
            "n_valvula": "1",
            "version": "1.1",
            //Datos del remote
            "id_remote": "011B06",
            "nombre_remote": "Remote Lite Almendros",
            "battery_level": 3965,
            "battery_timestamp": "2023-10-02 13:55:40"
        },

]

module.exports = { updateValvulaState, updateValvula, getValvulas, valvulasUno }