<template>
    <v-card>
      <v-card-title>
        <span class="headline pa-2">Contadores</span>
        <v-spacer></v-spacer>
        <FilterMenu :contadores="contadores" @apply-filters="filterContadores" />
      </v-card-title>
      <v-card-subtitle v-if="filteredContadores.length">
        <div class="list-header primary--text" :class="$vuetify.breakpoint.smAndDown ? 'mt-2 mb-n2' : 'mt-2 mb-n2'">
          <span class="column">Nombre</span>
          <span class="column">Pulsos</span>
          <span class="column">Caudal</span>
          <span class="column">Fecha</span>
          <span class="column">Remote</span>
        </div>
      </v-card-subtitle>
      <v-divider v-if="filteredContadores.length"></v-divider>
      <div class="list-container" v-if="filteredContadores.length">
        <v-list dense>
          <v-list-item v-for="item in filteredContadores" :key="item.id" @click="selectContador(item)">
            <v-list-item-content class="list-item-content caption">
              <span class="column">{{ item.nombre }}</span>
              <span class="column">{{ item.n_pulsos }}</span>
              <span class="column">{{ item.caudal }}</span>
              <span class="column">{{ new Date(item.fecha_registro).toLocaleDateString() }}</span>
              <span class="column">{{ item.id_remote }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-card-text v-else>
        <div class="ml-2">
          <v-icon color="red darken-2">mdi-information</v-icon>
          <span>
            No se han encontrado contadores
          </span>
        </div>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import FilterMenu from "@/components/FilterMenu.vue";
  
  export default {
    components: {
      FilterMenu
    },
    props: ['contadores'],
    data() {
      return {
        headers: [
          { text: 'Nombre', value: 'nombre', class: "primary--text" },
          { text: 'Pulsos', value: 'n_pulsos', class: "primary--text" },
          { text: 'Caudal', value: 'caudal', class: "primary--text" },
          { text: 'Fecha', value: 'fecha_registro', class: "primary--text" },
          { text: 'ID Remote', value: 'id_remote', class: "primary--text" },
        ],
        filteredContadores: []
      };
    },
    watch: {
      contadores: {
        immediate: true,
        handler(val) {
          this.filteredContadores = val;
        }
      }
    },
    methods: {
      filterContadores(filters) {
        this.filteredContadores = this.contadores.filter(item => {
          const matchesName = !filters.name || item.nombre.toLowerCase().includes(filters.name.toLowerCase());
          const matchesPulsos = !filters.pulsos || (
            filters.pulsos === '500' && item.n_pulsos < 500 ||
            filters.pulsos === '1000' && item.n_pulsos > 1000 ||
            filters.pulsos === '5000' && item.n_pulsos > 5000 ||
            filters.pulsos === '0' && item.n_pulsos === 0
          );
          const matchesRemote = !filters.remote || item.id_remote === filters.remote;
          return matchesName && matchesPulsos && matchesRemote;
        });
      },
      selectContador(item) {
        this.$emit('select-contador', item);
      }
    }
  };
  </script>
  
  <style scoped>
  .list-container {
    max-height: 400px;
    overflow-y: auto;
  }
  @media (min-width: 600px) {
    .list-container {
      max-height: 600px;
    }
  }
  .list-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  .list-item-content {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
  .column {
    flex: 1;
    text-align: left;
    padding: 6px 10px;
  }
  </style>
  