<template>
    <div class="card-container">
        <v-card class="info-card">
            <v-btn icon x-small class="sync-button" @click.stop="handleSyncClick">
                <v-icon :class="{ 'rotate-icon': isSyncing }">mdi-sync-circle</v-icon>
            </v-btn>
            <div class="card-content" :class="{ flipped: flipped }">
                <div class="card-front card-face">
                    <v-row align="center" no-gutters>
                        <v-col :cols="title === 'Previsión' ? '5' : '4'">
                            <template v-if="image">
                                <div class="icon-background">
                                    <img :src="image" alt="Image" class="icon-photo " />
                                </div>
                            </template>
                            <template v-else>
                                <div class="icon-background">
                                    <v-icon xx-large :color="iconColor" class="icon-xx-large" :class="{ 'icon-small': title === 'Válvulas abiertas' }">
                                        {{ icon }}
                                    </v-icon>
                                </div>
                                <!-- <div v-if="title === 'Previsión'" class="text-caption mt-1">
                                    <v-icon small color="red darken-2">mdi-map-marker-right</v-icon>
                                    {{ location }}
                                </div> -->
                            </template>
                        </v-col>
                        <v-col :cols="title === 'Previsión' ? '7' : '8'"
                            :class="{'align-ecos-core': title === 'ECOS Core' }">
                            <div class="title-container">
                                <div :class="['info-title', titleClass]">{{ title }}</div>
                            </div>
                            <div v-if="title !== 'ECOS Core'">
                                <div class="info-value">
                                    <span class="text-value">{{ value }}</span>
                                    <span v-if="title === 'Previsión'">ºC</span>
                                    <span v-if="title === 'Humedad'">%</span>
                                </div>
                            </div>
                            <div v-else class="info-value-core">
                                <span class="status-indicator" pulse style="background-color: teal;"></span>
                                <span class="text-value-core">{{ value }}</span>
                            </div>
                            <!-- <div v-if="title === 'Previsión'" class="text-right">
                                <v-icon>mdi-weather-windy</v-icon>
                                <span class="text-caption">{{ airQuality }}</span>
                                <v-icon class="ml-2">mdi-water-percent</v-icon>
                                <span class="text-caption"> {{ humidity }}</span>
                            </div> -->
                        </v-col>
                    </v-row>
                </div>
                <div class="card-back card-face">
                    <div v-if="title === 'Previsión'">
                        <div class="center-content mb-1">
                            <v-icon small color="red darken-2">mdi-map-marker-right</v-icon>
                            <span class="small-text ml-1">{{ location }}</span>
                        </div>
                        <div v-if="forecasts && forecasts.length" class="forecast-container">
                            <div v-for="(forecast, index) in forecasts" :key="index" class="forecast-item mx-3">
                                <div style="font-weight: 600; font-size: 12px">{{ forecast.day }}</div>
                                <v-icon :color="forecast.iconColor" large>{{ forecast.icon }}</v-icon>
                                <div style="font-weight: 400; font-size:16px">{{ forecast.minTemp.toFixed(0) }}°
                                    <strong>/</strong> {{ forecast.temp.toFixed(0) }}°
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            No hay pronósticos
                        </div>
                    </div>
                    <div v-if="title === 'Válvulas abiertas'">
                        <div v-if="valves && valves.length" class="chip-container">
                            <v-chip v-for="(valve, index) in visibleValves" :key="index" class="ma-1"
                                color="teal darken-2" outlined>
                                {{ valve }}
                            </v-chip>
                            <v-btn v-if="valves.length > maxVisible" @click.stop="showMore" class=" my-1" small text
                                color="blue darken-4">
                                {{ showAll ? 'Ver menos' : 'Ver más' }}
                            </v-btn>
                        </div>
                        <div v-else>
                            <div class="mt-5">
                                No hay válvulas conectadas
                            </div>
                        </div>
                    </div>
                    <div v-if="title === 'Humedad'" class="d-flex align-center justify-center flex-column">
                        <div class="subtitle-1">
                            Esta humedad es la que se encuentra en la tierra, obtenido desde el último registro.
                        </div>
                    </div>
                    <div v-if="title === 'Presión Cabezal'" class="d-flex align-center justify-center flex-column">
                        <div class="subtitle-1">
                            <div class="text-body-2">
                                Esta presión es la que se encuentra en el cabezal de la bomba, obtenido desde el último
                                registro.
                            </div>
                        </div>
                    </div>
                    <div v-if="title === 'ECOS Core'" class="d-flex align-center justify-center flex-column">
                        <div class="subtitle-1" v-if="fecha_ultima_conexion">
                            <div class="font-weight-medium pl-1">
                                <v-icon>mdi-update</v-icon>
                                Última conexión:
                            </div>
                            <div class="text-body-2 font-light-medium mt-3">
                                {{ fecha_ultima_conexion }}
                            </div>
                        </div>
                        <div v-else class="pt-2 w-100 d-flex align-center justify-center flex-column"
                            style="height:120px">
                            <v-icon color="grey-darken-3" icon="mdi-alert-circle-outline" />
                            <div class="text-center w-75">
                                <p class="text-grey-darken-3 text-caption">
                                    No existen datos de fecha de conexión
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Válvulas abiertas</span>
                    <v-spacer></v-spacer>
                    <v-icon @click="close('dialog')">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <span class="subtitle-2 ml-1 mt-n3">Estas son las válvulas que se encuentran abiertas en este
                        momento:</span>
                    <div v-if="valves && valves.length" class="mt-1">
                        <v-chip v-for="(valve, index) in valves" :key="index" class="ma-1 mt-1" color="teal darken-2"
                            text-color="white">
                            {{ valve }}
                        </v-chip>
                    </div>
                    <div v-else>
                        No hay válvulas conectadas
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String,
        icon: String,
        iconColor: String,
        image: String,
        cardClass: String,
        titleClass: {
            type: String,
            default: ''
        },
        forecasts: {
            type: Array,
            default: () => []
        },
        valves: {
            type: Array,
            default: () => []
        },
        location: String,
        humidity: String,
        airQuality: String,
    },
    data() {
        return {
            flipped: false,
            showAll: false,
            dialog: false,
            maxVisible: 10,
            isSyncing: false,
            fecha_ultima_conexion: '2023/10/04 - 16:18'
        };
    },
    computed: {
        visibleValves() {
            return this.showAll ? this.valves : this.valves.slice(0, this.maxVisible);
        }
    },
    methods: {
        flipCard() {
            this.flipped = !this.flipped;
        },
        handleSyncClick() {
            this.isSyncing = true;
            this.flipCard();
            setTimeout(() => {
                this.isSyncing = false;
            }, 1000); // Adjust the duration as needed
        },
        showMore(event) {
            event.stopPropagation();
            if (this.showAll) {
                this.showAll = false;
            } else {
                this.dialog = true;
            }
        },
        close(dialog) {
            this[dialog] = false;
        }
    },
};
</script>

<style scoped>
.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-text {
    font-size: 15px;
}

.sync-button {
    color: gray;
}

.sync-button:hover {
    color: #00acc1;
}

.text-right {
    text-align: right;
    margin-right: 10%;
}

.text-value {
    font-size: 55px;
    font-weight: 450;
}

.card-container {
    perspective: 1000px;
    width: 100%;
    position: relative;
}

.info-card {
    width: 100%;
    height: 145px;
    padding: 30px;
    background-color: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
}

.card-content {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-face {
    backface-visibility: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
    position: absolute;
    /* top: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.flipped {
    transform: rotateY(180deg);
}

.icon-xx-large {
    font-size: 70px;
    color: #00796d;
}

.icon-small {
    font-size: 60px;
}

.icon-background {
    background-color: #e6f0eb;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-container {
    text-align: right;
    height: 100%;
}

.info-title {
    margin-right: 12%;
    font-weight: 450;
    color: #333;
    font-size: 18px;
}

.align-title {
    margin-top: -20px;
}

.align-ecos-core {
    margin-top: -12px;
}

.info-value {
    font-size: 18px;
    margin-right: 10%;
    text-align: right;
}

.info-value-core {
    font-size: 16px;
    margin-right: 10%;
    text-align: right;
    margin-top: 10%
}

.text-value-core {
    margin-left: 8px;
    font-size: 30px;
    font-weight: 450;
}

.forecast-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.forecast-item {
    text-align: center;
}

.icon-photo {
    width: 75px;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.subtitle-1 {
    color: #666;
    font-size: 14px;
    text-align: center;
}

.sync-button {
    position: absolute;
    top: 8px;
    right: 8px;
}

.rotate-icon {
    animation: spin 0.6s linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

:root {
    --status-indicator-size: 15px;
    --status-indicator-animation-duration: 2s;

    --status-indicator-color-positive: rgb(75, 210, 143);
    --status-indicator-color-positive-semi: rgba(75, 210, 143, .5);
    --status-indicator-color-positive-transparent: rgba(75, 210, 143, 0);
}

@keyframes status-indicator-pulse-positive {
    0% {
        box-shadow: 0 0 0 0 var(--status-indicator-color-positive-semi);
    }

    70% {
        box-shadow: 0 0 0 var(--status-indicator-size) var(--status-indicator-color-positive-transparent);
    }

    100% {
        box-shadow: 0 0 0 0 var(--status-indicator-color-positive-transparent);
    }
}

.status-indicator {
    display: inline-block;
    border-radius: 50%;
    width: var(--status-indicator-size);
    height: var(--status-indicator-size);
    background-color: var(--status-indicator-color-positive);
}

.status-indicator[pulse] {
    animation: status-indicator-pulse-positive var(--status-indicator-animation-duration) ease-in-out infinite;
}
</style>
