<template>
  <GruposComponent
    :electrovalvulas="electrovalvulas"
    :programacionesSecuenciales="programacionesSecuenciales"
    :grupos="grupos"
    :loader="loader"
  />
</template>

<script>
import GruposComponent from "@/components/GruposComponent.vue";
import { getValvulas } from "@/data/valvulasData";
import { getGrupos } from "@/data/gruposData";
import { getProgramacionesSecuenciales } from "@/data/programacionesSecuencialesData";
export default {
  components: { GruposComponent },
  data() {
    return {
      loader: false,
      electrovalvulas: getValvulas(),
      grupos: getGrupos(),
      programacionesSecuenciales: getProgramacionesSecuenciales(),
    };
  },
  methods: {
  },
};
</script>

<style>
</style>