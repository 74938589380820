<template>
  <v-container :fluid="$vuetify.breakpoint.mobile" class="px-0 py-0">
    <v-row dense class="d-flex .flex-column align-start justify-space-between">
      <v-col cols="12" md="4" class="col_data">
        <v-card>
          <v-card-title>
            <span class="headline">Gráficos</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- Selector de Remotes -->
              <v-autocomplete class="py-0" solo item-color="darkslategrey" @change="selectRemotes($event)"
                :items="getUniqueRemotes" filled chips color="grey darken-2" label="Dispositivo" item-text="nombre_remote"
                item-value="nombre_remote" multiple return-object>
                <template v-slot:selection="data">
                  <v-chip close @click:close="remove(data.item)">
                    <v-avatar color="darkslategrey" left>
                      <v-icon>{{ data.item.icon }}</v-icon>
                    </v-avatar>
                    {{ data.item.nombre_remote }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content> {{ data.item }}</v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar color="teal">
                      <v-icon dark>{{ data.item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item.nombre_remote }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <!-- Selector de Parámetro -->
              <v-select class="py-0" :items="getSensorsByRemote" item-text="name" item-value="id" filled chips
                label="Parámetro" solo color="grey darken-2" item-color="darkslategrey"
                @change="selectedParameter($event)" return-object
                v-if="showParameterSelector && selectedRemotes.length > 0">
                <template v-slot:selection="data">
                  <v-icon left large class="ml-2 mr-3">{{ data.item.icon }}</v-icon>
                  {{ data.item.nombre }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content> {{ data.item }} </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar color="teal">
                      <v-icon dark>{{ data.item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item.nombre }} </v-list-item-title>
                      <v-list-item-subtitle> {{ data.item.nombre_remote }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaInicio" label="Fecha de inicio" prepend-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" color="teal"></v-text-field>
                    </template>
                    <v-date-picker color="teal darken-4" v-model="fechaInicio" locale="es-es"
                      :first-day-of-week="1"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu class="py-0" :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaFin" color="teal" label="Fecha de fin" prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaFin" locale="es-es" :first-day-of-week="1"
                      color="teal darken-4"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <div class="details-cards" v-if="!$vuetify.breakpoint.smAndDown && selectedRemotes.length > 0">
          <v-card dark class="mt-4 py-3 px-8 red darken-4 d-flex justify-space-between
              align-center" v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-top </v-icon>
              <span class="mt-n4 text-h6 pl-4">Máximo</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? convertToPercentage(resumen.maximo).toFixed(1) :
    resumen.maximo.toFixed(1) }} </span>
              <span class="text-subtitle-2">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 blue darken-4 d-flex
              justify-space-between align-center" dark v-if="resumen.media !== null">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-center </v-icon>
              <span class="mt-n4 text-h6 pl-4">Media</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? convertToPercentage(resumen.media).toFixed(1) :
    resumen.media.toFixed(1) }}</span>
              <span class="text-subtitle-2">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 teal darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-bottom </v-icon>
              <span class="mt-n4 text-h6 pl-4">Mínimo</span>
            </div>

            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? convertToPercentage(resumen.minimo).toFixed(1) :
    resumen.minimo.toFixed(1) }} </span>
              <span class="text-subtitle-2">{{ unidad }}</span>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <v-card :class="this.$vuetify.breakpoint.mobile ? 'pa-0 ma-0 mb-2' : 'pa-4'" :height="chartHeight">
          <div style="height: 5px">
            <v-progress-linear indeterminate color="teal" v-if="isLoading"></v-progress-linear>
          </div>
          <v-card-text style="height:100%">
            <div class="d-flex justify-center align-center" v-if="parametro == null" style="height: 90%">
              <v-icon left color="teal"> mdi-information-outline </v-icon>
              <span> Selecciona un parámetro </span>
            </div>
            <div class="d-flex justify-center align-center" v-else-if="parametro !== null && data.length == 0"
              style="height: 90%">
              <v-icon left color="red"> mdi-information-outline </v-icon>
              <span> No se han encontrado datos </span>
            </div>
            <apexchart v-show="data.length > 0 && selectedRemotes.length > 0" type="area" height="100%"
              :options="chartOptions" :series="series">
            </apexchart>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Cambio de posición dependiendo si es un movil o una Pc  -->
      <v-col v-if="$vuetify.breakpoint.smAndDown && selectedRemotes.length > 0" class="mb-12">
        <div class="details-cards">
          <v-card dark class="py-3 px-8 red darken-4 d-flex justify-space-between align-center"
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-top </v-icon>
              <span class="mt-n4 text-h6 pl-4">Máximo</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? convertToPercentage(resumen.maximo).toFixed(1) :
    resumen.maximo.toFixed(1) }}</span>
              <span class="text-subtitle-2">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 blue darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.media !== null">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-center </v-icon>
              <span class="mt-n4 text-h6 pl-4">Media</span>
            </div>
            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? convertToPercentage(resumen.media).toFixed(1) :
    resumen.media.toFixed(1) }}</span>
              <span class="text-subtitle-2">{{ unidad }}</span>
            </div>
          </v-card>
          <v-card class="mt-2 py-3 px-8 teal darken-4 d-flex justify-space-between align-center" dark
            v-if="resumen.tipo_dato == 'max_min'">
            <div class="d-flex align-end">
              <v-icon large> mdi-format-vertical-align-bottom </v-icon>
              <span class="mt-n4 text-h6 pl-4">Mínimo</span>
            </div>

            <div>
              <span class="text-h4">{{ this.name === 'Bateria Remote' ? convertToPercentage(resumen.minimo).toFixed(1) :
    resumen.minimo.toFixed(1) }}</span>
              <span class="text-subtitle-2">{{ unidad }}</span>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import apexchart from "vue-apexcharts";
import { getData } from '../data/dataGrafico'
export default {
  props: ["params"],
  components: { apexchart },
  data() {
    return {
      parametro: null,
      showParameterSelector: false,
      selectedRemotes: [],
      isLoading: false,
      unidad: "",
      name: "",
      nombre_remote: null,
      resumen: {
        minimo: null,
        maximo: null,
        media: null,
        total: null,
        tipo_dato: null,
      },
      fechaInicio: null,
      fechaFin: null,
      //Datos de la grafica
      data: [],
      series: [],
      // Configuracion de la grafica
      chartOptions: {
        chart: {
          id: "area-datetime",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
          animations: {
            enabled: false,
          },
        },
        colors: [
          "#009688", "#FF4444", "#2E93fA", "#66DA26", "#546E7A", "#E91E63",
        ],
        xaxis: {
          type: "datetime",
          axisBorder: { show: false },
          axisTicks: { show: false },
          tickAmount: 4,
          labels: {
            show: false,
            formatter: function (val) {
              let retorno =
                new Date(val).toLocaleDateString() +
                " " +
                new Date(val).toLocaleTimeString("es-Es");
              return retorno;
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  methods: {

    convertToPercentage(val) {
      return ((val - 0) / (4095 - 0)) * (100 - 0) + 0;
    },

    //Se elimina chip de remote seleccionado
    remove(item) {
      if (Array.isArray(this.selectedRemotes)) {
        const index = this.selectedRemotes.indexOf(item);
        if (index >= 0) this.selectedRemotes.splice(index, 1);
        this.data = [];
        this.series = [];
        this.unidad = "";
        this.name = "";
        this.resumen = {
          minimo: null,
          maximo: null,
          media: null,
          total: null,
          tipo_dato: null,
        };
      }
    },

    //Se cargan los datos de los remotes seleccionados
    selectRemotes(remotes) {
      this.selectedRemotes = remotes;
      this.showParameterSelector = true;
    },

    //Se cargan los datos de el parametro seleccionado
    selectedParameter(id) {
      this.fechaFin = null;
      this.fechaInicio = null;

      const response = getData(id, this.fechaInicio, this.fechaFin)
      this.parametro = id;
      this.unidad = response.unidades;
      this.name = response.nombre;
      this.data = response.data;
      this.resumen = response.resumen;
      this.changeOptions();
    },


    //Funcion para cambiar detalles de la grafica
    changeOptions() {
      // Del data obtengo sòlo el timestamp solo obteniendo el yyyy-mm-dd solo del primer dato
      this.fechaInicio = new Date(this.data[0][0]).toISOString().split("T")[0];
      // Del data obtengo sòlo el timestamp solo obteniendo el yyyy-mm-dd solo del ultimo dato
      this.fechaFin = new Date(this.data[this.data.length - 1][0]).toISOString().split("T")[0];

      let unidad = this.unidad ? this.unidad : "";
      let media = this.resumen.media === null ? "" : this.resumen.media;
      //Actualizo los datos
      this.series = [{ name: this.name, data: this.data }];
      this.chartOptions = {
        annotations: {
          //Linea que representa la media en la grafica
          yaxis: [
            {
              y: media,
              borderColor: "blue",
              label: {
                show: true,
                text: "Media",
                style: {
                  color: "white",
                  background: "teal",
                },
                position: "left"
              },
            },
          ],
        },
        yaxis: {
          labels: {
            show: true,
            //Formateo el para tener solo 1 decimal y le añado la unidad
            formatter: function (val) {
              return val? val.toFixed(1) + unidad : val;
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
        },
      };

      if (this.name === 'Bateria Remote') {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 4095,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                let percentage = ((val - 0) / (4095 - 0)) * (100 - 0) + 0;
                return percentage.toFixed(0) + '%';
              },
            },
          }
        }
        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } else if (this.name === 'Temperatura') {
        const parametersExtraChart = {
          yaxis: {
            min: -10,
            max: 45,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }

        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }

      } else if (this.name === 'Humedad') {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 100,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? Math.abs(val.toFixed(0)) + unidad : val;
              },
            },
          }
        }

        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }


      } else if (this.name === 'PH') {
        const parametersExtraChart = {
          yaxis: {
            min: 3,
            max: 9,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }
        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } else if (this.name === 'Presión') {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 20,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? Math.abs(val.toFixed(0)) + unidad : val;
              },
            },
          }
        }
        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } else if (this.name === 'Humectación Hoja') {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 100,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad si no es null
              formatter: function (val) {
                return val ? Math.abs(val.toFixed(0)) + unidad : val;
              },
            },
          }
        }
        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } else if (this.name === 'Temperatura Hoja') {
        const parametersExtraChart = {
          yaxis: {
            min: -10,
            max: 45,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }
        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      } else if (this.name === 'Conductividad') {
        const parametersExtraChart = {
          yaxis: {
            min: 0,
            max: 1000,
            labels: {
              show: true,
              //Formateo el para tener solo 1 decimal y le añado la unidad
              formatter: function (val) {
                return val ? val.toFixed(0) + unidad : val;
              },
            },
          }
        }
        // Añaado los parametros extra a la grafica
        this.chartOptions = { ...this.chartOptions, ...parametersExtraChart }
      }

      this.isLoading = false;
    },
  },
  computed: {
    chartHeight() {
      return this.$vuetify.breakpoint.smAndDown ? "500px" : "75vh";
    },

    // Busca los remotes que encuentre
    getUniqueRemotes() {
      let Remotes = [
        
      ];
      this.params.forEach(param => {
        if (!Remotes.some(remote => remote.id === param.id_remote)) {
          Remotes.push({
            'id': param.id_remote,
            'nombre_remote': param.nombre_remote ?? 'Core',
            'icon': param.id_remote ? 'mdi-chip' : 'mdi-box-shadow'
          })
        }
      });
      return Remotes;
    },

    getSensorsByRemote() {
      const selectedRemoteIds = this.selectedRemotes.map(selectedRemotes => selectedRemotes.id);
      // console.log("IDs de Remotes", selectedRemoteIds);

      let parametros = [
      ];

      for (const sensor of this.params) {
        if (selectedRemoteIds.length === 0 && sensor.id_remote === null) {
          parametros.push(sensor);
        } else {
          for (const remoteId of selectedRemoteIds) {
            if (sensor.id_remote === remoteId) {
              parametros.push(sensor);
            }
          }
        }
      }
      // console.log(parametros)
      return parametros;
    }
  },

  // beforeMount() {
  //   // Obtenemos la fecha de mañana en formato "yyyy-mm-dd"
  //   let tomorrow = new Date();
  //   tomorrow.setDate(tomorrow.getDate() + 1);
  //   tomorrow = tomorrow.toISOString().split("T")[0];

  //   // Obtenemos la fecha de hace 7 dias en formato "yyyy-mm-dd"
  //   let lastWeek = new Date();
  //   lastWeek.setDate(lastWeek.getDate() - 7);
  //   lastWeek = lastWeek.toISOString().split("T")[0];

  //   // Asignamos las fechas a los campos de fecha
  //   this.fechaInicio = lastWeek;
  //   this.fechaFin = tomorrow;
  // }
};
</script>

<style></style>